import './index.css'

// import '@radix-ui/themes/styles.css'
// import { Theme } from '@radix-ui/themes'
import React from 'react'
import ReactDOM from 'react-dom'
import { Route, Routes } from 'react-router'
import { BrowserRouter } from 'react-router-dom'

import App from './App'
import { AlertProvider } from './context/AlertContext'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  <React.StrictMode>
    <AlertProvider>
      {/* <Theme appearance="dark"> */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
        </Routes>
      </BrowserRouter>
      {/* </Theme> */}
    </AlertProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
