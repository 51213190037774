import {
  CalendarIcon,
  ChartBarIcon,
  CogIcon,
  InformationCircleIcon,
} from '@heroicons/react/outline'
// import { Flex, IconButton } from '@radix-ui/themes'
import classNames from 'classnames'

import { ENABLE_ARCHIVED_GAMES } from '../../constants/settings'
import { GAME_TITLE } from '../../constants/strings'
import { Button } from '../ui/button'

// border: '2px solid rgb(24, 100, 171)',
// backgroundColor: lastModifier
//   ? lastModifier?.type === 'direct'
//     ? 'rgb(190, 57, 97, 0.5)'
//     : 'rgb(175, 200, 50, 0.5)'
// : undefined,
type Props = {
  score?: number
  lastModifier?: { value: string; type: 'indirect' | 'direct' }
  setIsInfoModalOpen: (value: boolean) => void
  setIsStatsModalOpen: (value: boolean) => void
  setIsDatePickerModalOpen: (value: boolean) => void
  setIsSettingsModalOpen: (value: boolean) => void
}

export const Navbar = ({
  setIsInfoModalOpen,
  setIsStatsModalOpen,
  setIsDatePickerModalOpen,
  setIsSettingsModalOpen,
  lastModifier,
  score,
}: Props) => {
  return (
    <div className="navbar bg-gradient-to-br from-gray-900 to-gray-950 ">
      <div className="navbar-content px-5 short:h-auto">
        {/* <Flex gap={'3'}>
          <Button variant="default" color="gray">
            <InformationCircleIcon
              className="h-6 w-6 cursor-pointer dark:stroke-white"
              onClick={() => setIsInfoModalOpen(true)}
            />
          </IconButton>
          {ENABLE_ARCHIVED_GAMES && (
            <IconButton variant="classic" color="gray">
              <CalendarIcon
                className="h-6 w-6 cursor-pointer dark:stroke-white"
                onClick={() => setIsDatePickerModalOpen(true)}
              />
            </IconButton>
          )}
        </Flex> */}
        <div className="flex h-full min-h-[40px] w-full flex-1 items-center justify-center text-slate-800 dark:text-slate-50">
          <div
            style={{
              boxSizing: 'border-box',
              borderRadius: '4px',

              color: true ? 'rgb(165, 216, 255)' : undefined,
              backgroundColor: true ? 'rgb(13, 57, 97, 0.5)' : undefined,
              boxShadow: true
                ? 'rgba(0, 0, 0, 0.05) 0px 1px 3px, rgba(0, 0, 0, 0.05) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px'
                : undefined,
              border: true ? '2px solid rgb(24, 100, 171)' : undefined,
            }}
            className={classNames(
              'flex h-9 w-40 flex-row items-center justify-center rounded-md border-2 border-y-sky-700 px-0 py-2 text-center text-3xl'
            )}
          >
            {lastModifier?.value && (
              <div
                className="h-8 w-1/4 flex-grow rounded-sm  text-center text-xl font-semibold accent-directHit shadow-inner drop-shadow-xl  backdrop-blur-2xl transition-colors"
                style={{
                  color: 'rgba(0, 0, 0, 0.95)',
                  border: !lastModifier
                    ? '2px solid rgb(24, 100, 171)'
                    : lastModifier?.type === 'direct'
                      ? '2px solid rgb(190, 57, 97, 1)'
                      : ' 2px solid rgb(175, 200, 50, 1)',
                  backgroundColor: lastModifier
                    ? lastModifier?.type === 'direct'
                      ? 'rgb(190, 57, 97, 0.9)'
                      : 'rgb(175, 200, 50, 0.9)'
                    : undefined,
                }}
              >
                {lastModifier?.value}
              </div>
            )}
            <div className=" w-3/4 flex-grow text-lime-500">{score}</div>
            {/* <IconPointFilled /> */}
            {/* <div>{`${value.m}:${value.s}`}</div> */}
          </div>
        </div>
        {/* <p className="text-xl font-bold dark:text-white">{GAME_TITLE}</p> */}
        {/* <Flex gap={'3'}>
          <IconButton variant="classic" color="gray">
            <ChartBarIcon
              className="h-6 w-6 cursor-pointer dark:stroke-white"
              onClick={() => setIsStatsModalOpen(true)}
            />
          </IconButton>
          <IconButton variant="classic" color="gray">
            <CogIcon
              className="h-6 w-6 cursor-pointer dark:stroke-white"
              onClick={() => setIsSettingsModalOpen(true)}
            />
          </IconButton>
        </Flex> */}
      </div>
      <hr></hr>
    </div>
  )
}
