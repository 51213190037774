import React from 'react'
import videojs from 'video.js'
import { VideoJsPlayer as Player } from 'video.js'

import { Cell } from '../grid/Cell'
import { Modal } from '../shared/modal'
import { VideoJS } from '../video/player'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  const playerRef = React.useRef<Player | null>(null)

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: '/tutorial.mp4',
        type: 'video/mp4',
      },
    ],
  }

  const handlePlayerReady = (player: Player) => {
    playerRef.current = player

    // You can handle player events here, for example:
    player.on('waiting', () => {
      videojs.log('player is waiting')
    })

    player.on('dispose', () => {
      videojs.log('player will dispose')
    })
  }
  return (
    <BaseModal
      title="How to play"
      isOpen={isOpen}
      handleClose={handleClose}
      className="w-min-[70%] h-min-[70%]"
    >
      <div className=" flex min-h-[50%] min-w-[50%] flex-col space-y-2">
        <VideoJS
          options={videoJsOptions}
          onReady={handlePlayerReady as any}
          width={350}
          className="rounded-sm"
        />

        <p className="mt-6 pt-3 text-sm text-gray-500 dark:text-gray-300">
          Created by Bennette Sebastian and Ethan Weibman
          <br />
          <a
            href="mailto: info@hunch.game"
            className="font-bold text-cyan-500 underline"
          >
            Send us your feedback and thoughts
          </a>{' '}
        </p>
      </div>
    </BaseModal>
  )
}
