import { getGuessStatuses } from '../../lib/statuses'
import { unicodeSplit } from '../../lib/words'
import { Cell } from './Cell'

type Props = {
  solution: string
  guess: string
  isRevealing?: boolean
  guessId?: string
}

export const CompletedRow = ({
  solution,
  guess,
  isRevealing,
  guessId,
}: Props) => {
  console.info(solution)
  const { statuses, stats } = getGuessStatuses(solution, guess)
  const splitGuess = unicodeSplit(guess)

  return (
    <div className="mb-1 flex justify-center space-x-2">
      <span className="flex h-14 w-11 items-center justify-center text-4xl text-[#577BFF]">
        {guessId}
      </span>
      {splitGuess.map((letter, i) => (
        <Cell
          key={i}
          value={letter}
          type={'absent'}
          // type={statuses[i]}
          position={i}
          isRevealing={isRevealing}
          isCompleted
        />
      ))}

      <Cell
        key={'direct-hits'}
        position={5}
        value={`${stats?.directHits}`}
        isRevealing={isRevealing}
        isCompleted
        type={'direct-score'}
      />
      <Cell
        key={'indirect-hits'}
        position={6}
        value={`${stats?.indirectHits}`}
        isRevealing={isRevealing}
        isCompleted
        type={'indirect-score'}
      />
    </div>
  )
}
