import { TimeParts, Unit } from './types'

export function now(): number {
  // if (typeof window === 'undefined' || !('performance' in window)) {
  //   return Date.now();
  // }
  // return performance.now();

  // we do not use performance.now() anymore, read links below for more info
  // https://medium.com/@mihai/performance-now-sleep-16a5b774560c
  // https://persistent.info/web-experiments/performance-now-sleep/
  return Date.now()
}

export function getTimeParts(
  time: number,
  lastUnit?: Unit,
  roundUnit: Unit = 'ms'
): TimeParts {
  const units: Unit[] = ['ms', 's', 'm', 'h', 'd']
  const lastUnitIndex = units.findIndex((unit) => unit === lastUnit)
  const roundUnitIndex = units.findIndex((unit) => unit === roundUnit)

  if (roundUnitIndex > lastUnitIndex) {
    throw new Error('roundUnitIndex must be less or equal than lastUnitIndex')
  }

  const dividers = [1000, 60, 60, 24, 1]
  const dividersAcc = [1, 1000, 60000, 3600000, 86400000]

  const startValue = {
    ms: 0,
    s: 0,
    m: 0,
    h: 0,
    d: 0,
  }

  const output = units.reduce((obj, unit, index) => {
    if (index > lastUnitIndex) {
      obj[unit] = 0
    } else if (index === lastUnitIndex) {
      obj[unit] = Math.floor(time / dividersAcc[index])
    } else {
      obj[unit] = Math.floor(time / dividersAcc[index]) % dividers[index]
    }

    return obj
  }, startValue)

  if (roundUnitIndex > 0) {
    const unitToDecideOnRoundIndex = roundUnitIndex - 1
    const unitToDecideOnRound = units[unitToDecideOnRoundIndex]
    const isRoundNeeded =
      output[unitToDecideOnRound] >= dividers[unitToDecideOnRoundIndex] / 2

    if (isRoundNeeded) {
      output[roundUnit] = output[roundUnit] + 1
    }

    for (let i = 0; i < roundUnitIndex; i++) {
      output[units[i]] = 0
    }
  }

  return output
}
