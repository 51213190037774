import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function chunkArray<T>(xs: T[], chunk_size: number): T[][] {
  return xs.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunk_size)

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [] as T[][])
}

export function takeLastItems<T>(array: T[], n: number) {
  return array?.length >= n ? array.slice(-n) : []
}

export function isNullish<T>(
  value: T | undefined | null
): value is undefined | null {
  //@ts-ignore
  return typeof value !== 'string'
    ? !Array.isArray(value)
      ? //@ts-ignore

        (value as T) === undefined || (value as T) === null
      : //@ts-ignore

        (value as T) === undefined ||
        (value as T) === null ||
        (value as T as []).length <= 0
    : //@ts-ignore

      (value as T) === undefined || (value as T) === ''
}

type ArrayifyFields<Original> =
  Original extends Record<string, unknown>
    ? {
        [key in keyof Original]: Original[key][]
      }
    : never

export function arrayifyFields<Item extends Record<string, unknown>>(
  items: Item[]
) {
  return items.reduce(
    (grouped, item) => {
      const entries = Object.entries(item).map(
        ([key, value]) =>
          [key, grouped ? [grouped[key], value] : [value]] as const
      )
      return Object.fromEntries(entries) as ArrayifyFields<Item>
    },
    null as null | ArrayifyFields<Item>
  )
}

export function groupByName<Item extends { name: string }>(
  items: Item[]
): Item[][] {
  const repo: Record<string, Item[]> = {}

  items.forEach((item) => {
    repo[item.name] = [...(repo[item.name] ?? []), item]
  })

  return Object.entries(repo).map(([_key, value]) => value)
}

export type FireFn<T = any, A = T> = (val: T) => A

export function pipe(pipes: FireFn[] = []): {
  add: (_pipes: FireFn[]) => void
  run: (val: any) => any
} {
  return {
    add: (_pipes: FireFn[]): void => {
      pipes = pipes.concat(_pipes)
    },
    run: <T>(val: T): any => pipes.reduce((acc, fn) => fn(acc), val),
  }
}

export function addToArrayFn<T extends any[]>(
  item: any,
  index?: number
): FireFn<T> {
  return (array: T): T => {
    return addToArray(array, item, index ?? array.length)
  }
}

export function addToArray<T extends any[]>(
  array: T,
  item: any,
  index = array.length
): T {
  ;(array as unknown) = [...array]
  array.splice(index, 0, item)
  return array
}

export function removeFromArrayFn<T extends any[]>(index: number): FireFn<T> {
  return (array: T): T => {
    return removeFromArray(array, index)
  }
}

export function removeFromArray<T extends any[]>(array: T, index: number): T {
  ;(array as unknown) = [...array]
  array.splice(index, 1)
  return array
}

export function removeFromArrayByItem<T extends any[], K extends keyof T>(
  array: T,
  identityFilter: (item: T[number]) => boolean
): T {
  ;(array as unknown) = [...array]
  const index = array.findIndex((i) => identityFilter(i))
  if (index > -1) {
    array.splice(index, 1)
  }
  return array
}

export function replaceInArrayFn<T extends any[]>(
  index: number,
  item: any
): FireFn<T> {
  return (array: T): T => replaceInArray(array, index, item)
}

export function replaceInArray<T extends any[]>(
  array: T,
  index: any,
  item: any
): T {
  return pipe([removeFromArrayFn(index), addToArrayFn(item, index)]).run([
    ...array,
  ]) as T
}

export function moveInArrayFn<T extends any[]>(
  prevIndex: number,
  newIndex: number
): FireFn<T> {
  return (array: T): T => replaceInArray(array, prevIndex, newIndex)
}

export function moveInArray<T extends any[]>(
  array: T,
  prevIndex: any,
  newIndex: number
): T {
  const item = array[prevIndex]
  return pipe([removeFromArrayFn(prevIndex), addToArrayFn(item, newIndex)]).run(
    [...array]
  ) as T
}
