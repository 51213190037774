import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { lighten } from '@wessberg/color'
import classnames from 'classnames'
import { useEffect, useMemo, useRef, useState } from 'react'
import invariant from 'tiny-invariant'

import { REVEAL_TIME_MS } from '../../constants/settings'
import { useGameBoard } from '../../context/GameBoardContext'
import { blueDarkP3, colors, jadeDarkA } from '../../lib/colors'
import { getStoredIsHighContrastMode } from '../../lib/localStorage'
import { CharType } from '../../lib/statuses'
import { cn, isNullish } from '../../lib/utils'
import {
  FollowPointer,
  FollowerPointerCard,
} from '../effects/following-pointer'
import If from '../shared/if'

type Props = {
  value?: string
  type?: CharType
  isRevealing?: boolean
  isCompleted?: boolean
  position?: number
  active?: boolean
  onClick?: () => void
}

function getColor(isDraggedOver: boolean, isDark: boolean): string {
  if (isDraggedOver) {
    return 'skyblue'
  }
  return isDark ? 'lightgrey' : 'white'
}

export const Cell = ({
  value,
  type: status,
  isRevealing,
  isCompleted,
  position = 0,
  active = false,
  onClick,
}: Props) => {
  const ref = useRef(null)
  const [isDraggedOver, setIsDraggedOver] = useState(false)

  useEffect(() => {
    const el = ref.current
    invariant(el)

    return dropTargetForElements({
      element: el,
      getData(args) {
        return { status, value, position }
      },

      onDragEnter: () => setIsDraggedOver(true),
      onDragLeave: () => setIsDraggedOver(false),
      onDrop: () => setIsDraggedOver(false),
      // canDrop(args) {
      //   const type = args.source.data['status'] as CharType
      //   const value = args.source.data['value'] as CharType

      //   const currentValue = args.source.element.innerText
      //   console.info('value', currentValue)

      //   if (isNullish(currentValue)) {
      //     return true
      //   }
      //   return false
      // },
    })
  }, [])
  const { annotatedKeys } = useGameBoard()

  const annotatedColor = useMemo(
    () =>
      annotatedKeys?.find((annotation) => annotation.key === value)?.annotation,
    [annotatedKeys, value]
  )

  const isFilled = value && !isCompleted
  const shouldReveal = isRevealing && isCompleted
  const animationDelay = `${position * REVEAL_TIME_MS}ms`
  const isHighContrast = getStoredIsHighContrastMode()

  const classes = classnames(
    'relative xxshort:w-11 xxshort:h-11 short:text-2xl short:w-12 short:h-12 w-14 h-14 border-solid border-3 flex items-center justify-center mx-0.5 text-4xl font-bold rounded dark:text-white ', //bevel bevel-tl bevel-br bevel-bl bevel-tr
    {
      'bg-gray-200 dark:bg-gradient-to-b from-[#0E4F9B] to-[#0B1B60]  border-slate-200':
        !status,
      'border-black dark:bg-gradient-to-b from-[#0E4F9B] to-[#0B1B60]':
        value && !status,
      'absent shadowed bg-slate-400 dark:bg-gradient-to-b from-[#0E4F9B] to-[#0B1B60] text-white border-slate-400':
        status === 'absent',
      'indirect-score shadowed bg-gradient-to-b from-yellow-500 via-yellow-600 to-yellow-700 text-white border-yellow-500':
        status === 'indirect-score',
      'direct-score shadowed bg-gradient-to-b from-red-500 via-red-600 to-red-700 text-white border-red-500':
        status === 'direct-score',
      'correct shadowed bg-orange-500 text-white border-orange-500':
        status === 'correct' && isHighContrast,
      'present shadowed bg-cyan-500 text-white border-cyan-500':
        status === 'present' && isHighContrast,
      'correct shadowed bg-gradient-to-b from-green-700 via-green-800 to-green-900 text-white border-green-500':
        status === 'correct' && !isHighContrast,
      'present shadowed bg-gradient-to-b from-yellow-500 via-yellow-600 to-yellow-700 text-white border-yellow-500':
        status === 'present' && !isHighContrast,
      'cell-fill-animation': isFilled,

      'cell-reveal': shouldReveal,
    }
  )
  const hasValue = useMemo(() => !isNullish(value) && value !== ' ', [value])

  return (
    <div
      onClick={onClick}
      ref={ref}
      className={classnames(classes, isDraggedOver ? 'animate-ping' : '')}
      style={{
        animationDelay,
        boxShadow: isDraggedOver
          ? `
            0 0 1px rgba(0,255,255,1),
            0 0 2px 0.5px rgba(0,255,255,0.8),
            0 0 3px 1.5px rgba(0,255,25,0.5),
            0 0 5px rgba(24, 100, 171,0.5) inset,
            0 0 10px 2px rgba(24, 100, 171,0.7) inset,
            0 0 15px 4px rgba(24, 100, 171,0.9) inset,
            5px 5px 5px rgba(0,0,0,0.2)`
          : //       `inset 0 0 50px #fff,
            // inset 20px 0 80px #f0f,
            // inset -20px 0 80px rgb(0, 255, 255),
            // inset 20px 0 300px #f0f,
            // inset -20px 0 300px #0ff,
            // 0 0 50px #fff,
            // -10px 0 80px #f0f,
            // 10px 0 80px #0ff`
            undefined,
        borderColor: isDraggedOver
          ? colors.cyan4
          : active
            ? 'rgba(24, 100, 171, .8)'
            : undefined,
        borderWidth: isDraggedOver || active ? 2 : 0,
        backgroundColor: isDraggedOver
          ? colors.cyan11
          : active
            ? colors.cyan2
            : annotatedColor ?? undefined,
      }}
    >
      <If condition={status === 'direct-score' || status === 'indirect-score'}>
        <div className="absolute inset-0.5 bg-gradient-to-br from-white/20 to-transparent" />
      </If>
      <div className="shadow-[inset_0_2px_2px_rgba(11,27,96,0.3),inset_0_-2px_2px_rgba(4, 10, 48,0.3)] absolute inset-0" />
      <If condition={!hasValue && active}>
        <div className="pointer-events-none  flex items-center justify-center">
          <div className="h-4 w-px animate-caret-blink bg-foreground duration-1000" />
        </div>
      </If>
      <If condition={hasValue}>
        <div className="letter-container" style={{ animationDelay }}>
          {value}
        </div>
      </If>
    </div>
  )
}
