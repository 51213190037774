import { useMemo } from 'react'

type Condition<Value = unknown> = Value | Falsy

export function If<Value = unknown>({
  condition,
  children,
  fallback,
}: React.PropsWithoutRef<{
  condition: Condition<Value>
  children: React.ReactNode | ((value: Value) => React.ReactNode)
  fallback?: React.ReactNode
}>) {
  return useMemo(() => {
    if (condition) {
      if (typeof children === 'function') {
        return <>{children(condition)}</>
      }

      return <>{children}</>
    }

    if (fallback) {
      return <>{fallback}</>
    }

    return null
  }, [condition, fallback, children])
}

export default If

type StringObject = Record<string, string>
type NumberObject = Record<string, number>
type UnknownObject = Record<string, unknown>
type BooleanObject = Record<string, boolean>
type UnixTimestamp = number

type WithId<T> = T & {
  id: number | string
}

type Truthy<T> = false extends T
  ? never
  : 0 extends T
  ? never
  : '' extends T
  ? never
  : null extends T
  ? never
  : undefined extends T
  ? never
  : T

type Falsy = false | 0 | '' | null | undefined
type Maybe<T> = T | undefined

type AtLeastOne<T> = readonly [T, ...T[]]

type EmptyCallback = () => void
