import { useEffect, useState } from 'react'

import { cn } from '../../lib/utils'

import { DELETE_TEXT, ENTER_TEXT } from '../../constants/strings'
import { useGameBoard } from '../../context/GameBoardContext'
import { getStatuses } from '../../lib/statuses'
import { localeAwareUpperCase } from '../../lib/words'
import { Key } from './Key'

type Props = {
  onChar: (value: string) => void
  onDelete: () => void
  onEnter: () => void
  solution: string
  guesses: string[]
  isRevealing?: boolean
  className?: string
}

export const Keyboard = ({
  onChar,
  onDelete,
  onEnter,
  solution,
  guesses,
  isRevealing,
  className,
}: Props) => {
  const charStatuses = getStatuses(solution, guesses)
  const { annotatedKeys, state, currentGuess, cursorIndex, setCursor } =
    useGameBoard()

  const onClick = (value: string) => {
    if (value === 'ENTER') {
      onEnter()
    } else if (value === 'DELETE') {
      onDelete()
    } else {
      onChar(value)
      if (cursorIndex < 4) {
        setCursor(cursorIndex + 1)
      }
    }
  }

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.code === 'Enter') {
        onEnter()
      } else if (e.code === 'Backspace') {
        onDelete()
        if (cursorIndex > 0 && currentGuess[cursorIndex] === ' ') {
          setCursor(cursorIndex - 1)
        }
      } else if (e.code === 'ArrowLeft') {
        if (cursorIndex > 0) {
          setCursor(cursorIndex - 1)
        }
      } else if (e.code === 'ArrowRight') {
        if (cursorIndex < 4) {
          setCursor(cursorIndex + 1)
        }
      } else {
        const key = localeAwareUpperCase(e.key)
        // TODO: check this test if the range works with non-english letters
        if (key.length === 1 && key >= 'A' && key <= 'Z') {
          onChar(key)
          if (cursorIndex < 4) {
            setCursor(cursorIndex + 1)
          }
        }
      }
    }
    window.addEventListener('keyup', listener)
    return () => {
      window.removeEventListener('keyup', listener)
    }
  }, [onEnter, onDelete, onChar, cursorIndex, currentGuess, setCursor])

  const onBeginAnnotating = (key: string) => {
    // if (setKeyInFocus) {
    //   setKeyInFocus(key)
    // }
  }

  return (
    <div
      className={cn(
        'space-y-1 sm:w-full sm:rounded-3xl sm:bg-card sm:px-10 sm:py-6 md:w-1/2 md:px-0',
        className
      )}
    >
      <div className="flex justify-center sm:mb-1">
        {['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'].map((key) => (
          <Key
            value={key}
            key={key}
            onClick={onClick}
            annotedColor={
              annotatedKeys?.find((annotatedKey) => annotatedKey.key === key)
                ?.annotation ?? undefined
            }
            onOpenPaintTool={onBeginAnnotating}
            status={charStatuses[key]}
            isRevealing={isRevealing}
          />
        ))}
      </div>
      <div className="flex justify-center sm:mb-1">
        {['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'].map((key) => (
          <Key
            value={key}
            key={key}
            onClick={onClick}
            annotedColor={
              annotatedKeys?.find((annotatedKey) => annotatedKey.key === key)
                ?.annotation
            }
            status={charStatuses[key]}
            isRevealing={isRevealing}
          />
        ))}
      </div>
      <div className="flex justify-center">
        <Key width={65.4} value="ENTER" onClick={onClick}>
          {ENTER_TEXT}
        </Key>
        {['Z', 'X', 'C', 'V', 'B', 'N', 'M'].map((key) => (
          <Key
            value={key}
            key={key}
            onClick={onClick}
            onOpenPaintTool={onBeginAnnotating}
            annotedColor={
              annotatedKeys?.find((annotatedKey) => annotatedKey.key === key)
                ?.annotation
            }
            status={charStatuses[key]}
            isRevealing={isRevealing}
          />
        ))}
        <Key width={65.4} value="DELETE" onClick={onClick}>
          {DELETE_TEXT}
        </Key>
      </div>
    </div>
  )
}

export function hasOwnProperty<T, K extends PropertyKey>(
  obj: T,
  prop: K
): obj is T & Record<K, unknown> {
  return Object.prototype.hasOwnProperty.call(obj, prop)
}
