import { useMemo } from 'react'

import { useGameBoard } from '../../context/GameBoardContext'
import { cn } from '../../lib/utils'
import { solution, unicodeSplit } from '../../lib/words'
import { Cell } from './Cell'

type Props = {
  guess: string
  className: string
  guessId?: string
}

export const CurrentRow = ({ guess, guessId, className }: Props) => {
  const { cursorIndex, setCursor } = useGameBoard()
  const splitGuess = useMemo(() => unicodeSplit(guess), [guess])
  const emptyCells = Array.from(Array(solution.length - splitGuess.length))
  const classes = `flex justify-center mb-1 space-x-2 pb-2`

  console.info('cursor-idx', cursorIndex)
  return (
    <div className={cn(classes, className)}>
      <span className="flex h-14 w-11 items-center justify-center text-4xl text-[#577bff5f]">{`${guessId}`}</span>
      {splitGuess.map((letter, i) => (
        <Cell
          key={i}
          value={letter}
          position={i}
          active={cursorIndex === i}
          onClick={() => setCursor(i)}
        />
      ))}
      {emptyCells.map((_, i) => (
        <Cell
          key={i}
          position={splitGuess.length + i}
          active={cursorIndex === splitGuess.length + i}
          onClick={() => setCursor(splitGuess.length + i)}
        />
      ))}
      <Cell key={'direct-hits'} position={5} value={``} type={'direct-score'} />
      <Cell
        key={'indirect-hits'}
        position={6}
        value={``}
        type={'indirect-score'}
      />
    </div>
  )
}
