import { useState } from 'react'

import { Input } from '../ui/input'
import { GradientPicker } from './gradient-picker'

export const TextGradientGenerator = () => {
  const [text, setText] = useState('Hello World!')
  const [background, setBackground] = useState(
    'linear-gradient(to top left,#ff75c3,#ffa647,#ffe83f,#9fff5b,#70e2ff,#cd93ff)'
  )
  return (
    <div>
      <div className="mb-12 rounded-md border-2 border-white/20 bg-gradient-to-br from-gray-900 to-gray-950 p-4 transition hover:border-white/50">
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
          <GradientPicker
            className="w-full truncate"
            background={background}
            setBackground={setBackground}
          />
          <Input
            id="text"
            placeholder="text"
            value={text}
            onChange={(e) => setText(e.currentTarget.value)}
          />
        </div>
        <div className="w-full py-8 text-center">
          <div
            className="inline-block !bg-cover !bg-clip-text !bg-center text-5xl font-black uppercase text-transparent transition-all"
            style={{ background }}
          >
            {text}
          </div>
        </div>
      </div>
    </div>
  )
}
