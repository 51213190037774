export function replaceCharAt(
  origString: string,
  replaceChar: string,
  index: number
) {
  const firstPart = origString.substring(0, index)
  const lastPart = origString.substring(index + 1)

  let newString = firstPart + replaceChar + lastPart
  return newString
}
