export const gray = {
  gray1: '#fcfcfc',
  gray2: '#f9f9f9',
  gray3: '#f0f0f0',
  gray4: '#e8e8e8',
  gray5: '#e0e0e0',
  gray6: '#d9d9d9',
  gray7: '#cecece',
  gray8: '#bbbbbb',
  gray9: '#8d8d8d',
  gray10: '#838383',
  gray11: '#646464',
  gray12: '#202020',
}

export const grayA = {
  grayA1: '#00000003',
  grayA2: '#00000006',
  grayA3: '#0000000f',
  grayA4: '#00000017',
  grayA5: '#0000001f',
  grayA6: '#00000026',
  grayA7: '#00000031',
  grayA8: '#00000044',
  grayA9: '#00000072',
  grayA10: '#0000007c',
  grayA11: '#0000009b',
  grayA12: '#000000df',
}

export const grayP3 = {
  gray1: 'color(display-p3 0.988 0.988 0.988)',
  gray2: 'color(display-p3 0.975 0.975 0.975)',
  gray3: 'color(display-p3 0.939 0.939 0.939)',
  gray4: 'color(display-p3 0.908 0.908 0.908)',
  gray5: 'color(display-p3 0.88 0.88 0.88)',
  gray6: 'color(display-p3 0.849 0.849 0.849)',
  gray7: 'color(display-p3 0.807 0.807 0.807)',
  gray8: 'color(display-p3 0.732 0.732 0.732)',
  gray9: 'color(display-p3 0.553 0.553 0.553)',
  gray10: 'color(display-p3 0.512 0.512 0.512)',
  gray11: 'color(display-p3 0.392 0.392 0.392)',
  gray12: 'color(display-p3 0.125 0.125 0.125)',
}

export const grayP3A = {
  grayA1: 'color(display-p3 0 0 0 / 0.012)',
  grayA2: 'color(display-p3 0 0 0 / 0.024)',
  grayA3: 'color(display-p3 0 0 0 / 0.063)',
  grayA4: 'color(display-p3 0 0 0 / 0.09)',
  grayA5: 'color(display-p3 0 0 0 / 0.122)',
  grayA6: 'color(display-p3 0 0 0 / 0.153)',
  grayA7: 'color(display-p3 0 0 0 / 0.192)',
  grayA8: 'color(display-p3 0 0 0 / 0.267)',
  grayA9: 'color(display-p3 0 0 0 / 0.447)',
  grayA10: 'color(display-p3 0 0 0 / 0.486)',
  grayA11: 'color(display-p3 0 0 0 / 0.608)',
  grayA12: 'color(display-p3 0 0 0 / 0.875)',
}

export const mauve = {
  mauve1: '#fdfcfd',
  mauve2: '#faf9fb',
  mauve3: '#f2eff3',
  mauve4: '#eae7ec',
  mauve5: '#e3dfe6',
  mauve6: '#dbd8e0',
  mauve7: '#d0cdd7',
  mauve8: '#bcbac7',
  mauve9: '#8e8c99',
  mauve10: '#84828e',
  mauve11: '#65636d',
  mauve12: '#211f26',
}

export const mauveA = {
  mauveA1: '#55005503',
  mauveA2: '#2b005506',
  mauveA3: '#30004010',
  mauveA4: '#20003618',
  mauveA5: '#20003820',
  mauveA6: '#14003527',
  mauveA7: '#10003332',
  mauveA8: '#08003145',
  mauveA9: '#05001d73',
  mauveA10: '#0500197d',
  mauveA11: '#0400119c',
  mauveA12: '#020008e0',
}

export const mauveP3 = {
  mauve1: 'color(display-p3 0.991 0.988 0.992)',
  mauve2: 'color(display-p3 0.98 0.976 0.984)',
  mauve3: 'color(display-p3 0.946 0.938 0.952)',
  mauve4: 'color(display-p3 0.915 0.906 0.925)',
  mauve5: 'color(display-p3 0.886 0.876 0.901)',
  mauve6: 'color(display-p3 0.856 0.846 0.875)',
  mauve7: 'color(display-p3 0.814 0.804 0.84)',
  mauve8: 'color(display-p3 0.735 0.728 0.777)',
  mauve9: 'color(display-p3 0.555 0.549 0.596)',
  mauve10: 'color(display-p3 0.514 0.508 0.552)',
  mauve11: 'color(display-p3 0.395 0.388 0.424)',
  mauve12: 'color(display-p3 0.128 0.122 0.147)',
}

export const mauveP3A = {
  mauveA1: 'color(display-p3 0.349 0.024 0.349 / 0.012)',
  mauveA2: 'color(display-p3 0.184 0.024 0.349 / 0.024)',
  mauveA3: 'color(display-p3 0.129 0.008 0.255 / 0.063)',
  mauveA4: 'color(display-p3 0.094 0.012 0.216 / 0.095)',
  mauveA5: 'color(display-p3 0.098 0.008 0.224 / 0.126)',
  mauveA6: 'color(display-p3 0.055 0.004 0.18 / 0.153)',
  mauveA7: 'color(display-p3 0.067 0.008 0.184 / 0.197)',
  mauveA8: 'color(display-p3 0.02 0.004 0.176 / 0.271)',
  mauveA9: 'color(display-p3 0.02 0.004 0.106 / 0.451)',
  mauveA10: 'color(display-p3 0.012 0.004 0.09 / 0.491)',
  mauveA11: 'color(display-p3 0.016 0 0.059 / 0.612)',
  mauveA12: 'color(display-p3 0.008 0 0.027 / 0.879)',
}

export const slate = {
  slate1: '#fcfcfd',
  slate2: '#f9f9fb',
  slate3: '#f0f0f3',
  slate4: '#e8e8ec',
  slate5: '#e0e1e6',
  slate6: '#d9d9e0',
  slate7: '#cdced6',
  slate8: '#b9bbc6',
  slate9: '#8b8d98',
  slate10: '#80838d',
  slate11: '#60646c',
  slate12: '#1c2024',
}

export const slateA = {
  slateA1: '#00005503',
  slateA2: '#00005506',
  slateA3: '#0000330f',
  slateA4: '#00002d17',
  slateA5: '#0009321f',
  slateA6: '#00002f26',
  slateA7: '#00062e32',
  slateA8: '#00083046',
  slateA9: '#00051d74',
  slateA10: '#00071b7f',
  slateA11: '#0007149f',
  slateA12: '#000509e3',
}

export const slateP3 = {
  slate1: 'color(display-p3 0.988 0.988 0.992)',
  slate2: 'color(display-p3 0.976 0.976 0.984)',
  slate3: 'color(display-p3 0.94 0.941 0.953)',
  slate4: 'color(display-p3 0.908 0.909 0.925)',
  slate5: 'color(display-p3 0.88 0.881 0.901)',
  slate6: 'color(display-p3 0.85 0.852 0.876)',
  slate7: 'color(display-p3 0.805 0.808 0.838)',
  slate8: 'color(display-p3 0.727 0.733 0.773)',
  slate9: 'color(display-p3 0.547 0.553 0.592)',
  slate10: 'color(display-p3 0.503 0.512 0.549)',
  slate11: 'color(display-p3 0.379 0.392 0.421)',
  slate12: 'color(display-p3 0.113 0.125 0.14)',
}

export const slateP3A = {
  slateA1: 'color(display-p3 0.024 0.024 0.349 / 0.012)',
  slateA2: 'color(display-p3 0.024 0.024 0.349 / 0.024)',
  slateA3: 'color(display-p3 0.004 0.004 0.204 / 0.059)',
  slateA4: 'color(display-p3 0.012 0.012 0.184 / 0.091)',
  slateA5: 'color(display-p3 0.004 0.039 0.2 / 0.122)',
  slateA6: 'color(display-p3 0.008 0.008 0.165 / 0.15)',
  slateA7: 'color(display-p3 0.008 0.027 0.184 / 0.197)',
  slateA8: 'color(display-p3 0.004 0.031 0.176 / 0.275)',
  slateA9: 'color(display-p3 0.004 0.02 0.106 / 0.455)',
  slateA10: 'color(display-p3 0.004 0.027 0.098 / 0.499)',
  slateA11: 'color(display-p3 0 0.02 0.063 / 0.62)',
  slateA12: 'color(display-p3 0 0.012 0.031 / 0.887)',
}

export const sage = {
  sage1: '#fbfdfc',
  sage2: '#f7f9f8',
  sage3: '#eef1f0',
  sage4: '#e6e9e8',
  sage5: '#dfe2e0',
  sage6: '#d7dad9',
  sage7: '#cbcfcd',
  sage8: '#b8bcba',
  sage9: '#868e8b',
  sage10: '#7c8481',
  sage11: '#5f6563',
  sage12: '#1a211e',
}

export const sageA = {
  sageA1: '#00804004',
  sageA2: '#00402008',
  sageA3: '#002d1e11',
  sageA4: '#001f1519',
  sageA5: '#00180820',
  sageA6: '#00140d28',
  sageA7: '#00140a34',
  sageA8: '#000f0847',
  sageA9: '#00110b79',
  sageA10: '#00100a83',
  sageA11: '#000a07a0',
  sageA12: '#000805e5',
}

export const sageP3 = {
  sage1: 'color(display-p3 0.986 0.992 0.988)',
  sage2: 'color(display-p3 0.97 0.977 0.974)',
  sage3: 'color(display-p3 0.935 0.944 0.94)',
  sage4: 'color(display-p3 0.904 0.913 0.909)',
  sage5: 'color(display-p3 0.875 0.885 0.88)',
  sage6: 'color(display-p3 0.844 0.854 0.849)',
  sage7: 'color(display-p3 0.8 0.811 0.806)',
  sage8: 'color(display-p3 0.725 0.738 0.732)',
  sage9: 'color(display-p3 0.531 0.556 0.546)',
  sage10: 'color(display-p3 0.492 0.515 0.506)',
  sage11: 'color(display-p3 0.377 0.395 0.389)',
  sage12: 'color(display-p3 0.107 0.129 0.118)',
}

export const sageP3A = {
  sageA1: 'color(display-p3 0.024 0.514 0.267 / 0.016)',
  sageA2: 'color(display-p3 0.02 0.267 0.145 / 0.032)',
  sageA3: 'color(display-p3 0.008 0.184 0.125 / 0.067)',
  sageA4: 'color(display-p3 0.012 0.094 0.051 / 0.095)',
  sageA5: 'color(display-p3 0.008 0.098 0.035 / 0.126)',
  sageA6: 'color(display-p3 0.004 0.078 0.027 / 0.157)',
  sageA7: 'color(display-p3 0 0.059 0.039 / 0.2)',
  sageA8: 'color(display-p3 0.004 0.047 0.031 / 0.275)',
  sageA9: 'color(display-p3 0.004 0.059 0.035 / 0.471)',
  sageA10: 'color(display-p3 0 0.047 0.031 / 0.51)',
  sageA11: 'color(display-p3 0 0.031 0.02 / 0.624)',
  sageA12: 'color(display-p3 0 0.027 0.012 / 0.895)',
}

export const olive = {
  olive1: '#fcfdfc',
  olive2: '#f8faf8',
  olive3: '#eff1ef',
  olive4: '#e7e9e7',
  olive5: '#dfe2df',
  olive6: '#d7dad7',
  olive7: '#cccfcc',
  olive8: '#b9bcb8',
  olive9: '#898e87',
  olive10: '#7f847d',
  olive11: '#60655f',
  olive12: '#1d211c',
}

export const oliveA = {
  oliveA1: '#00550003',
  oliveA2: '#00490007',
  oliveA3: '#00200010',
  oliveA4: '#00160018',
  oliveA5: '#00180020',
  oliveA6: '#00140028',
  oliveA7: '#000f0033',
  oliveA8: '#040f0047',
  oliveA9: '#050f0078',
  oliveA10: '#040e0082',
  oliveA11: '#020a00a0',
  oliveA12: '#010600e3',
}

export const oliveP3 = {
  olive1: 'color(display-p3 0.989 0.992 0.989)',
  olive2: 'color(display-p3 0.974 0.98 0.973)',
  olive3: 'color(display-p3 0.939 0.945 0.937)',
  olive4: 'color(display-p3 0.907 0.914 0.905)',
  olive5: 'color(display-p3 0.878 0.885 0.875)',
  olive6: 'color(display-p3 0.846 0.855 0.843)',
  olive7: 'color(display-p3 0.803 0.812 0.8)',
  olive8: 'color(display-p3 0.727 0.738 0.723)',
  olive9: 'color(display-p3 0.541 0.556 0.532)',
  olive10: 'color(display-p3 0.5 0.515 0.491)',
  olive11: 'color(display-p3 0.38 0.395 0.374)',
  olive12: 'color(display-p3 0.117 0.129 0.111)',
}

export const oliveP3A = {
  oliveA1: 'color(display-p3 0.024 0.349 0.024 / 0.012)',
  oliveA2: 'color(display-p3 0.024 0.302 0.024 / 0.028)',
  oliveA3: 'color(display-p3 0.008 0.129 0.008 / 0.063)',
  oliveA4: 'color(display-p3 0.012 0.094 0.012 / 0.095)',
  oliveA5: 'color(display-p3 0.035 0.098 0.008 / 0.126)',
  oliveA6: 'color(display-p3 0.027 0.078 0.004 / 0.157)',
  oliveA7: 'color(display-p3 0.02 0.059 0 / 0.2)',
  oliveA8: 'color(display-p3 0.02 0.059 0.004 / 0.279)',
  oliveA9: 'color(display-p3 0.02 0.051 0.004 / 0.467)',
  oliveA10: 'color(display-p3 0.024 0.047 0 / 0.51)',
  oliveA11: 'color(display-p3 0.012 0.039 0 / 0.628)',
  oliveA12: 'color(display-p3 0.008 0.024 0 / 0.891)',
}

export const sand = {
  sand1: '#fdfdfc',
  sand2: '#f9f9f8',
  sand3: '#f1f0ef',
  sand4: '#e9e8e6',
  sand5: '#e2e1de',
  sand6: '#dad9d6',
  sand7: '#cfceca',
  sand8: '#bcbbb5',
  sand9: '#8d8d86',
  sand10: '#82827c',
  sand11: '#63635e',
  sand12: '#21201c',
}

export const sandA = {
  sandA1: '#55550003',
  sandA2: '#25250007',
  sandA3: '#20100010',
  sandA4: '#1f150019',
  sandA5: '#1f180021',
  sandA6: '#19130029',
  sandA7: '#19140035',
  sandA8: '#1915014a',
  sandA9: '#0f0f0079',
  sandA10: '#0c0c0083',
  sandA11: '#080800a1',
  sandA12: '#060500e3',
}

export const sandP3 = {
  sand1: 'color(display-p3 0.992 0.992 0.989)',
  sand2: 'color(display-p3 0.977 0.977 0.973)',
  sand3: 'color(display-p3 0.943 0.942 0.936)',
  sand4: 'color(display-p3 0.913 0.912 0.903)',
  sand5: 'color(display-p3 0.885 0.883 0.873)',
  sand6: 'color(display-p3 0.854 0.852 0.839)',
  sand7: 'color(display-p3 0.813 0.81 0.794)',
  sand8: 'color(display-p3 0.738 0.734 0.713)',
  sand9: 'color(display-p3 0.553 0.553 0.528)',
  sand10: 'color(display-p3 0.511 0.511 0.488)',
  sand11: 'color(display-p3 0.388 0.388 0.37)',
  sand12: 'color(display-p3 0.129 0.126 0.111)',
}

export const sandP3A = {
  sandA1: 'color(display-p3 0.349 0.349 0.024 / 0.012)',
  sandA2: 'color(display-p3 0.161 0.161 0.024 / 0.028)',
  sandA3: 'color(display-p3 0.067 0.067 0.008 / 0.063)',
  sandA4: 'color(display-p3 0.129 0.129 0.012 / 0.099)',
  sandA5: 'color(display-p3 0.098 0.067 0.008 / 0.126)',
  sandA6: 'color(display-p3 0.102 0.075 0.004 / 0.161)',
  sandA7: 'color(display-p3 0.098 0.098 0.004 / 0.208)',
  sandA8: 'color(display-p3 0.086 0.075 0.004 / 0.287)',
  sandA9: 'color(display-p3 0.051 0.051 0.004 / 0.471)',
  sandA10: 'color(display-p3 0.047 0.047 0 / 0.514)',
  sandA11: 'color(display-p3 0.031 0.031 0 / 0.632)',
  sandA12: 'color(display-p3 0.024 0.02 0 / 0.891)',
}

export const tomato = {
  tomato1: '#fffcfc',
  tomato2: '#fff8f7',
  tomato3: '#feebe7',
  tomato4: '#ffdcd3',
  tomato5: '#ffcdc2',
  tomato6: '#fdbdaf',
  tomato7: '#f5a898',
  tomato8: '#ec8e7b',
  tomato9: '#e54d2e',
  tomato10: '#dd4425',
  tomato11: '#d13415',
  tomato12: '#5c271f',
}

export const tomatoA = {
  tomatoA1: '#ff000003',
  tomatoA2: '#ff200008',
  tomatoA3: '#f52b0018',
  tomatoA4: '#ff35002c',
  tomatoA5: '#ff2e003d',
  tomatoA6: '#f92d0050',
  tomatoA7: '#e7280067',
  tomatoA8: '#db250084',
  tomatoA9: '#df2600d1',
  tomatoA10: '#d72400da',
  tomatoA11: '#cd2200ea',
  tomatoA12: '#460900e0',
}

export const tomatoP3 = {
  tomato1: 'color(display-p3 0.998 0.989 0.988)',
  tomato2: 'color(display-p3 0.994 0.974 0.969)',
  tomato3: 'color(display-p3 0.985 0.924 0.909)',
  tomato4: 'color(display-p3 0.996 0.868 0.835)',
  tomato5: 'color(display-p3 0.98 0.812 0.77)',
  tomato6: 'color(display-p3 0.953 0.75 0.698)',
  tomato7: 'color(display-p3 0.917 0.673 0.611)',
  tomato8: 'color(display-p3 0.875 0.575 0.502)',
  tomato9: 'color(display-p3 0.831 0.345 0.231)',
  tomato10: 'color(display-p3 0.802 0.313 0.2)',
  tomato11: 'color(display-p3 0.755 0.259 0.152)',
  tomato12: 'color(display-p3 0.335 0.165 0.132)',
}

export const tomatoP3A = {
  tomatoA1: 'color(display-p3 0.675 0.024 0.024 / 0.012)',
  tomatoA2: 'color(display-p3 0.757 0.145 0.02 / 0.032)',
  tomatoA3: 'color(display-p3 0.831 0.184 0.012 / 0.091)',
  tomatoA4: 'color(display-p3 0.976 0.192 0.004 / 0.165)',
  tomatoA5: 'color(display-p3 0.918 0.192 0.004 / 0.232)',
  tomatoA6: 'color(display-p3 0.847 0.173 0.004 / 0.302)',
  tomatoA7: 'color(display-p3 0.788 0.165 0.004 / 0.389)',
  tomatoA8: 'color(display-p3 0.749 0.153 0.004 / 0.499)',
  tomatoA9: 'color(display-p3 0.78 0.149 0 / 0.769)',
  tomatoA10: 'color(display-p3 0.757 0.141 0 / 0.8)',
  tomatoA11: 'color(display-p3 0.755 0.259 0.152)',
  tomatoA12: 'color(display-p3 0.335 0.165 0.132)',
}

export const red = {
  red1: '#fffcfc',
  red2: '#fff7f7',
  red3: '#feebec',
  red4: '#ffdbdc',
  red5: '#ffcdce',
  red6: '#fdbdbe',
  red7: '#f4a9aa',
  red8: '#eb8e90',
  red9: '#e5484d',
  red10: '#dc3e42',
  red11: '#ce2c31',
  red12: '#641723',
}

export const redA = {
  redA1: '#ff000003',
  redA2: '#ff000008',
  redA3: '#f3000d14',
  redA4: '#ff000824',
  redA5: '#ff000632',
  redA6: '#f8000442',
  redA7: '#df000356',
  redA8: '#d2000571',
  redA9: '#db0007b7',
  redA10: '#d10005c1',
  redA11: '#c40006d3',
  redA12: '#55000de8',
}

export const redP3 = {
  red1: 'color(display-p3 0.998 0.989 0.988)',
  red2: 'color(display-p3 0.995 0.971 0.971)',
  red3: 'color(display-p3 0.985 0.925 0.925)',
  red4: 'color(display-p3 0.999 0.866 0.866)',
  red5: 'color(display-p3 0.984 0.812 0.811)',
  red6: 'color(display-p3 0.955 0.751 0.749)',
  red7: 'color(display-p3 0.915 0.675 0.672)',
  red8: 'color(display-p3 0.872 0.575 0.572)',
  red9: 'color(display-p3 0.83 0.329 0.324)',
  red10: 'color(display-p3 0.798 0.294 0.285)',
  red11: 'color(display-p3 0.744 0.234 0.222)',
  red12: 'color(display-p3 0.36 0.115 0.143)',
}

export const redP3A = {
  redA1: 'color(display-p3 0.675 0.024 0.024 / 0.012)',
  redA2: 'color(display-p3 0.863 0.024 0.024 / 0.028)',
  redA3: 'color(display-p3 0.792 0.008 0.008 / 0.075)',
  redA4: 'color(display-p3 1 0.008 0.008 / 0.134)',
  redA5: 'color(display-p3 0.918 0.008 0.008 / 0.189)',
  redA6: 'color(display-p3 0.831 0.02 0.004 / 0.251)',
  redA7: 'color(display-p3 0.741 0.016 0.004 / 0.33)',
  redA8: 'color(display-p3 0.698 0.012 0.004 / 0.428)',
  redA9: 'color(display-p3 0.749 0.008 0 / 0.675)',
  redA10: 'color(display-p3 0.714 0.012 0 / 0.714)',
  redA11: 'color(display-p3 0.744 0.234 0.222)',
  redA12: 'color(display-p3 0.36 0.115 0.143)',
}

export const ruby = {
  ruby1: '#fffcfd',
  ruby2: '#fff7f8',
  ruby3: '#feeaed',
  ruby4: '#ffdce1',
  ruby5: '#ffced6',
  ruby6: '#f8bfc8',
  ruby7: '#efacb8',
  ruby8: '#e592a3',
  ruby9: '#e54666',
  ruby10: '#dc3b5d',
  ruby11: '#ca244d',
  ruby12: '#64172b',
}

export const rubyA = {
  rubyA1: '#ff005503',
  rubyA2: '#ff002008',
  rubyA3: '#f3002515',
  rubyA4: '#ff002523',
  rubyA5: '#ff002a31',
  rubyA6: '#e4002440',
  rubyA7: '#ce002553',
  rubyA8: '#c300286d',
  rubyA9: '#db002cb9',
  rubyA10: '#d2002cc4',
  rubyA11: '#c10030db',
  rubyA12: '#550016e8',
}

export const rubyP3 = {
  ruby1: 'color(display-p3 0.998 0.989 0.992)',
  ruby2: 'color(display-p3 0.995 0.971 0.974)',
  ruby3: 'color(display-p3 0.983 0.92 0.928)',
  ruby4: 'color(display-p3 0.987 0.869 0.885)',
  ruby5: 'color(display-p3 0.968 0.817 0.839)',
  ruby6: 'color(display-p3 0.937 0.758 0.786)',
  ruby7: 'color(display-p3 0.897 0.685 0.721)',
  ruby8: 'color(display-p3 0.851 0.588 0.639)',
  ruby9: 'color(display-p3 0.83 0.323 0.408)',
  ruby10: 'color(display-p3 0.795 0.286 0.375)',
  ruby11: 'color(display-p3 0.728 0.211 0.311)',
  ruby12: 'color(display-p3 0.36 0.115 0.171)',
}

export const rubyP3A = {
  rubyA1: 'color(display-p3 0.675 0.024 0.349 / 0.012)',
  rubyA2: 'color(display-p3 0.863 0.024 0.024 / 0.028)',
  rubyA3: 'color(display-p3 0.804 0.008 0.11 / 0.079)',
  rubyA4: 'color(display-p3 0.91 0.008 0.125 / 0.13)',
  rubyA5: 'color(display-p3 0.831 0.004 0.133 / 0.185)',
  rubyA6: 'color(display-p3 0.745 0.004 0.118 / 0.244)',
  rubyA7: 'color(display-p3 0.678 0.004 0.114 / 0.314)',
  rubyA8: 'color(display-p3 0.639 0.004 0.125 / 0.412)',
  rubyA9: 'color(display-p3 0.753 0 0.129 / 0.679)',
  rubyA10: 'color(display-p3 0.714 0 0.125 / 0.714)',
  rubyA11: 'color(display-p3 0.728 0.211 0.311)',
  rubyA12: 'color(display-p3 0.36 0.115 0.171)',
}

export const crimson = {
  crimson1: '#fffcfd',
  crimson2: '#fef7f9',
  crimson3: '#ffe9f0',
  crimson4: '#fedce7',
  crimson5: '#facedd',
  crimson6: '#f3bed1',
  crimson7: '#eaacc3',
  crimson8: '#e093b2',
  crimson9: '#e93d82',
  crimson10: '#df3478',
  crimson11: '#cb1d63',
  crimson12: '#621639',
}

export const crimsonA = {
  crimsonA1: '#ff005503',
  crimsonA2: '#e0004008',
  crimsonA3: '#ff005216',
  crimsonA4: '#f8005123',
  crimsonA5: '#e5004f31',
  crimsonA6: '#d0004b41',
  crimsonA7: '#bf004753',
  crimsonA8: '#b6004a6c',
  crimsonA9: '#e2005bc2',
  crimsonA10: '#d70056cb',
  crimsonA11: '#c4004fe2',
  crimsonA12: '#530026e9',
}

export const crimsonP3 = {
  crimson1: 'color(display-p3 0.998 0.989 0.992)',
  crimson2: 'color(display-p3 0.991 0.969 0.976)',
  crimson3: 'color(display-p3 0.987 0.917 0.941)',
  crimson4: 'color(display-p3 0.975 0.866 0.904)',
  crimson5: 'color(display-p3 0.953 0.813 0.864)',
  crimson6: 'color(display-p3 0.921 0.755 0.817)',
  crimson7: 'color(display-p3 0.88 0.683 0.761)',
  crimson8: 'color(display-p3 0.834 0.592 0.694)',
  crimson9: 'color(display-p3 0.843 0.298 0.507)',
  crimson10: 'color(display-p3 0.807 0.266 0.468)',
  crimson11: 'color(display-p3 0.731 0.195 0.388)',
  crimson12: 'color(display-p3 0.352 0.111 0.221)',
}

export const crimsonP3A = {
  crimsonA1: 'color(display-p3 0.675 0.024 0.349 / 0.012)',
  crimsonA2: 'color(display-p3 0.757 0.02 0.267 / 0.032)',
  crimsonA3: 'color(display-p3 0.859 0.008 0.294 / 0.083)',
  crimsonA4: 'color(display-p3 0.827 0.008 0.298 / 0.134)',
  crimsonA5: 'color(display-p3 0.753 0.008 0.275 / 0.189)',
  crimsonA6: 'color(display-p3 0.682 0.004 0.247 / 0.244)',
  crimsonA7: 'color(display-p3 0.62 0.004 0.251 / 0.318)',
  crimsonA8: 'color(display-p3 0.6 0.004 0.251 / 0.408)',
  crimsonA9: 'color(display-p3 0.776 0 0.298 / 0.702)',
  crimsonA10: 'color(display-p3 0.737 0 0.275 / 0.734)',
  crimsonA11: 'color(display-p3 0.731 0.195 0.388)',
  crimsonA12: 'color(display-p3 0.352 0.111 0.221)',
}

export const pink = {
  pink1: '#fffcfe',
  pink2: '#fef7fb',
  pink3: '#fee9f5',
  pink4: '#fbdcef',
  pink5: '#f6cee7',
  pink6: '#efbfdd',
  pink7: '#e7acd0',
  pink8: '#dd93c2',
  pink9: '#d6409f',
  pink10: '#cf3897',
  pink11: '#c2298a',
  pink12: '#651249',
}

export const pinkA = {
  pinkA1: '#ff00aa03',
  pinkA2: '#e0008008',
  pinkA3: '#f4008c16',
  pinkA4: '#e2008b23',
  pinkA5: '#d1008331',
  pinkA6: '#c0007840',
  pinkA7: '#b6006f53',
  pinkA8: '#af006f6c',
  pinkA9: '#c8007fbf',
  pinkA10: '#c2007ac7',
  pinkA11: '#b60074d6',
  pinkA12: '#59003bed',
}

export const pinkP3 = {
  pink1: 'color(display-p3 0.998 0.989 0.996)',
  pink2: 'color(display-p3 0.992 0.97 0.985)',
  pink3: 'color(display-p3 0.981 0.917 0.96)',
  pink4: 'color(display-p3 0.963 0.867 0.932)',
  pink5: 'color(display-p3 0.939 0.815 0.899)',
  pink6: 'color(display-p3 0.907 0.756 0.859)',
  pink7: 'color(display-p3 0.869 0.683 0.81)',
  pink8: 'color(display-p3 0.825 0.59 0.751)',
  pink9: 'color(display-p3 0.775 0.297 0.61)',
  pink10: 'color(display-p3 0.748 0.27 0.581)',
  pink11: 'color(display-p3 0.698 0.219 0.528)',
  pink12: 'color(display-p3 0.363 0.101 0.279)',
}

export const pinkP3A = {
  pinkA1: 'color(display-p3 0.675 0.024 0.675 / 0.012)',
  pinkA2: 'color(display-p3 0.757 0.02 0.51 / 0.032)',
  pinkA3: 'color(display-p3 0.765 0.008 0.529 / 0.083)',
  pinkA4: 'color(display-p3 0.737 0.008 0.506 / 0.134)',
  pinkA5: 'color(display-p3 0.663 0.004 0.451 / 0.185)',
  pinkA6: 'color(display-p3 0.616 0.004 0.424 / 0.244)',
  pinkA7: 'color(display-p3 0.596 0.004 0.412 / 0.318)',
  pinkA8: 'color(display-p3 0.573 0.004 0.404 / 0.412)',
  pinkA9: 'color(display-p3 0.682 0 0.447 / 0.702)',
  pinkA10: 'color(display-p3 0.655 0 0.424 / 0.73)',
  pinkA11: 'color(display-p3 0.698 0.219 0.528)',
  pinkA12: 'color(display-p3 0.363 0.101 0.279)',
}

export const plum = {
  plum1: '#fefcff',
  plum2: '#fdf7fd',
  plum3: '#fbebfb',
  plum4: '#f7def8',
  plum5: '#f2d1f3',
  plum6: '#e9c2ec',
  plum7: '#deade3',
  plum8: '#cf91d8',
  plum9: '#ab4aba',
  plum10: '#a144af',
  plum11: '#953ea3',
  plum12: '#53195d',
}

export const plumA = {
  plumA1: '#aa00ff03',
  plumA2: '#c000c008',
  plumA3: '#cc00cc14',
  plumA4: '#c200c921',
  plumA5: '#b700bd2e',
  plumA6: '#a400b03d',
  plumA7: '#9900a852',
  plumA8: '#9000a56e',
  plumA9: '#89009eb5',
  plumA10: '#7f0092bb',
  plumA11: '#730086c1',
  plumA12: '#40004be6',
}

export const plumP3 = {
  plum1: 'color(display-p3 0.995 0.988 0.999)',
  plum2: 'color(display-p3 0.988 0.971 0.99)',
  plum3: 'color(display-p3 0.973 0.923 0.98)',
  plum4: 'color(display-p3 0.953 0.875 0.966)',
  plum5: 'color(display-p3 0.926 0.825 0.945)',
  plum6: 'color(display-p3 0.89 0.765 0.916)',
  plum7: 'color(display-p3 0.84 0.686 0.877)',
  plum8: 'color(display-p3 0.775 0.58 0.832)',
  plum9: 'color(display-p3 0.624 0.313 0.708)',
  plum10: 'color(display-p3 0.587 0.29 0.667)',
  plum11: 'color(display-p3 0.543 0.263 0.619)',
  plum12: 'color(display-p3 0.299 0.114 0.352)',
}

export const plumP3A = {
  plumA1: 'color(display-p3 0.675 0.024 1 / 0.012)',
  plumA2: 'color(display-p3 0.58 0.024 0.58 / 0.028)',
  plumA3: 'color(display-p3 0.655 0.008 0.753 / 0.079)',
  plumA4: 'color(display-p3 0.627 0.008 0.722 / 0.126)',
  plumA5: 'color(display-p3 0.58 0.004 0.69 / 0.177)',
  plumA6: 'color(display-p3 0.537 0.004 0.655 / 0.236)',
  plumA7: 'color(display-p3 0.49 0.004 0.616 / 0.314)',
  plumA8: 'color(display-p3 0.471 0.004 0.6 / 0.42)',
  plumA9: 'color(display-p3 0.451 0 0.576 / 0.687)',
  plumA10: 'color(display-p3 0.42 0 0.529 / 0.71)',
  plumA11: 'color(display-p3 0.543 0.263 0.619)',
  plumA12: 'color(display-p3 0.299 0.114 0.352)',
}

export const purple = {
  purple1: '#fefcfe',
  purple2: '#fbf7fe',
  purple3: '#f7edfe',
  purple4: '#f2e2fc',
  purple5: '#ead5f9',
  purple6: '#e0c4f4',
  purple7: '#d1afec',
  purple8: '#be93e4',
  purple9: '#8e4ec6',
  purple10: '#8347b9',
  purple11: '#8145b5',
  purple12: '#402060',
}

export const purpleA = {
  purpleA1: '#aa00aa03',
  purpleA2: '#8000e008',
  purpleA3: '#8e00f112',
  purpleA4: '#8d00e51d',
  purpleA5: '#8000db2a',
  purpleA6: '#7a01d03b',
  purpleA7: '#6d00c350',
  purpleA8: '#6600c06c',
  purpleA9: '#5c00adb1',
  purpleA10: '#53009eb8',
  purpleA11: '#52009aba',
  purpleA12: '#250049df',
}

export const purpleP3 = {
  purple1: 'color(display-p3 0.995 0.988 0.996)',
  purple2: 'color(display-p3 0.983 0.971 0.993)',
  purple3: 'color(display-p3 0.963 0.931 0.989)',
  purple4: 'color(display-p3 0.937 0.888 0.981)',
  purple5: 'color(display-p3 0.904 0.837 0.966)',
  purple6: 'color(display-p3 0.86 0.774 0.942)',
  purple7: 'color(display-p3 0.799 0.69 0.91)',
  purple8: 'color(display-p3 0.719 0.583 0.874)',
  purple9: 'color(display-p3 0.523 0.318 0.751)',
  purple10: 'color(display-p3 0.483 0.289 0.7)',
  purple11: 'color(display-p3 0.473 0.281 0.687)',
  purple12: 'color(display-p3 0.234 0.132 0.363)',
}

export const purpleP3A = {
  purpleA1: 'color(display-p3 0.675 0.024 0.675 / 0.012)',
  purpleA2: 'color(display-p3 0.443 0.024 0.722 / 0.028)',
  purpleA3: 'color(display-p3 0.506 0.008 0.835 / 0.071)',
  purpleA4: 'color(display-p3 0.451 0.004 0.831 / 0.114)',
  purpleA5: 'color(display-p3 0.431 0.004 0.788 / 0.165)',
  purpleA6: 'color(display-p3 0.384 0.004 0.745 / 0.228)',
  purpleA7: 'color(display-p3 0.357 0.004 0.71 / 0.31)',
  purpleA8: 'color(display-p3 0.322 0.004 0.702 / 0.416)',
  purpleA9: 'color(display-p3 0.298 0 0.639 / 0.683)',
  purpleA10: 'color(display-p3 0.271 0 0.58 / 0.71)',
  purpleA11: 'color(display-p3 0.473 0.281 0.687)',
  purpleA12: 'color(display-p3 0.234 0.132 0.363)',
}

export const violet = {
  violet1: '#fdfcfe',
  violet2: '#faf8ff',
  violet3: '#f4f0fe',
  violet4: '#ebe4ff',
  violet5: '#e1d9ff',
  violet6: '#d4cafe',
  violet7: '#c2b5f5',
  violet8: '#aa99ec',
  violet9: '#6e56cf',
  violet10: '#654dc4',
  violet11: '#6550b9',
  violet12: '#2f265f',
}

export const violetA = {
  violetA1: '#5500aa03',
  violetA2: '#4900ff07',
  violetA3: '#4400ee0f',
  violetA4: '#4300ff1b',
  violetA5: '#3600ff26',
  violetA6: '#3100fb35',
  violetA7: '#2d01dd4a',
  violetA8: '#2b00d066',
  violetA9: '#2400b7a9',
  violetA10: '#2300abb2',
  violetA11: '#1f0099af',
  violetA12: '#0b0043d9',
}

export const violetP3 = {
  violet1: 'color(display-p3 0.991 0.988 0.995)',
  violet2: 'color(display-p3 0.978 0.974 0.998)',
  violet3: 'color(display-p3 0.953 0.943 0.993)',
  violet4: 'color(display-p3 0.916 0.897 1)',
  violet5: 'color(display-p3 0.876 0.851 1)',
  violet6: 'color(display-p3 0.825 0.793 0.981)',
  violet7: 'color(display-p3 0.752 0.712 0.943)',
  violet8: 'color(display-p3 0.654 0.602 0.902)',
  violet9: 'color(display-p3 0.417 0.341 0.784)',
  violet10: 'color(display-p3 0.381 0.306 0.741)',
  violet11: 'color(display-p3 0.383 0.317 0.702)',
  violet12: 'color(display-p3 0.179 0.15 0.359)',
}

export const violetP3A = {
  violetA1: 'color(display-p3 0.349 0.024 0.675 / 0.012)',
  violetA2: 'color(display-p3 0.161 0.024 0.863 / 0.028)',
  violetA3: 'color(display-p3 0.204 0.004 0.871 / 0.059)',
  violetA4: 'color(display-p3 0.196 0.004 1 / 0.102)',
  violetA5: 'color(display-p3 0.165 0.008 1 / 0.15)',
  violetA6: 'color(display-p3 0.153 0.004 0.906 / 0.208)',
  violetA7: 'color(display-p3 0.141 0.004 0.796 / 0.287)',
  violetA8: 'color(display-p3 0.133 0.004 0.753 / 0.397)',
  violetA9: 'color(display-p3 0.114 0 0.675 / 0.659)',
  violetA10: 'color(display-p3 0.11 0 0.627 / 0.695)',
  violetA11: 'color(display-p3 0.383 0.317 0.702)',
  violetA12: 'color(display-p3 0.179 0.15 0.359)',
}

export const iris = {
  iris1: '#fdfdff',
  iris2: '#f8f8ff',
  iris3: '#f0f1fe',
  iris4: '#e6e7ff',
  iris5: '#dadcff',
  iris6: '#cbcdff',
  iris7: '#b8baf8',
  iris8: '#9b9ef0',
  iris9: '#5b5bd6',
  iris10: '#5151cd',
  iris11: '#5753c6',
  iris12: '#272962',
}

export const irisA = {
  irisA1: '#0000ff02',
  irisA2: '#0000ff07',
  irisA3: '#0011ee0f',
  irisA4: '#000bff19',
  irisA5: '#000eff25',
  irisA6: '#000aff34',
  irisA7: '#0008e647',
  irisA8: '#0008d964',
  irisA9: '#0000c0a4',
  irisA10: '#0000b6ae',
  irisA11: '#0600abac',
  irisA12: '#000246d8',
}

export const irisP3 = {
  iris1: 'color(display-p3 0.992 0.992 0.999)',
  iris2: 'color(display-p3 0.972 0.973 0.998)',
  iris3: 'color(display-p3 0.943 0.945 0.992)',
  iris4: 'color(display-p3 0.902 0.906 1)',
  iris5: 'color(display-p3 0.857 0.861 1)',
  iris6: 'color(display-p3 0.799 0.805 0.987)',
  iris7: 'color(display-p3 0.721 0.727 0.955)',
  iris8: 'color(display-p3 0.61 0.619 0.918)',
  iris9: 'color(display-p3 0.357 0.357 0.81)',
  iris10: 'color(display-p3 0.318 0.318 0.774)',
  iris11: 'color(display-p3 0.337 0.326 0.748)',
  iris12: 'color(display-p3 0.154 0.161 0.371)',
}

export const irisP3A = {
  irisA1: 'color(display-p3 0.02 0.02 1 / 0.008)',
  irisA2: 'color(display-p3 0.024 0.024 0.863 / 0.028)',
  irisA3: 'color(display-p3 0.004 0.071 0.871 / 0.059)',
  irisA4: 'color(display-p3 0.012 0.051 1 / 0.099)',
  irisA5: 'color(display-p3 0.008 0.035 1 / 0.142)',
  irisA6: 'color(display-p3 0 0.02 0.941 / 0.2)',
  irisA7: 'color(display-p3 0.004 0.02 0.847 / 0.279)',
  irisA8: 'color(display-p3 0.004 0.024 0.788 / 0.389)',
  irisA9: 'color(display-p3 0 0 0.706 / 0.644)',
  irisA10: 'color(display-p3 0 0 0.667 / 0.683)',
  irisA11: 'color(display-p3 0.337 0.326 0.748)',
  irisA12: 'color(display-p3 0.154 0.161 0.371)',
}

export const indigo = {
  indigo1: '#fdfdfe',
  indigo2: '#f7f9ff',
  indigo3: '#edf2fe',
  indigo4: '#e1e9ff',
  indigo5: '#d2deff',
  indigo6: '#c1d0ff',
  indigo7: '#abbdf9',
  indigo8: '#8da4ef',
  indigo9: '#3e63dd',
  indigo10: '#3358d4',
  indigo11: '#3a5bc7',
  indigo12: '#1f2d5c',
}

export const indigoA = {
  indigoA1: '#00008002',
  indigoA2: '#0040ff08',
  indigoA3: '#0047f112',
  indigoA4: '#0044ff1e',
  indigoA5: '#0044ff2d',
  indigoA6: '#003eff3e',
  indigoA7: '#0037ed54',
  indigoA8: '#0034dc72',
  indigoA9: '#0031d2c1',
  indigoA10: '#002ec9cc',
  indigoA11: '#002bb7c5',
  indigoA12: '#001046e0',
}

export const indigoP3 = {
  indigo1: 'color(display-p3 0.992 0.992 0.996)',
  indigo2: 'color(display-p3 0.971 0.977 0.998)',
  indigo3: 'color(display-p3 0.933 0.948 0.992)',
  indigo4: 'color(display-p3 0.885 0.914 1)',
  indigo5: 'color(display-p3 0.831 0.87 1)',
  indigo6: 'color(display-p3 0.767 0.814 0.995)',
  indigo7: 'color(display-p3 0.685 0.74 0.957)',
  indigo8: 'color(display-p3 0.569 0.639 0.916)',
  indigo9: 'color(display-p3 0.276 0.384 0.837)',
  indigo10: 'color(display-p3 0.234 0.343 0.801)',
  indigo11: 'color(display-p3 0.256 0.354 0.755)',
  indigo12: 'color(display-p3 0.133 0.175 0.348)',
}

export const indigoP3A = {
  indigoA1: 'color(display-p3 0.02 0.02 0.51 / 0.008)',
  indigoA2: 'color(display-p3 0.024 0.161 0.863 / 0.028)',
  indigoA3: 'color(display-p3 0.008 0.239 0.886 / 0.067)',
  indigoA4: 'color(display-p3 0.004 0.247 1 / 0.114)',
  indigoA5: 'color(display-p3 0.004 0.235 1 / 0.169)',
  indigoA6: 'color(display-p3 0.004 0.208 0.984 / 0.232)',
  indigoA7: 'color(display-p3 0.004 0.176 0.863 / 0.314)',
  indigoA8: 'color(display-p3 0.004 0.165 0.812 / 0.432)',
  indigoA9: 'color(display-p3 0 0.153 0.773 / 0.726)',
  indigoA10: 'color(display-p3 0 0.137 0.737 / 0.765)',
  indigoA11: 'color(display-p3 0.256 0.354 0.755)',
  indigoA12: 'color(display-p3 0.133 0.175 0.348)',
}

export const blue = {
  blue1: '#fbfdff',
  blue2: '#f4faff',
  blue3: '#e6f4fe',
  blue4: '#d5efff',
  blue5: '#c2e5ff',
  blue6: '#acd8fc',
  blue7: '#8ec8f6',
  blue8: '#5eb1ef',
  blue9: '#0090ff',
  blue10: '#0588f0',
  blue11: '#0d74ce',
  blue12: '#113264',
}

export const blueA = {
  blueA1: '#0080ff04',
  blueA2: '#008cff0b',
  blueA3: '#008ff519',
  blueA4: '#009eff2a',
  blueA5: '#0093ff3d',
  blueA6: '#0088f653',
  blueA7: '#0083eb71',
  blueA8: '#0084e6a1',
  blueA9: '#0090ff',
  blueA10: '#0086f0fa',
  blueA11: '#006dcbf2',
  blueA12: '#002359ee',
}

export const blueP3 = {
  blue1: 'color(display-p3 0.986 0.992 0.999)',
  blue2: 'color(display-p3 0.96 0.979 0.998)',
  blue3: 'color(display-p3 0.912 0.956 0.991)',
  blue4: 'color(display-p3 0.853 0.932 1)',
  blue5: 'color(display-p3 0.788 0.894 0.998)',
  blue6: 'color(display-p3 0.709 0.843 0.976)',
  blue7: 'color(display-p3 0.606 0.777 0.947)',
  blue8: 'color(display-p3 0.451 0.688 0.917)',
  blue9: 'color(display-p3 0.247 0.556 0.969)',
  blue10: 'color(display-p3 0.234 0.523 0.912)',
  blue11: 'color(display-p3 0.15 0.44 0.84)',
  blue12: 'color(display-p3 0.102 0.193 0.379)',
}

export const blueP3A = {
  blueA1: 'color(display-p3 0.024 0.514 1 / 0.016)',
  blueA2: 'color(display-p3 0.024 0.514 0.906 / 0.04)',
  blueA3: 'color(display-p3 0.012 0.506 0.914 / 0.087)',
  blueA4: 'color(display-p3 0.008 0.545 1 / 0.146)',
  blueA5: 'color(display-p3 0.004 0.502 0.984 / 0.212)',
  blueA6: 'color(display-p3 0.004 0.463 0.922 / 0.291)',
  blueA7: 'color(display-p3 0.004 0.431 0.863 / 0.393)',
  blueA8: 'color(display-p3 0 0.427 0.851 / 0.55)',
  blueA9: 'color(display-p3 0 0.412 0.961 / 0.753)',
  blueA10: 'color(display-p3 0 0.376 0.886 / 0.765)',
  blueA11: 'color(display-p3 0.15 0.44 0.84)',
  blueA12: 'color(display-p3 0.102 0.193 0.379)',
}

export const cyan = {
  cyan1: '#fafdfe',
  cyan2: '#f2fafb',
  cyan3: '#def7f9',
  cyan4: '#caf1f6',
  cyan5: '#b5e9f0',
  cyan6: '#9ddde7',
  cyan7: '#7dcedc',
  cyan8: '#3db9cf',
  cyan9: '#00a2c7',
  cyan10: '#0797b9',
  cyan11: '#107d98',
  cyan12: '#0d3c48',
}

export const cyanA = {
  cyanA1: '#0099cc05',
  cyanA2: '#009db10d',
  cyanA3: '#00c2d121',
  cyanA4: '#00bcd435',
  cyanA5: '#01b4cc4a',
  cyanA6: '#00a7c162',
  cyanA7: '#009fbb82',
  cyanA8: '#00a3c0c2',
  cyanA9: '#00a2c7',
  cyanA10: '#0094b7f8',
  cyanA11: '#007491ef',
  cyanA12: '#00323ef2',
}

export const cyanP3 = {
  cyan1: 'color(display-p3 0.982 0.992 0.996)',
  cyan2: 'color(display-p3 0.955 0.981 0.984)',
  cyan3: 'color(display-p3 0.888 0.965 0.975)',
  cyan4: 'color(display-p3 0.821 0.941 0.959)',
  cyan5: 'color(display-p3 0.751 0.907 0.935)',
  cyan6: 'color(display-p3 0.671 0.862 0.9)',
  cyan7: 'color(display-p3 0.564 0.8 0.854)',
  cyan8: 'color(display-p3 0.388 0.715 0.798)',
  cyan9: 'color(display-p3 0.282 0.627 0.765)',
  cyan10: 'color(display-p3 0.264 0.583 0.71)',
  cyan11: 'color(display-p3 0.08 0.48 0.63)',
  cyan12: 'color(display-p3 0.108 0.232 0.277)',
}

export const cyanP3A = {
  cyanA1: 'color(display-p3 0.02 0.608 0.804 / 0.02)',
  cyanA2: 'color(display-p3 0.02 0.557 0.647 / 0.044)',
  cyanA3: 'color(display-p3 0.004 0.694 0.796 / 0.114)',
  cyanA4: 'color(display-p3 0.004 0.678 0.784 / 0.181)',
  cyanA5: 'color(display-p3 0.004 0.624 0.733 / 0.248)',
  cyanA6: 'color(display-p3 0.004 0.584 0.706 / 0.33)',
  cyanA7: 'color(display-p3 0.004 0.541 0.667 / 0.436)',
  cyanA8: 'color(display-p3 0 0.533 0.667 / 0.612)',
  cyanA9: 'color(display-p3 0 0.482 0.675 / 0.718)',
  cyanA10: 'color(display-p3 0 0.435 0.608 / 0.738)',
  cyanA11: 'color(display-p3 0.08 0.48 0.63)',
  cyanA12: 'color(display-p3 0.108 0.232 0.277)',
}

export const teal = {
  teal1: '#fafefd',
  teal2: '#f3fbf9',
  teal3: '#e0f8f3',
  teal4: '#ccf3ea',
  teal5: '#b8eae0',
  teal6: '#a1ded2',
  teal7: '#83cdc1',
  teal8: '#53b9ab',
  teal9: '#12a594',
  teal10: '#0d9b8a',
  teal11: '#008573',
  teal12: '#0d3d38',
}

export const tealA = {
  tealA1: '#00cc9905',
  tealA2: '#00aa800c',
  tealA3: '#00c69d1f',
  tealA4: '#00c39633',
  tealA5: '#00b49047',
  tealA6: '#00a6855e',
  tealA7: '#0099807c',
  tealA8: '#009783ac',
  tealA9: '#009e8ced',
  tealA10: '#009684f2',
  tealA11: '#008573',
  tealA12: '#00332df2',
}

export const tealP3 = {
  teal1: 'color(display-p3 0.983 0.996 0.992)',
  teal2: 'color(display-p3 0.958 0.983 0.976)',
  teal3: 'color(display-p3 0.895 0.971 0.952)',
  teal4: 'color(display-p3 0.831 0.949 0.92)',
  teal5: 'color(display-p3 0.761 0.914 0.878)',
  teal6: 'color(display-p3 0.682 0.864 0.825)',
  teal7: 'color(display-p3 0.581 0.798 0.756)',
  teal8: 'color(display-p3 0.433 0.716 0.671)',
  teal9: 'color(display-p3 0.297 0.637 0.581)',
  teal10: 'color(display-p3 0.275 0.599 0.542)',
  teal11: 'color(display-p3 0.08 0.5 0.43)',
  teal12: 'color(display-p3 0.11 0.235 0.219)',
}

export const tealP3A = {
  tealA1: 'color(display-p3 0.024 0.757 0.514 / 0.016)',
  tealA2: 'color(display-p3 0.02 0.647 0.467 / 0.044)',
  tealA3: 'color(display-p3 0.004 0.741 0.557 / 0.106)',
  tealA4: 'color(display-p3 0.004 0.702 0.537 / 0.169)',
  tealA5: 'color(display-p3 0.004 0.643 0.494 / 0.24)',
  tealA6: 'color(display-p3 0.004 0.569 0.447 / 0.318)',
  tealA7: 'color(display-p3 0.004 0.518 0.424 / 0.42)',
  tealA8: 'color(display-p3 0 0.506 0.424 / 0.569)',
  tealA9: 'color(display-p3 0 0.482 0.404 / 0.702)',
  tealA10: 'color(display-p3 0 0.451 0.369 / 0.726)',
  tealA11: 'color(display-p3 0.08 0.5 0.43)',
  tealA12: 'color(display-p3 0.11 0.235 0.219)',
}

export const jade = {
  jade1: '#fbfefd',
  jade2: '#f4fbf7',
  jade3: '#e6f7ed',
  jade4: '#d6f1e3',
  jade5: '#c3e9d7',
  jade6: '#acdec8',
  jade7: '#8bceb6',
  jade8: '#56ba9f',
  jade9: '#29a383',
  jade10: '#26997b',
  jade11: '#208368',
  jade12: '#1d3b31',
}

export const jadeA = {
  jadeA1: '#00c08004',
  jadeA2: '#00a3460b',
  jadeA3: '#00ae4819',
  jadeA4: '#00a85129',
  jadeA5: '#00a2553c',
  jadeA6: '#009a5753',
  jadeA7: '#00945f74',
  jadeA8: '#00976ea9',
  jadeA9: '#00916bd6',
  jadeA10: '#008764d9',
  jadeA11: '#007152df',
  jadeA12: '#002217e2',
}

export const jadeP3 = {
  jade1: 'color(display-p3 0.986 0.996 0.992)',
  jade2: 'color(display-p3 0.962 0.983 0.969)',
  jade3: 'color(display-p3 0.912 0.965 0.932)',
  jade4: 'color(display-p3 0.858 0.941 0.893)',
  jade5: 'color(display-p3 0.795 0.909 0.847)',
  jade6: 'color(display-p3 0.715 0.864 0.791)',
  jade7: 'color(display-p3 0.603 0.802 0.718)',
  jade8: 'color(display-p3 0.44 0.72 0.629)',
  jade9: 'color(display-p3 0.319 0.63 0.521)',
  jade10: 'color(display-p3 0.299 0.592 0.488)',
  jade11: 'color(display-p3 0.15 0.5 0.37)',
  jade12: 'color(display-p3 0.142 0.229 0.194)',
}

export const jadeP3A = {
  jadeA1: 'color(display-p3 0.024 0.757 0.514 / 0.016)',
  jadeA2: 'color(display-p3 0.024 0.612 0.22 / 0.04)',
  jadeA3: 'color(display-p3 0.012 0.596 0.235 / 0.087)',
  jadeA4: 'color(display-p3 0.008 0.588 0.255 / 0.142)',
  jadeA5: 'color(display-p3 0.004 0.561 0.251 / 0.204)',
  jadeA6: 'color(display-p3 0.004 0.525 0.278 / 0.287)',
  jadeA7: 'color(display-p3 0.004 0.506 0.29 / 0.397)',
  jadeA8: 'color(display-p3 0 0.506 0.337 / 0.561)',
  jadeA9: 'color(display-p3 0 0.459 0.298 / 0.683)',
  jadeA10: 'color(display-p3 0 0.42 0.271 / 0.702)',
  jadeA11: 'color(display-p3 0.15 0.5 0.37)',
  jadeA12: 'color(display-p3 0.142 0.229 0.194)',
}

export const green = {
  green1: '#fbfefc',
  green2: '#f4fbf6',
  green3: '#e6f6eb',
  green4: '#d6f1df',
  green5: '#c4e8d1',
  green6: '#adddc0',
  green7: '#8eceaa',
  green8: '#5bb98b',
  green9: '#30a46c',
  green10: '#2b9a66',
  green11: '#218358',
  green12: '#193b2d',
}

export const greenA = {
  greenA1: '#00c04004',
  greenA2: '#00a32f0b',
  greenA3: '#00a43319',
  greenA4: '#00a83829',
  greenA5: '#019c393b',
  greenA6: '#00963c52',
  greenA7: '#00914071',
  greenA8: '#00924ba4',
  greenA9: '#008f4acf',
  greenA10: '#008647d4',
  greenA11: '#00713fde',
  greenA12: '#002616e6',
}

export const greenP3 = {
  green1: 'color(display-p3 0.986 0.996 0.989)',
  green2: 'color(display-p3 0.963 0.983 0.967)',
  green3: 'color(display-p3 0.913 0.964 0.925)',
  green4: 'color(display-p3 0.859 0.94 0.879)',
  green5: 'color(display-p3 0.796 0.907 0.826)',
  green6: 'color(display-p3 0.718 0.863 0.761)',
  green7: 'color(display-p3 0.61 0.801 0.675)',
  green8: 'color(display-p3 0.451 0.715 0.559)',
  green9: 'color(display-p3 0.332 0.634 0.442)',
  green10: 'color(display-p3 0.308 0.595 0.417)',
  green11: 'color(display-p3 0.19 0.5 0.32)',
  green12: 'color(display-p3 0.132 0.228 0.18)',
}

export const greenP3A = {
  greenA1: 'color(display-p3 0.024 0.757 0.267 / 0.016)',
  greenA2: 'color(display-p3 0.024 0.565 0.129 / 0.036)',
  greenA3: 'color(display-p3 0.012 0.596 0.145 / 0.087)',
  greenA4: 'color(display-p3 0.008 0.588 0.145 / 0.142)',
  greenA5: 'color(display-p3 0.004 0.541 0.157 / 0.204)',
  greenA6: 'color(display-p3 0.004 0.518 0.157 / 0.283)',
  greenA7: 'color(display-p3 0.004 0.486 0.165 / 0.389)',
  greenA8: 'color(display-p3 0 0.478 0.2 / 0.55)',
  greenA9: 'color(display-p3 0 0.455 0.165 / 0.667)',
  greenA10: 'color(display-p3 0 0.416 0.153 / 0.691)',
  greenA11: 'color(display-p3 0.19 0.5 0.32)',
  greenA12: 'color(display-p3 0.132 0.228 0.18)',
}

export const grass = {
  grass1: '#fbfefb',
  grass2: '#f5fbf5',
  grass3: '#e9f6e9',
  grass4: '#daf1db',
  grass5: '#c9e8ca',
  grass6: '#b2ddb5',
  grass7: '#94ce9a',
  grass8: '#65ba74',
  grass9: '#46a758',
  grass10: '#3e9b4f',
  grass11: '#2a7e3b',
  grass12: '#203c25',
}

export const grassA = {
  grassA1: '#00c00004',
  grassA2: '#0099000a',
  grassA3: '#00970016',
  grassA4: '#009f0725',
  grassA5: '#00930536',
  grassA6: '#008f0a4d',
  grassA7: '#018b0f6b',
  grassA8: '#008d199a',
  grassA9: '#008619b9',
  grassA10: '#007b17c1',
  grassA11: '#006514d5',
  grassA12: '#002006df',
}

export const grassP3 = {
  grass1: 'color(display-p3 0.986 0.996 0.985)',
  grass2: 'color(display-p3 0.966 0.983 0.964)',
  grass3: 'color(display-p3 0.923 0.965 0.917)',
  grass4: 'color(display-p3 0.872 0.94 0.865)',
  grass5: 'color(display-p3 0.811 0.908 0.802)',
  grass6: 'color(display-p3 0.733 0.864 0.724)',
  grass7: 'color(display-p3 0.628 0.803 0.622)',
  grass8: 'color(display-p3 0.477 0.72 0.482)',
  grass9: 'color(display-p3 0.38 0.647 0.378)',
  grass10: 'color(display-p3 0.344 0.598 0.342)',
  grass11: 'color(display-p3 0.263 0.488 0.261)',
  grass12: 'color(display-p3 0.151 0.233 0.153)',
}

export const grassP3A = {
  grassA1: 'color(display-p3 0.024 0.757 0.024 / 0.016)',
  grassA2: 'color(display-p3 0.024 0.565 0.024 / 0.036)',
  grassA3: 'color(display-p3 0.059 0.576 0.008 / 0.083)',
  grassA4: 'color(display-p3 0.035 0.565 0.008 / 0.134)',
  grassA5: 'color(display-p3 0.047 0.545 0.008 / 0.197)',
  grassA6: 'color(display-p3 0.031 0.502 0.004 / 0.275)',
  grassA7: 'color(display-p3 0.012 0.482 0.004 / 0.377)',
  grassA8: 'color(display-p3 0 0.467 0.008 / 0.522)',
  grassA9: 'color(display-p3 0.008 0.435 0 / 0.624)',
  grassA10: 'color(display-p3 0.008 0.388 0 / 0.659)',
  grassA11: 'color(display-p3 0.263 0.488 0.261)',
  grassA12: 'color(display-p3 0.151 0.233 0.153)',
}

export const brown = {
  brown1: '#fefdfc',
  brown2: '#fcf9f6',
  brown3: '#f6eee7',
  brown4: '#f0e4d9',
  brown5: '#ebdaca',
  brown6: '#e4cdb7',
  brown7: '#dcbc9f',
  brown8: '#cea37e',
  brown9: '#ad7f58',
  brown10: '#a07553',
  brown11: '#815e46',
  brown12: '#3e332e',
}

export const brownA = {
  brownA1: '#aa550003',
  brownA2: '#aa550009',
  brownA3: '#a04b0018',
  brownA4: '#9b4a0026',
  brownA5: '#9f4d0035',
  brownA6: '#a04e0048',
  brownA7: '#a34e0060',
  brownA8: '#9f4a0081',
  brownA9: '#823c00a7',
  brownA10: '#723300ac',
  brownA11: '#522100b9',
  brownA12: '#140600d1',
}

export const brownP3 = {
  brown1: 'color(display-p3 0.995 0.992 0.989)',
  brown2: 'color(display-p3 0.987 0.976 0.964)',
  brown3: 'color(display-p3 0.959 0.936 0.909)',
  brown4: 'color(display-p3 0.934 0.897 0.855)',
  brown5: 'color(display-p3 0.909 0.856 0.798)',
  brown6: 'color(display-p3 0.88 0.808 0.73)',
  brown7: 'color(display-p3 0.841 0.742 0.639)',
  brown8: 'color(display-p3 0.782 0.647 0.514)',
  brown9: 'color(display-p3 0.651 0.505 0.368)',
  brown10: 'color(display-p3 0.601 0.465 0.344)',
  brown11: 'color(display-p3 0.485 0.374 0.288)',
  brown12: 'color(display-p3 0.236 0.202 0.183)',
}

export const brownP3A = {
  brownA1: 'color(display-p3 0.675 0.349 0.024 / 0.012)',
  brownA2: 'color(display-p3 0.675 0.349 0.024 / 0.036)',
  brownA3: 'color(display-p3 0.573 0.314 0.012 / 0.091)',
  brownA4: 'color(display-p3 0.545 0.302 0.008 / 0.146)',
  brownA5: 'color(display-p3 0.561 0.29 0.004 / 0.204)',
  brownA6: 'color(display-p3 0.553 0.294 0.004 / 0.271)',
  brownA7: 'color(display-p3 0.557 0.286 0.004 / 0.361)',
  brownA8: 'color(display-p3 0.549 0.275 0.004 / 0.487)',
  brownA9: 'color(display-p3 0.447 0.22 0 / 0.632)',
  brownA10: 'color(display-p3 0.388 0.188 0 / 0.655)',
  brownA11: 'color(display-p3 0.485 0.374 0.288)',
  brownA12: 'color(display-p3 0.236 0.202 0.183)',
}

export const bronze = {
  bronze1: '#fdfcfc',
  bronze2: '#fdf7f5',
  bronze3: '#f6edea',
  bronze4: '#efe4df',
  bronze5: '#e7d9d3',
  bronze6: '#dfcdc5',
  bronze7: '#d3bcb3',
  bronze8: '#c2a499',
  bronze9: '#a18072',
  bronze10: '#957468',
  bronze11: '#7d5e54',
  bronze12: '#43302b',
}

export const bronzeA = {
  bronzeA1: '#55000003',
  bronzeA2: '#cc33000a',
  bronzeA3: '#92250015',
  bronzeA4: '#80280020',
  bronzeA5: '#7423002c',
  bronzeA6: '#7324003a',
  bronzeA7: '#6c1f004c',
  bronzeA8: '#671c0066',
  bronzeA9: '#551a008d',
  bronzeA10: '#4c150097',
  bronzeA11: '#3d0f00ab',
  bronzeA12: '#1d0600d4',
}

export const bronzeP3 = {
  bronze1: 'color(display-p3 0.991 0.988 0.988)',
  bronze2: 'color(display-p3 0.989 0.97 0.961)',
  bronze3: 'color(display-p3 0.958 0.932 0.919)',
  bronze4: 'color(display-p3 0.929 0.894 0.877)',
  bronze5: 'color(display-p3 0.898 0.853 0.832)',
  bronze6: 'color(display-p3 0.861 0.805 0.778)',
  bronze7: 'color(display-p3 0.812 0.739 0.706)',
  bronze8: 'color(display-p3 0.741 0.647 0.606)',
  bronze9: 'color(display-p3 0.611 0.507 0.455)',
  bronze10: 'color(display-p3 0.563 0.461 0.414)',
  bronze11: 'color(display-p3 0.471 0.373 0.336)',
  bronze12: 'color(display-p3 0.251 0.191 0.172)',
}

export const bronzeP3A = {
  bronzeA1: 'color(display-p3 0.349 0.024 0.024 / 0.012)',
  bronzeA2: 'color(display-p3 0.71 0.22 0.024 / 0.04)',
  bronzeA3: 'color(display-p3 0.482 0.2 0.008 / 0.083)',
  bronzeA4: 'color(display-p3 0.424 0.133 0.004 / 0.122)',
  bronzeA5: 'color(display-p3 0.4 0.145 0.004 / 0.169)',
  bronzeA6: 'color(display-p3 0.388 0.125 0.004 / 0.224)',
  bronzeA7: 'color(display-p3 0.365 0.11 0.004 / 0.295)',
  bronzeA8: 'color(display-p3 0.341 0.102 0.004 / 0.393)',
  bronzeA9: 'color(display-p3 0.29 0.094 0 / 0.546)',
  bronzeA10: 'color(display-p3 0.255 0.082 0 / 0.585)',
  bronzeA11: 'color(display-p3 0.471 0.373 0.336)',
  bronzeA12: 'color(display-p3 0.251 0.191 0.172)',
}

export const gold = {
  gold1: '#fdfdfc',
  gold2: '#faf9f2',
  gold3: '#f2f0e7',
  gold4: '#eae6db',
  gold5: '#e1dccf',
  gold6: '#d8d0bf',
  gold7: '#cbc0aa',
  gold8: '#b9a88d',
  gold9: '#978365',
  gold10: '#8c7a5e',
  gold11: '#71624b',
  gold12: '#3b352b',
}

export const goldA = {
  goldA1: '#55550003',
  goldA2: '#9d8a000d',
  goldA3: '#75600018',
  goldA4: '#6b4e0024',
  goldA5: '#60460030',
  goldA6: '#64440040',
  goldA7: '#63420055',
  goldA8: '#633d0072',
  goldA9: '#5332009a',
  goldA10: '#492d00a1',
  goldA11: '#362100b4',
  goldA12: '#130c00d4',
}

export const goldP3 = {
  gold1: 'color(display-p3 0.992 0.992 0.989)',
  gold2: 'color(display-p3 0.98 0.976 0.953)',
  gold3: 'color(display-p3 0.947 0.94 0.909)',
  gold4: 'color(display-p3 0.914 0.904 0.865)',
  gold5: 'color(display-p3 0.88 0.865 0.816)',
  gold6: 'color(display-p3 0.84 0.818 0.756)',
  gold7: 'color(display-p3 0.788 0.753 0.677)',
  gold8: 'color(display-p3 0.715 0.66 0.565)',
  gold9: 'color(display-p3 0.579 0.517 0.41)',
  gold10: 'color(display-p3 0.538 0.479 0.38)',
  gold11: 'color(display-p3 0.433 0.386 0.305)',
  gold12: 'color(display-p3 0.227 0.209 0.173)',
}

export const goldP3A = {
  goldA1: 'color(display-p3 0.349 0.349 0.024 / 0.012)',
  goldA2: 'color(display-p3 0.592 0.514 0.024 / 0.048)',
  goldA3: 'color(display-p3 0.4 0.357 0.012 / 0.091)',
  goldA4: 'color(display-p3 0.357 0.298 0.008 / 0.134)',
  goldA5: 'color(display-p3 0.345 0.282 0.004 / 0.185)',
  goldA6: 'color(display-p3 0.341 0.263 0.004 / 0.244)',
  goldA7: 'color(display-p3 0.345 0.235 0.004 / 0.322)',
  goldA8: 'color(display-p3 0.345 0.22 0.004 / 0.436)',
  goldA9: 'color(display-p3 0.286 0.18 0 / 0.589)',
  goldA10: 'color(display-p3 0.255 0.161 0 / 0.62)',
  goldA11: 'color(display-p3 0.433 0.386 0.305)',
  goldA12: 'color(display-p3 0.227 0.209 0.173)',
}

export const sky = {
  sky1: '#f9feff',
  sky2: '#f1fafd',
  sky3: '#e1f6fd',
  sky4: '#d1f0fa',
  sky5: '#bee7f5',
  sky6: '#a9daed',
  sky7: '#8dcae3',
  sky8: '#60b3d7',
  sky9: '#7ce2fe',
  sky10: '#74daf8',
  sky11: '#00749e',
  sky12: '#1d3e56',
}

export const skyA = {
  skyA1: '#00d5ff06',
  skyA2: '#00a4db0e',
  skyA3: '#00b3ee1e',
  skyA4: '#00ace42e',
  skyA5: '#00a1d841',
  skyA6: '#0092ca56',
  skyA7: '#0089c172',
  skyA8: '#0085bf9f',
  skyA9: '#00c7fe83',
  skyA10: '#00bcf38b',
  skyA11: '#00749e',
  skyA12: '#002540e2',
}

export const skyP3 = {
  sky1: 'color(display-p3 0.98 0.995 0.999)',
  sky2: 'color(display-p3 0.953 0.98 0.99)',
  sky3: 'color(display-p3 0.899 0.963 0.989)',
  sky4: 'color(display-p3 0.842 0.937 0.977)',
  sky5: 'color(display-p3 0.777 0.9 0.954)',
  sky6: 'color(display-p3 0.701 0.851 0.921)',
  sky7: 'color(display-p3 0.604 0.785 0.879)',
  sky8: 'color(display-p3 0.457 0.696 0.829)',
  sky9: 'color(display-p3 0.585 0.877 0.983)',
  sky10: 'color(display-p3 0.555 0.845 0.959)',
  sky11: 'color(display-p3 0.193 0.448 0.605)',
  sky12: 'color(display-p3 0.145 0.241 0.329)',
}

export const skyP3A = {
  skyA1: 'color(display-p3 0.02 0.804 1 / 0.02)',
  skyA2: 'color(display-p3 0.024 0.592 0.757 / 0.048)',
  skyA3: 'color(display-p3 0.004 0.655 0.886 / 0.102)',
  skyA4: 'color(display-p3 0.004 0.604 0.851 / 0.157)',
  skyA5: 'color(display-p3 0.004 0.565 0.792 / 0.224)',
  skyA6: 'color(display-p3 0.004 0.502 0.737 / 0.299)',
  skyA7: 'color(display-p3 0.004 0.459 0.694 / 0.397)',
  skyA8: 'color(display-p3 0 0.435 0.682 / 0.542)',
  skyA9: 'color(display-p3 0.004 0.71 0.965 / 0.416)',
  skyA10: 'color(display-p3 0.004 0.647 0.914 / 0.444)',
  skyA11: 'color(display-p3 0.193 0.448 0.605)',
  skyA12: 'color(display-p3 0.145 0.241 0.329)',
}

export const mint = {
  mint1: '#f9fefd',
  mint2: '#f2fbf9',
  mint3: '#ddf9f2',
  mint4: '#c8f4e9',
  mint5: '#b3ecde',
  mint6: '#9ce0d0',
  mint7: '#7ecfbd',
  mint8: '#4cbba5',
  mint9: '#86ead4',
  mint10: '#7de0cb',
  mint11: '#027864',
  mint12: '#16433c',
}

export const mintA = {
  mintA1: '#00d5aa06',
  mintA2: '#00b18a0d',
  mintA3: '#00d29e22',
  mintA4: '#00cc9937',
  mintA5: '#00c0914c',
  mintA6: '#00b08663',
  mintA7: '#00a17d81',
  mintA8: '#009e7fb3',
  mintA9: '#00d3a579',
  mintA10: '#00c39982',
  mintA11: '#007763fd',
  mintA12: '#00312ae9',
}

export const mintP3 = {
  mint1: 'color(display-p3 0.98 0.995 0.992)',
  mint2: 'color(display-p3 0.957 0.985 0.977)',
  mint3: 'color(display-p3 0.888 0.972 0.95)',
  mint4: 'color(display-p3 0.819 0.951 0.916)',
  mint5: 'color(display-p3 0.747 0.918 0.873)',
  mint6: 'color(display-p3 0.668 0.87 0.818)',
  mint7: 'color(display-p3 0.567 0.805 0.744)',
  mint8: 'color(display-p3 0.42 0.724 0.649)',
  mint9: 'color(display-p3 0.62 0.908 0.834)',
  mint10: 'color(display-p3 0.585 0.871 0.797)',
  mint11: 'color(display-p3 0.203 0.463 0.397)',
  mint12: 'color(display-p3 0.136 0.259 0.236)',
}

export const mintP3A = {
  mintA1: 'color(display-p3 0.02 0.804 0.608 / 0.02)',
  mintA2: 'color(display-p3 0.02 0.647 0.467 / 0.044)',
  mintA3: 'color(display-p3 0.004 0.761 0.553 / 0.114)',
  mintA4: 'color(display-p3 0.004 0.741 0.545 / 0.181)',
  mintA5: 'color(display-p3 0.004 0.678 0.51 / 0.255)',
  mintA6: 'color(display-p3 0.004 0.616 0.463 / 0.334)',
  mintA7: 'color(display-p3 0.004 0.549 0.412 / 0.432)',
  mintA8: 'color(display-p3 0 0.529 0.392 / 0.581)',
  mintA9: 'color(display-p3 0.004 0.765 0.569 / 0.381)',
  mintA10: 'color(display-p3 0.004 0.69 0.51 / 0.416)',
  mintA11: 'color(display-p3 0.203 0.463 0.397)',
  mintA12: 'color(display-p3 0.136 0.259 0.236)',
}

export const lime = {
  lime1: '#fcfdfa',
  lime2: '#f8faf3',
  lime3: '#eef6d6',
  lime4: '#e2f0bd',
  lime5: '#d3e7a6',
  lime6: '#c2da91',
  lime7: '#abc978',
  lime8: '#8db654',
  lime9: '#bdee63',
  lime10: '#b0e64c',
  lime11: '#5c7c2f',
  lime12: '#37401c',
}

export const limeA = {
  limeA1: '#66990005',
  limeA2: '#6b95000c',
  limeA3: '#96c80029',
  limeA4: '#8fc60042',
  limeA5: '#81bb0059',
  limeA6: '#72aa006e',
  limeA7: '#61990087',
  limeA8: '#559200ab',
  limeA9: '#93e4009c',
  limeA10: '#8fdc00b3',
  limeA11: '#375f00d0',
  limeA12: '#1e2900e3',
}

export const limeP3 = {
  lime1: 'color(display-p3 0.989 0.992 0.981)',
  lime2: 'color(display-p3 0.975 0.98 0.954)',
  lime3: 'color(display-p3 0.939 0.965 0.851)',
  lime4: 'color(display-p3 0.896 0.94 0.76)',
  lime5: 'color(display-p3 0.843 0.903 0.678)',
  lime6: 'color(display-p3 0.778 0.852 0.599)',
  lime7: 'color(display-p3 0.694 0.784 0.508)',
  lime8: 'color(display-p3 0.585 0.707 0.378)',
  lime9: 'color(display-p3 0.78 0.928 0.466)',
  lime10: 'color(display-p3 0.734 0.896 0.397)',
  lime11: 'color(display-p3 0.386 0.482 0.227)',
  lime12: 'color(display-p3 0.222 0.25 0.128)',
}

export const limeP3A = {
  limeA1: 'color(display-p3 0.412 0.608 0.02 / 0.02)',
  limeA2: 'color(display-p3 0.514 0.592 0.024 / 0.048)',
  limeA3: 'color(display-p3 0.584 0.765 0.008 / 0.15)',
  limeA4: 'color(display-p3 0.561 0.757 0.004 / 0.24)',
  limeA5: 'color(display-p3 0.514 0.698 0.004 / 0.322)',
  limeA6: 'color(display-p3 0.443 0.627 0 / 0.4)',
  limeA7: 'color(display-p3 0.376 0.561 0.004 / 0.491)',
  limeA8: 'color(display-p3 0.333 0.529 0 / 0.624)',
  limeA9: 'color(display-p3 0.588 0.867 0 / 0.534)',
  limeA10: 'color(display-p3 0.561 0.827 0 / 0.604)',
  limeA11: 'color(display-p3 0.386 0.482 0.227)',
  limeA12: 'color(display-p3 0.222 0.25 0.128)',
}

export const yellow = {
  yellow1: '#fdfdf9',
  yellow2: '#fefce9',
  yellow3: '#fffab8',
  yellow4: '#fff394',
  yellow5: '#ffe770',
  yellow6: '#f3d768',
  yellow7: '#e4c767',
  yellow8: '#d5ae39',
  yellow9: '#ffe629',
  yellow10: '#ffdc00',
  yellow11: '#9e6c00',
  yellow12: '#473b1f',
}

export const yellowA = {
  yellowA1: '#aaaa0006',
  yellowA2: '#f4dd0016',
  yellowA3: '#ffee0047',
  yellowA4: '#ffe3016b',
  yellowA5: '#ffd5008f',
  yellowA6: '#ebbc0097',
  yellowA7: '#d2a10098',
  yellowA8: '#c99700c6',
  yellowA9: '#ffe100d6',
  yellowA10: '#ffdc00',
  yellowA11: '#9e6c00',
  yellowA12: '#2e2000e0',
}

export const yellowP3 = {
  yellow1: 'color(display-p3 0.992 0.992 0.978)',
  yellow2: 'color(display-p3 0.995 0.99 0.922)',
  yellow3: 'color(display-p3 0.997 0.982 0.749)',
  yellow4: 'color(display-p3 0.992 0.953 0.627)',
  yellow5: 'color(display-p3 0.984 0.91 0.51)',
  yellow6: 'color(display-p3 0.934 0.847 0.474)',
  yellow7: 'color(display-p3 0.876 0.785 0.46)',
  yellow8: 'color(display-p3 0.811 0.689 0.313)',
  yellow9: 'color(display-p3 1 0.92 0.22)',
  yellow10: 'color(display-p3 0.977 0.868 0.291)',
  yellow11: 'color(display-p3 0.6 0.44 0)',
  yellow12: 'color(display-p3 0.271 0.233 0.137)',
}

export const yellowP3A = {
  yellowA1: 'color(display-p3 0.675 0.675 0.024 / 0.024)',
  yellowA2: 'color(display-p3 0.953 0.855 0.008 / 0.079)',
  yellowA3: 'color(display-p3 0.988 0.925 0.004 / 0.251)',
  yellowA4: 'color(display-p3 0.98 0.875 0.004 / 0.373)',
  yellowA5: 'color(display-p3 0.969 0.816 0.004 / 0.491)',
  yellowA6: 'color(display-p3 0.875 0.71 0 / 0.526)',
  yellowA7: 'color(display-p3 0.769 0.604 0 / 0.542)',
  yellowA8: 'color(display-p3 0.725 0.549 0 / 0.687)',
  yellowA9: 'color(display-p3 1 0.898 0 / 0.781)',
  yellowA10: 'color(display-p3 0.969 0.812 0 / 0.71)',
  yellowA11: 'color(display-p3 0.6 0.44 0)',
  yellowA12: 'color(display-p3 0.271 0.233 0.137)',
}

export const amber = {
  amber1: '#fefdfb',
  amber2: '#fefbe9',
  amber3: '#fff7c2',
  amber4: '#ffee9c',
  amber5: '#fbe577',
  amber6: '#f3d673',
  amber7: '#e9c162',
  amber8: '#e2a336',
  amber9: '#ffc53d',
  amber10: '#ffba18',
  amber11: '#ab6400',
  amber12: '#4f3422',
}

export const amberA = {
  amberA1: '#c0800004',
  amberA2: '#f4d10016',
  amberA3: '#ffde003d',
  amberA4: '#ffd40063',
  amberA5: '#f8cf0088',
  amberA6: '#eab5008c',
  amberA7: '#dc9b009d',
  amberA8: '#da8a00c9',
  amberA9: '#ffb300c2',
  amberA10: '#ffb300e7',
  amberA11: '#ab6400',
  amberA12: '#341500dd',
}

export const amberP3 = {
  amber1: 'color(display-p3 0.995 0.992 0.985)',
  amber2: 'color(display-p3 0.994 0.986 0.921)',
  amber3: 'color(display-p3 0.994 0.969 0.782)',
  amber4: 'color(display-p3 0.989 0.937 0.65)',
  amber5: 'color(display-p3 0.97 0.902 0.527)',
  amber6: 'color(display-p3 0.936 0.844 0.506)',
  amber7: 'color(display-p3 0.89 0.762 0.443)',
  amber8: 'color(display-p3 0.85 0.65 0.3)',
  amber9: 'color(display-p3 1 0.77 0.26)',
  amber10: 'color(display-p3 0.959 0.741 0.274)',
  amber11: 'color(display-p3 0.64 0.4 0)',
  amber12: 'color(display-p3 0.294 0.208 0.145)',
}

export const amberP3A = {
  amberA1: 'color(display-p3 0.757 0.514 0.024 / 0.016)',
  amberA2: 'color(display-p3 0.902 0.804 0.008 / 0.079)',
  amberA3: 'color(display-p3 0.965 0.859 0.004 / 0.22)',
  amberA4: 'color(display-p3 0.969 0.82 0.004 / 0.35)',
  amberA5: 'color(display-p3 0.933 0.796 0.004 / 0.475)',
  amberA6: 'color(display-p3 0.875 0.682 0.004 / 0.495)',
  amberA7: 'color(display-p3 0.804 0.573 0 / 0.557)',
  amberA8: 'color(display-p3 0.788 0.502 0 / 0.699)',
  amberA9: 'color(display-p3 1 0.686 0 / 0.742)',
  amberA10: 'color(display-p3 0.945 0.643 0 / 0.726)',
  amberA11: 'color(display-p3 0.64 0.4 0)',
  amberA12: 'color(display-p3 0.294 0.208 0.145)',
}

export const orange = {
  orange1: '#fefcfb',
  orange2: '#fff7ed',
  orange3: '#ffefd6',
  orange4: '#ffdfb5',
  orange5: '#ffd19a',
  orange6: '#ffc182',
  orange7: '#f5ae73',
  orange8: '#ec9455',
  orange9: '#f76b15',
  orange10: '#ef5f00',
  orange11: '#cc4e00',
  orange12: '#582d1d',
}

export const orangeA = {
  orangeA1: '#c0400004',
  orangeA2: '#ff8e0012',
  orangeA3: '#ff9c0029',
  orangeA4: '#ff91014a',
  orangeA5: '#ff8b0065',
  orangeA6: '#ff81007d',
  orangeA7: '#ed6c008c',
  orangeA8: '#e35f00aa',
  orangeA9: '#f65e00ea',
  orangeA10: '#ef5f00',
  orangeA11: '#cc4e00',
  orangeA12: '#431200e2',
}

export const orangeP3 = {
  orange1: 'color(display-p3 0.995 0.988 0.985)',
  orange2: 'color(display-p3 0.994 0.968 0.934)',
  orange3: 'color(display-p3 0.989 0.938 0.85)',
  orange4: 'color(display-p3 1 0.874 0.687)',
  orange5: 'color(display-p3 1 0.821 0.583)',
  orange6: 'color(display-p3 0.975 0.767 0.545)',
  orange7: 'color(display-p3 0.919 0.693 0.486)',
  orange8: 'color(display-p3 0.877 0.597 0.379)',
  orange9: 'color(display-p3 0.9 0.45 0.2)',
  orange10: 'color(display-p3 0.87 0.409 0.164)',
  orange11: 'color(display-p3 0.76 0.34 0)',
  orange12: 'color(display-p3 0.323 0.185 0.127)',
}

export const orangeP3A = {
  orangeA1: 'color(display-p3 0.757 0.267 0.024 / 0.016)',
  orangeA2: 'color(display-p3 0.886 0.533 0.008 / 0.067)',
  orangeA3: 'color(display-p3 0.922 0.584 0.008 / 0.15)',
  orangeA4: 'color(display-p3 1 0.604 0.004 / 0.314)',
  orangeA5: 'color(display-p3 1 0.569 0.004 / 0.416)',
  orangeA6: 'color(display-p3 0.949 0.494 0.004 / 0.455)',
  orangeA7: 'color(display-p3 0.839 0.408 0 / 0.514)',
  orangeA8: 'color(display-p3 0.804 0.349 0 / 0.62)',
  orangeA9: 'color(display-p3 0.878 0.314 0 / 0.8)',
  orangeA10: 'color(display-p3 0.843 0.29 0 / 0.836)',
  orangeA11: 'color(display-p3 0.76 0.34 0)',
  orangeA12: 'color(display-p3 0.323 0.185 0.127)',
}

export const colors = {
  ...gray,
  ...mauve,
  ...slate,
  ...sage,
  ...olive,
  ...sand,
  ...gold,
  ...bronze,
  ...brown,
  ...yellow,
  ...amber,
  ...orange,
  ...tomato,
  ...red,
  ...ruby,
  ...crimson,
  ...pink,
  ...plum,
  ...purple,
  ...violet,
  ...iris,
  ...indigo,
  ...blue,
  ...cyan,
  ...teal,
  ...jade,
  ...green,
  ...grass,
  ...lime,
  ...mint,
  ...sky,
}
