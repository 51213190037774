import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react'

import { StoredGameState } from '../lib/localStorage'
import {
  addToArray,
  isNullish,
  removeFromArray,
  replaceInArray,
} from '../lib/utils'

export interface GameBoardContextType {
  cursorIndex: number
  setCursor: (index: number) => void
  keyInFocus: string
  setKeyInFocus?: (section: string) => void
  currentGuess: string
  setCurrentGuess: (value: string) => void
  state?: StoredGameState
  annotatedKeys?: Array<{ key: string; annotation: string }>
  annotateKey: (key: string, color: string) => void
}
export const GameBoardContext = createContext<GameBoardContextType>({
  cursorIndex: 0,
  setCursor: () => {},
  state: undefined,
  keyInFocus: '',
  annotatedKeys: [],
  currentGuess: '     ',
  setCurrentGuess: (v) => {},
  annotateKey(key, color) {
    return
  },
})

export interface GameBoardProviderProps {
  children: ReactNode
  gameState: StoredGameState
  setCursor: React.Dispatch<React.SetStateAction<number>>
  cursor: number
  currentGuess: string
  setCurrentGuess: (value: string) => void
}

export const GameBoardProvider: React.FC<GameBoardProviderProps> = ({
  children,
  gameState,
  setCursor,
  cursor,
  currentGuess,
  setCurrentGuess,
}) => {
  const [_, setCertificate] = useState<StoredGameState>(gameState)
  const [keyInFocus, setKeyInFocus] = useState<string>('')
  const [keys, setKeys] = useState<Array<{ key: string; annotation: string }>>(
    []
  )

  const annotateKey = useCallback<(key: string, color: string) => void>(
    (key, color) => {
      console.info(key, color)
      const currentKeyIndex = keys.findIndex((k) => k.key === key)
      if (!isNullish(color) && currentKeyIndex <= -1) {
        console.info(`Adding a new key and annotation ${color} for ${key}`)
        setKeys((keys) => [...addToArray(keys, { key, annotation: color })])
      } else if (!isNullish(color) && currentKeyIndex > -1) {
        console.info(
          `Replacing annotation for ${key} since color has changed to ${color}`
        )
        setKeys((keys) => [
          ...replaceInArray(keys, currentKeyIndex, { key, annotation: color }),
        ])
      } else if (isNullish(color) && currentKeyIndex > -1) {
        console.info(`Removing annotation for ${key} since color was ${color}`)
        setKeys((keys) => [...removeFromArray(keys, currentKeyIndex)])
      }
    },
    [setKeys]
  )

  return (
    <GameBoardContext.Provider
      value={{
        currentGuess,
        setCurrentGuess,
        state: gameState,
        keyInFocus,
        setKeyInFocus,
        annotatedKeys: keys,
        annotateKey,
        cursorIndex: cursor,
        setCursor,
      }}
    >
      {children}
    </GameBoardContext.Provider>
  )
}

export const useGameBoard = () => {
  const context = useContext(GameBoardContext)
  if (!context) {
    throw new Error('useGameBoard must be used within a GameBoardProvider')
  }

  return context
}
