export const grayDark = {
  gray1: '#111111',
  gray2: '#191919',
  gray3: '#222222',
  gray4: '#2a2a2a',
  gray5: '#313131',
  gray6: '#3a3a3a',
  gray7: '#484848',
  gray8: '#606060',
  gray9: '#6e6e6e',
  gray10: '#7b7b7b',
  gray11: '#b4b4b4',
  gray12: '#eeeeee',
}

export const grayDarkA = {
  grayA1: '#00000000',
  grayA2: '#ffffff09',
  grayA3: '#ffffff12',
  grayA4: '#ffffff1b',
  grayA5: '#ffffff22',
  grayA6: '#ffffff2c',
  grayA7: '#ffffff3b',
  grayA8: '#ffffff55',
  grayA9: '#ffffff64',
  grayA10: '#ffffff72',
  grayA11: '#ffffffaf',
  grayA12: '#ffffffed',
}

export const grayDarkP3 = {
  gray1: 'color(display-p3 0.067 0.067 0.067)',
  gray2: 'color(display-p3 0.098 0.098 0.098)',
  gray3: 'color(display-p3 0.135 0.135 0.135)',
  gray4: 'color(display-p3 0.163 0.163 0.163)',
  gray5: 'color(display-p3 0.192 0.192 0.192)',
  gray6: 'color(display-p3 0.228 0.228 0.228)',
  gray7: 'color(display-p3 0.283 0.283 0.283)',
  gray8: 'color(display-p3 0.375 0.375 0.375)',
  gray9: 'color(display-p3 0.431 0.431 0.431)',
  gray10: 'color(display-p3 0.484 0.484 0.484)',
  gray11: 'color(display-p3 0.706 0.706 0.706)',
  gray12: 'color(display-p3 0.933 0.933 0.933)',
}

export const grayDarkP3A = {
  grayA1: 'color(display-p3 0 0 0 / 0)',
  grayA2: 'color(display-p3 1 1 1 / 0.034)',
  grayA3: 'color(display-p3 1 1 1 / 0.071)',
  grayA4: 'color(display-p3 1 1 1 / 0.105)',
  grayA5: 'color(display-p3 1 1 1 / 0.134)',
  grayA6: 'color(display-p3 1 1 1 / 0.172)',
  grayA7: 'color(display-p3 1 1 1 / 0.231)',
  grayA8: 'color(display-p3 1 1 1 / 0.332)',
  grayA9: 'color(display-p3 1 1 1 / 0.391)',
  grayA10: 'color(display-p3 1 1 1 / 0.445)',
  grayA11: 'color(display-p3 1 1 1 / 0.685)',
  grayA12: 'color(display-p3 1 1 1 / 0.929)',
}

export const mauveDark = {
  mauve1: '#121113',
  mauve2: '#1a191b',
  mauve3: '#232225',
  mauve4: '#2b292d',
  mauve5: '#323035',
  mauve6: '#3c393f',
  mauve7: '#49474e',
  mauve8: '#625f69',
  mauve9: '#6f6d78',
  mauve10: '#7c7a85',
  mauve11: '#b5b2bc',
  mauve12: '#eeeef0',
}

export const mauveDarkA = {
  mauveA1: '#00000000',
  mauveA2: '#f5f4f609',
  mauveA3: '#ebeaf814',
  mauveA4: '#eee5f81d',
  mauveA5: '#efe6fe25',
  mauveA6: '#f1e6fd30',
  mauveA7: '#eee9ff40',
  mauveA8: '#eee7ff5d',
  mauveA9: '#eae6fd6e',
  mauveA10: '#ece9fd7c',
  mauveA11: '#f5f1ffb7',
  mauveA12: '#fdfdffef',
}

export const mauveDarkP3 = {
  mauve1: 'color(display-p3 0.07 0.067 0.074)',
  mauve2: 'color(display-p3 0.101 0.098 0.105)',
  mauve3: 'color(display-p3 0.138 0.134 0.144)',
  mauve4: 'color(display-p3 0.167 0.161 0.175)',
  mauve5: 'color(display-p3 0.196 0.189 0.206)',
  mauve6: 'color(display-p3 0.232 0.225 0.245)',
  mauve7: 'color(display-p3 0.286 0.277 0.302)',
  mauve8: 'color(display-p3 0.383 0.373 0.408)',
  mauve9: 'color(display-p3 0.434 0.428 0.467)',
  mauve10: 'color(display-p3 0.487 0.48 0.519)',
  mauve11: 'color(display-p3 0.707 0.7 0.735)',
  mauve12: 'color(display-p3 0.933 0.933 0.94)',
}

export const mauveDarkP3A = {
  mauveA1: 'color(display-p3 0 0 0 / 0)',
  mauveA2: 'color(display-p3 0.996 0.992 1 / 0.034)',
  mauveA3: 'color(display-p3 0.937 0.933 0.992 / 0.077)',
  mauveA4: 'color(display-p3 0.957 0.918 0.996 / 0.111)',
  mauveA5: 'color(display-p3 0.937 0.906 0.996 / 0.145)',
  mauveA6: 'color(display-p3 0.953 0.925 0.996 / 0.183)',
  mauveA7: 'color(display-p3 0.945 0.929 1 / 0.246)',
  mauveA8: 'color(display-p3 0.937 0.918 1 / 0.361)',
  mauveA9: 'color(display-p3 0.933 0.918 1 / 0.424)',
  mauveA10: 'color(display-p3 0.941 0.925 1 / 0.479)',
  mauveA11: 'color(display-p3 0.965 0.961 1 / 0.712)',
  mauveA12: 'color(display-p3 0.992 0.992 1 / 0.937)',
}

export const slateDark = {
  slate1: '#111113',
  slate2: '#18191b',
  slate3: '#212225',
  slate4: '#272a2d',
  slate5: '#2e3135',
  slate6: '#363a3f',
  slate7: '#43484e',
  slate8: '#5a6169',
  slate9: '#696e77',
  slate10: '#777b84',
  slate11: '#b0b4ba',
  slate12: '#edeef0',
}

export const slateDarkA = {
  slateA1: '#00000000',
  slateA2: '#d8f4f609',
  slateA3: '#ddeaf814',
  slateA4: '#d3edf81d',
  slateA5: '#d9edfe25',
  slateA6: '#d6ebfd30',
  slateA7: '#d9edff40',
  slateA8: '#d9edff5d',
  slateA9: '#dfebfd6d',
  slateA10: '#e5edfd7b',
  slateA11: '#f1f7feb5',
  slateA12: '#fcfdffef',
}

export const slateDarkP3 = {
  slate1: 'color(display-p3 0.067 0.067 0.074)',
  slate2: 'color(display-p3 0.095 0.098 0.105)',
  slate3: 'color(display-p3 0.13 0.135 0.145)',
  slate4: 'color(display-p3 0.156 0.163 0.176)',
  slate5: 'color(display-p3 0.183 0.191 0.206)',
  slate6: 'color(display-p3 0.215 0.226 0.244)',
  slate7: 'color(display-p3 0.265 0.28 0.302)',
  slate8: 'color(display-p3 0.357 0.381 0.409)',
  slate9: 'color(display-p3 0.415 0.431 0.463)',
  slate10: 'color(display-p3 0.469 0.483 0.514)',
  slate11: 'color(display-p3 0.692 0.704 0.728)',
  slate12: 'color(display-p3 0.93 0.933 0.94)',
}

export const slateDarkP3A = {
  slateA1: 'color(display-p3 0 0 0 / 0)',
  slateA2: 'color(display-p3 0.875 0.992 1 / 0.034)',
  slateA3: 'color(display-p3 0.882 0.933 0.992 / 0.077)',
  slateA4: 'color(display-p3 0.882 0.953 0.996 / 0.111)',
  slateA5: 'color(display-p3 0.878 0.929 0.996 / 0.145)',
  slateA6: 'color(display-p3 0.882 0.949 0.996 / 0.183)',
  slateA7: 'color(display-p3 0.882 0.929 1 / 0.246)',
  slateA8: 'color(display-p3 0.871 0.937 1 / 0.361)',
  slateA9: 'color(display-p3 0.898 0.937 1 / 0.42)',
  slateA10: 'color(display-p3 0.918 0.945 1 / 0.475)',
  slateA11: 'color(display-p3 0.949 0.969 0.996 / 0.708)',
  slateA12: 'color(display-p3 0.988 0.992 1 / 0.937)',
}

export const sageDark = {
  sage1: '#101211',
  sage2: '#171918',
  sage3: '#202221',
  sage4: '#272a29',
  sage5: '#2e3130',
  sage6: '#373b39',
  sage7: '#444947',
  sage8: '#5b625f',
  sage9: '#63706b',
  sage10: '#717d79',
  sage11: '#adb5b2',
  sage12: '#eceeed',
}

export const sageDarkA = {
  sageA1: '#00000000',
  sageA2: '#f0f2f108',
  sageA3: '#f3f5f412',
  sageA4: '#f2fefd1a',
  sageA5: '#f1fbfa22',
  sageA6: '#edfbf42d',
  sageA7: '#edfcf73c',
  sageA8: '#ebfdf657',
  sageA9: '#dffdf266',
  sageA10: '#e5fdf674',
  sageA11: '#f4fefbb0',
  sageA12: '#fdfffeed',
}

export const sageDarkP3 = {
  sage1: 'color(display-p3 0.064 0.07 0.067)',
  sage2: 'color(display-p3 0.092 0.098 0.094)',
  sage3: 'color(display-p3 0.128 0.135 0.131)',
  sage4: 'color(display-p3 0.155 0.164 0.159)',
  sage5: 'color(display-p3 0.183 0.193 0.188)',
  sage6: 'color(display-p3 0.218 0.23 0.224)',
  sage7: 'color(display-p3 0.269 0.285 0.277)',
  sage8: 'color(display-p3 0.362 0.382 0.373)',
  sage9: 'color(display-p3 0.398 0.438 0.421)',
  sage10: 'color(display-p3 0.453 0.49 0.474)',
  sage11: 'color(display-p3 0.685 0.709 0.697)',
  sage12: 'color(display-p3 0.927 0.933 0.93)',
}

export const sageDarkP3A = {
  sageA1: 'color(display-p3 0 0 0 / 0)',
  sageA2: 'color(display-p3 0.976 0.988 0.984 / 0.03)',
  sageA3: 'color(display-p3 0.992 0.945 0.941 / 0.072)',
  sageA4: 'color(display-p3 0.988 0.996 0.992 / 0.102)',
  sageA5: 'color(display-p3 0.992 1 0.996 / 0.131)',
  sageA6: 'color(display-p3 0.973 1 0.976 / 0.173)',
  sageA7: 'color(display-p3 0.957 1 0.976 / 0.233)',
  sageA8: 'color(display-p3 0.957 1 0.984 / 0.334)',
  sageA9: 'color(display-p3 0.902 1 0.957 / 0.397)',
  sageA10: 'color(display-p3 0.929 1 0.973 / 0.452)',
  sageA11: 'color(display-p3 0.969 1 0.988 / 0.688)',
  sageA12: 'color(display-p3 0.992 1 0.996 / 0.929)',
}

export const oliveDark = {
  olive1: '#111210',
  olive2: '#181917',
  olive3: '#212220',
  olive4: '#282a27',
  olive5: '#2f312e',
  olive6: '#383a36',
  olive7: '#454843',
  olive8: '#5c625b',
  olive9: '#687066',
  olive10: '#767d74',
  olive11: '#afb5ad',
  olive12: '#eceeec',
}

export const oliveDarkA = {
  oliveA1: '#00000000',
  oliveA2: '#f1f2f008',
  oliveA3: '#f4f5f312',
  oliveA4: '#f3fef21a',
  oliveA5: '#f2fbf122',
  oliveA6: '#f4faed2c',
  oliveA7: '#f2fced3b',
  oliveA8: '#edfdeb57',
  oliveA9: '#ebfde766',
  oliveA10: '#f0fdec74',
  oliveA11: '#f6fef4b0',
  oliveA12: '#fdfffded',
}

export const oliveDarkP3 = {
  olive1: 'color(display-p3 0.067 0.07 0.063)',
  olive2: 'color(display-p3 0.095 0.098 0.091)',
  olive3: 'color(display-p3 0.131 0.135 0.126)',
  olive4: 'color(display-p3 0.158 0.163 0.153)',
  olive5: 'color(display-p3 0.186 0.192 0.18)',
  olive6: 'color(display-p3 0.221 0.229 0.215)',
  olive7: 'color(display-p3 0.273 0.284 0.266)',
  olive8: 'color(display-p3 0.365 0.382 0.359)',
  olive9: 'color(display-p3 0.414 0.438 0.404)',
  olive10: 'color(display-p3 0.467 0.49 0.458)',
  olive11: 'color(display-p3 0.69 0.709 0.682)',
  olive12: 'color(display-p3 0.927 0.933 0.926)',
}

export const oliveDarkP3A = {
  oliveA1: 'color(display-p3 0 0 0 / 0)',
  oliveA2: 'color(display-p3 0.984 0.988 0.976 / 0.03)',
  oliveA3: 'color(display-p3 0.992 0.996 0.988 / 0.068)',
  oliveA4: 'color(display-p3 0.953 0.996 0.949 / 0.102)',
  oliveA5: 'color(display-p3 0.969 1 0.965 / 0.131)',
  oliveA6: 'color(display-p3 0.973 1 0.969 / 0.169)',
  oliveA7: 'color(display-p3 0.98 1 0.961 / 0.228)',
  oliveA8: 'color(display-p3 0.961 1 0.957 / 0.334)',
  oliveA9: 'color(display-p3 0.949 1 0.922 / 0.397)',
  oliveA10: 'color(display-p3 0.953 1 0.941 / 0.452)',
  oliveA11: 'color(display-p3 0.976 1 0.965 / 0.688)',
  oliveA12: 'color(display-p3 0.992 1 0.992 / 0.929)',
}

export const sandDark = {
  sand1: '#111110',
  sand2: '#191918',
  sand3: '#222221',
  sand4: '#2a2a28',
  sand5: '#31312e',
  sand6: '#3b3a37',
  sand7: '#494844',
  sand8: '#62605b',
  sand9: '#6f6d66',
  sand10: '#7c7b74',
  sand11: '#b5b3ad',
  sand12: '#eeeeec',
}

export const sandDarkA = {
  sandA1: '#00000000',
  sandA2: '#f4f4f309',
  sandA3: '#f6f6f513',
  sandA4: '#fefef31b',
  sandA5: '#fbfbeb23',
  sandA6: '#fffaed2d',
  sandA7: '#fffbed3c',
  sandA8: '#fff9eb57',
  sandA9: '#fffae965',
  sandA10: '#fffdee73',
  sandA11: '#fffcf4b0',
  sandA12: '#fffffded',
}

export const sandDarkP3 = {
  sand1: 'color(display-p3 0.067 0.067 0.063)',
  sand2: 'color(display-p3 0.098 0.098 0.094)',
  sand3: 'color(display-p3 0.135 0.135 0.129)',
  sand4: 'color(display-p3 0.164 0.163 0.156)',
  sand5: 'color(display-p3 0.193 0.192 0.183)',
  sand6: 'color(display-p3 0.23 0.229 0.217)',
  sand7: 'color(display-p3 0.285 0.282 0.267)',
  sand8: 'color(display-p3 0.384 0.378 0.357)',
  sand9: 'color(display-p3 0.434 0.428 0.403)',
  sand10: 'color(display-p3 0.487 0.481 0.456)',
  sand11: 'color(display-p3 0.707 0.703 0.68)',
  sand12: 'color(display-p3 0.933 0.933 0.926)',
}

export const sandDarkP3A = {
  sandA1: 'color(display-p3 0 0 0 / 0)',
  sandA2: 'color(display-p3 0.992 0.992 0.988 / 0.034)',
  sandA3: 'color(display-p3 0.996 0.996 0.992 / 0.072)',
  sandA4: 'color(display-p3 0.992 0.992 0.953 / 0.106)',
  sandA5: 'color(display-p3 1 1 0.965 / 0.135)',
  sandA6: 'color(display-p3 1 0.976 0.929 / 0.177)',
  sandA7: 'color(display-p3 1 0.984 0.929 / 0.236)',
  sandA8: 'color(display-p3 1 0.976 0.925 / 0.341)',
  sandA9: 'color(display-p3 1 0.98 0.925 / 0.395)',
  sandA10: 'color(display-p3 1 0.992 0.933 / 0.45)',
  sandA11: 'color(display-p3 1 0.996 0.961 / 0.685)',
  sandA12: 'color(display-p3 1 1 0.992 / 0.929)',
}

export const tomatoDark = {
  tomato1: '#181111',
  tomato2: '#1f1513',
  tomato3: '#391714',
  tomato4: '#4e1511',
  tomato5: '#5e1c16',
  tomato6: '#6e2920',
  tomato7: '#853a2d',
  tomato8: '#ac4d39',
  tomato9: '#e54d2e',
  tomato10: '#ec6142',
  tomato11: '#ff977d',
  tomato12: '#fbd3cb',
}

export const tomatoDarkA = {
  tomatoA1: '#f1121208',
  tomatoA2: '#ff55330f',
  tomatoA3: '#ff35232b',
  tomatoA4: '#fd201142',
  tomatoA5: '#fe332153',
  tomatoA6: '#ff4f3864',
  tomatoA7: '#fd644a7d',
  tomatoA8: '#fe6d4ea7',
  tomatoA9: '#fe5431e4',
  tomatoA10: '#ff6847eb',
  tomatoA11: '#ff977d',
  tomatoA12: '#ffd6cefb',
}

export const tomatoDarkP3 = {
  tomato1: 'color(display-p3 0.09 0.068 0.067)',
  tomato2: 'color(display-p3 0.115 0.084 0.076)',
  tomato3: 'color(display-p3 0.205 0.097 0.083)',
  tomato4: 'color(display-p3 0.282 0.099 0.077)',
  tomato5: 'color(display-p3 0.339 0.129 0.101)',
  tomato6: 'color(display-p3 0.398 0.179 0.141)',
  tomato7: 'color(display-p3 0.487 0.245 0.194)',
  tomato8: 'color(display-p3 0.629 0.322 0.248)',
  tomato9: 'color(display-p3 0.831 0.345 0.231)',
  tomato10: 'color(display-p3 0.862 0.415 0.298)',
  tomato11: 'color(display-p3 1 0.585 0.455)',
  tomato12: 'color(display-p3 0.959 0.833 0.802)',
}

export const tomatoDarkP3A = {
  tomatoA1: 'color(display-p3 0.973 0.071 0.071 / 0.026)',
  tomatoA2: 'color(display-p3 0.992 0.376 0.224 / 0.051)',
  tomatoA3: 'color(display-p3 0.996 0.282 0.176 / 0.148)',
  tomatoA4: 'color(display-p3 1 0.204 0.118 / 0.232)',
  tomatoA5: 'color(display-p3 1 0.286 0.192 / 0.29)',
  tomatoA6: 'color(display-p3 1 0.392 0.278 / 0.353)',
  tomatoA7: 'color(display-p3 1 0.459 0.349 / 0.45)',
  tomatoA8: 'color(display-p3 1 0.49 0.369 / 0.601)',
  tomatoA9: 'color(display-p3 1 0.408 0.267 / 0.82)',
  tomatoA10: 'color(display-p3 1 0.478 0.341 / 0.853)',
  tomatoA11: 'color(display-p3 1 0.585 0.455)',
  tomatoA12: 'color(display-p3 0.959 0.833 0.802)',
}

export const redDark = {
  red1: '#191111',
  red2: '#201314',
  red3: '#3b1219',
  red4: '#500f1c',
  red5: '#611623',
  red6: '#72232d',
  red7: '#8c333a',
  red8: '#b54548',
  red9: '#e5484d',
  red10: '#ec5d5e',
  red11: '#ff9592',
  red12: '#ffd1d9',
}

export const redDarkA = {
  redA1: '#f4121209',
  redA2: '#f22f3e11',
  redA3: '#ff173f2d',
  redA4: '#fe0a3b44',
  redA5: '#ff204756',
  redA6: '#ff3e5668',
  redA7: '#ff536184',
  redA8: '#ff5d61b0',
  redA9: '#fe4e54e4',
  redA10: '#ff6465eb',
  redA11: '#ff9592',
  redA12: '#ffd1d9',
}

export const redDarkP3 = {
  red1: 'color(display-p3 0.093 0.068 0.067)',
  red2: 'color(display-p3 0.118 0.077 0.079)',
  red3: 'color(display-p3 0.211 0.081 0.099)',
  red4: 'color(display-p3 0.287 0.079 0.113)',
  red5: 'color(display-p3 0.348 0.11 0.142)',
  red6: 'color(display-p3 0.414 0.16 0.183)',
  red7: 'color(display-p3 0.508 0.224 0.236)',
  red8: 'color(display-p3 0.659 0.298 0.297)',
  red9: 'color(display-p3 0.83 0.329 0.324)',
  red10: 'color(display-p3 0.861 0.403 0.387)',
  red11: 'color(display-p3 1 0.57 0.55)',
  red12: 'color(display-p3 0.971 0.826 0.852)',
}

export const redDarkP3A = {
  redA1: 'color(display-p3 0.984 0.071 0.071 / 0.03)',
  redA2: 'color(display-p3 0.996 0.282 0.282 / 0.055)',
  redA3: 'color(display-p3 1 0.169 0.271 / 0.156)',
  redA4: 'color(display-p3 1 0.118 0.267 / 0.236)',
  redA5: 'color(display-p3 1 0.212 0.314 / 0.303)',
  redA6: 'color(display-p3 1 0.318 0.38 / 0.374)',
  redA7: 'color(display-p3 1 0.4 0.424 / 0.475)',
  redA8: 'color(display-p3 1 0.431 0.431 / 0.635)',
  redA9: 'color(display-p3 1 0.388 0.384 / 0.82)',
  redA10: 'color(display-p3 1 0.463 0.447 / 0.853)',
  redA11: 'color(display-p3 1 0.57 0.55)',
  redA12: 'color(display-p3 0.971 0.826 0.852)',
}

export const rubyDark = {
  ruby1: '#191113',
  ruby2: '#1e1517',
  ruby3: '#3a141e',
  ruby4: '#4e1325',
  ruby5: '#5e1a2e',
  ruby6: '#6f2539',
  ruby7: '#883447',
  ruby8: '#b3445a',
  ruby9: '#e54666',
  ruby10: '#ec5a72',
  ruby11: '#ff949d',
  ruby12: '#fed2e1',
}

export const rubyDarkA = {
  rubyA1: '#f4124a09',
  rubyA2: '#fe5a7f0e',
  rubyA3: '#ff235d2c',
  rubyA4: '#fd195e42',
  rubyA5: '#fe2d6b53',
  rubyA6: '#ff447665',
  rubyA7: '#ff577d80',
  rubyA8: '#ff5c7cae',
  rubyA9: '#fe4c70e4',
  rubyA10: '#ff617beb',
  rubyA11: '#ff949d',
  rubyA12: '#ffd3e2fe',
}

export const rubyDarkP3 = {
  ruby1: 'color(display-p3 0.093 0.068 0.074)',
  ruby2: 'color(display-p3 0.113 0.083 0.089)',
  ruby3: 'color(display-p3 0.208 0.088 0.117)',
  ruby4: 'color(display-p3 0.279 0.092 0.147)',
  ruby5: 'color(display-p3 0.337 0.12 0.18)',
  ruby6: 'color(display-p3 0.401 0.166 0.223)',
  ruby7: 'color(display-p3 0.495 0.224 0.281)',
  ruby8: 'color(display-p3 0.652 0.295 0.359)',
  ruby9: 'color(display-p3 0.83 0.323 0.408)',
  ruby10: 'color(display-p3 0.857 0.392 0.455)',
  ruby11: 'color(display-p3 1 0.57 0.59)',
  ruby12: 'color(display-p3 0.968 0.83 0.88)',
}

export const rubyDarkP3A = {
  rubyA1: 'color(display-p3 0.984 0.071 0.329 / 0.03)',
  rubyA2: 'color(display-p3 0.992 0.376 0.529 / 0.051)',
  rubyA3: 'color(display-p3 0.996 0.196 0.404 / 0.152)',
  rubyA4: 'color(display-p3 1 0.173 0.416 / 0.227)',
  rubyA5: 'color(display-p3 1 0.259 0.459 / 0.29)',
  rubyA6: 'color(display-p3 1 0.341 0.506 / 0.358)',
  rubyA7: 'color(display-p3 1 0.412 0.541 / 0.458)',
  rubyA8: 'color(display-p3 1 0.431 0.537 / 0.627)',
  rubyA9: 'color(display-p3 1 0.376 0.482 / 0.82)',
  rubyA10: 'color(display-p3 1 0.447 0.522 / 0.849)',
  rubyA11: 'color(display-p3 1 0.57 0.59)',
  rubyA12: 'color(display-p3 0.968 0.83 0.88)',
}

export const crimsonDark = {
  crimson1: '#191114',
  crimson2: '#201318',
  crimson3: '#381525',
  crimson4: '#4d122f',
  crimson5: '#5c1839',
  crimson6: '#6d2545',
  crimson7: '#873356',
  crimson8: '#b0436e',
  crimson9: '#e93d82',
  crimson10: '#ee518a',
  crimson11: '#ff92ad',
  crimson12: '#fdd3e8',
}

export const crimsonDarkA = {
  crimsonA1: '#f4126709',
  crimsonA2: '#f22f7a11',
  crimsonA3: '#fe2a8b2a',
  crimsonA4: '#fd158741',
  crimsonA5: '#fd278f51',
  crimsonA6: '#fe459763',
  crimsonA7: '#fd559b7f',
  crimsonA8: '#fe5b9bab',
  crimsonA9: '#fe418de8',
  crimsonA10: '#ff5693ed',
  crimsonA11: '#ff92ad',
  crimsonA12: '#ffd5eafd',
}

export const crimsonDarkP3 = {
  crimson1: 'color(display-p3 0.093 0.068 0.078)',
  crimson2: 'color(display-p3 0.117 0.078 0.095)',
  crimson3: 'color(display-p3 0.203 0.091 0.143)',
  crimson4: 'color(display-p3 0.277 0.087 0.182)',
  crimson5: 'color(display-p3 0.332 0.115 0.22)',
  crimson6: 'color(display-p3 0.394 0.162 0.268)',
  crimson7: 'color(display-p3 0.489 0.222 0.336)',
  crimson8: 'color(display-p3 0.638 0.289 0.429)',
  crimson9: 'color(display-p3 0.843 0.298 0.507)',
  crimson10: 'color(display-p3 0.864 0.364 0.539)',
  crimson11: 'color(display-p3 1 0.56 0.66)',
  crimson12: 'color(display-p3 0.966 0.834 0.906)',
}

export const crimsonDarkP3A = {
  crimsonA1: 'color(display-p3 0.984 0.071 0.463 / 0.03)',
  crimsonA2: 'color(display-p3 0.996 0.282 0.569 / 0.055)',
  crimsonA3: 'color(display-p3 0.996 0.227 0.573 / 0.148)',
  crimsonA4: 'color(display-p3 1 0.157 0.569 / 0.227)',
  crimsonA5: 'color(display-p3 1 0.231 0.604 / 0.286)',
  crimsonA6: 'color(display-p3 1 0.337 0.643 / 0.349)',
  crimsonA7: 'color(display-p3 1 0.416 0.663 / 0.454)',
  crimsonA8: 'color(display-p3 0.996 0.427 0.651 / 0.614)',
  crimsonA9: 'color(display-p3 1 0.345 0.596 / 0.832)',
  crimsonA10: 'color(display-p3 1 0.42 0.62 / 0.853)',
  crimsonA11: 'color(display-p3 1 0.56 0.66)',
  crimsonA12: 'color(display-p3 0.966 0.834 0.906)',
}

export const pinkDark = {
  pink1: '#191117',
  pink2: '#21121d',
  pink3: '#37172f',
  pink4: '#4b143d',
  pink5: '#591c47',
  pink6: '#692955',
  pink7: '#833869',
  pink8: '#a84885',
  pink9: '#d6409f',
  pink10: '#de51a8',
  pink11: '#ff8dcc',
  pink12: '#fdd1ea',
}

export const pinkDarkA = {
  pinkA1: '#f412bc09',
  pinkA2: '#f420bb12',
  pinkA3: '#fe37cc29',
  pinkA4: '#fc1ec43f',
  pinkA5: '#fd35c24e',
  pinkA6: '#fd51c75f',
  pinkA7: '#fd62c87b',
  pinkA8: '#ff68c8a2',
  pinkA9: '#fe49bcd4',
  pinkA10: '#ff5cc0dc',
  pinkA11: '#ff8dcc',
  pinkA12: '#ffd3ecfd',
}

export const pinkDarkP3 = {
  pink1: 'color(display-p3 0.093 0.068 0.089)',
  pink2: 'color(display-p3 0.121 0.073 0.11)',
  pink3: 'color(display-p3 0.198 0.098 0.179)',
  pink4: 'color(display-p3 0.271 0.095 0.231)',
  pink5: 'color(display-p3 0.32 0.127 0.273)',
  pink6: 'color(display-p3 0.382 0.177 0.326)',
  pink7: 'color(display-p3 0.477 0.238 0.405)',
  pink8: 'color(display-p3 0.612 0.304 0.51)',
  pink9: 'color(display-p3 0.775 0.297 0.61)',
  pink10: 'color(display-p3 0.808 0.356 0.645)',
  pink11: 'color(display-p3 1 0.535 0.78)',
  pink12: 'color(display-p3 0.964 0.826 0.912)',
}

export const pinkDarkP3A = {
  pinkA1: 'color(display-p3 0.984 0.071 0.855 / 0.03)',
  pinkA2: 'color(display-p3 1 0.2 0.8 / 0.059)',
  pinkA3: 'color(display-p3 1 0.294 0.886 / 0.139)',
  pinkA4: 'color(display-p3 1 0.192 0.82 / 0.219)',
  pinkA5: 'color(display-p3 1 0.282 0.827 / 0.274)',
  pinkA6: 'color(display-p3 1 0.396 0.835 / 0.337)',
  pinkA7: 'color(display-p3 1 0.459 0.831 / 0.442)',
  pinkA8: 'color(display-p3 1 0.478 0.827 / 0.585)',
  pinkA9: 'color(display-p3 1 0.373 0.784 / 0.761)',
  pinkA10: 'color(display-p3 1 0.435 0.792 / 0.795)',
  pinkA11: 'color(display-p3 1 0.535 0.78)',
  pinkA12: 'color(display-p3 0.964 0.826 0.912)',
}

export const plumDark = {
  plum1: '#181118',
  plum2: '#201320',
  plum3: '#351a35',
  plum4: '#451d47',
  plum5: '#512454',
  plum6: '#5e3061',
  plum7: '#734079',
  plum8: '#92549c',
  plum9: '#ab4aba',
  plum10: '#b658c4',
  plum11: '#e796f3',
  plum12: '#f4d4f4',
}

export const plumDarkA = {
  plumA1: '#f112f108',
  plumA2: '#f22ff211',
  plumA3: '#fd4cfd27',
  plumA4: '#f646ff3a',
  plumA5: '#f455ff48',
  plumA6: '#f66dff56',
  plumA7: '#f07cfd70',
  plumA8: '#ee84ff95',
  plumA9: '#e961feb6',
  plumA10: '#ed70ffc0',
  plumA11: '#f19cfef3',
  plumA12: '#feddfef4',
}

export const plumDarkP3 = {
  plum1: 'color(display-p3 0.09 0.068 0.092)',
  plum2: 'color(display-p3 0.118 0.077 0.121)',
  plum3: 'color(display-p3 0.192 0.105 0.202)',
  plum4: 'color(display-p3 0.25 0.121 0.271)',
  plum5: 'color(display-p3 0.293 0.152 0.319)',
  plum6: 'color(display-p3 0.343 0.198 0.372)',
  plum7: 'color(display-p3 0.424 0.262 0.461)',
  plum8: 'color(display-p3 0.54 0.341 0.595)',
  plum9: 'color(display-p3 0.624 0.313 0.708)',
  plum10: 'color(display-p3 0.666 0.365 0.748)',
  plum11: 'color(display-p3 0.86 0.602 0.933)',
  plum12: 'color(display-p3 0.936 0.836 0.949)',
}

export const plumDarkP3A = {
  plumA1: 'color(display-p3 0.973 0.071 0.973 / 0.026)',
  plumA2: 'color(display-p3 0.933 0.267 1 / 0.059)',
  plumA3: 'color(display-p3 0.918 0.333 0.996 / 0.148)',
  plumA4: 'color(display-p3 0.91 0.318 1 / 0.219)',
  plumA5: 'color(display-p3 0.914 0.388 1 / 0.269)',
  plumA6: 'color(display-p3 0.906 0.463 1 / 0.328)',
  plumA7: 'color(display-p3 0.906 0.529 1 / 0.425)',
  plumA8: 'color(display-p3 0.906 0.553 1 / 0.568)',
  plumA9: 'color(display-p3 0.875 0.427 1 / 0.69)',
  plumA10: 'color(display-p3 0.886 0.471 0.996 / 0.732)',
  plumA11: 'color(display-p3 0.86 0.602 0.933)',
  plumA12: 'color(display-p3 0.936 0.836 0.949)',
}

export const purpleDark = {
  purple1: '#18111b',
  purple2: '#1e1523',
  purple3: '#301c3b',
  purple4: '#3d224e',
  purple5: '#48295c',
  purple6: '#54346b',
  purple7: '#664282',
  purple8: '#8457aa',
  purple9: '#8e4ec6',
  purple10: '#9a5cd0',
  purple11: '#d19dff',
  purple12: '#ecd9fa',
}

export const purpleDarkA = {
  purpleA1: '#b412f90b',
  purpleA2: '#b744f714',
  purpleA3: '#c150ff2d',
  purpleA4: '#bb53fd42',
  purpleA5: '#be5cfd51',
  purpleA6: '#c16dfd61',
  purpleA7: '#c378fd7a',
  purpleA8: '#c47effa4',
  purpleA9: '#b661ffc2',
  purpleA10: '#bc6fffcd',
  purpleA11: '#d19dff',
  purpleA12: '#f1ddfffa',
}

export const purpleDarkP3 = {
  purple1: 'color(display-p3 0.09 0.068 0.103)',
  purple2: 'color(display-p3 0.113 0.082 0.134)',
  purple3: 'color(display-p3 0.175 0.112 0.224)',
  purple4: 'color(display-p3 0.224 0.137 0.297)',
  purple5: 'color(display-p3 0.264 0.167 0.349)',
  purple6: 'color(display-p3 0.311 0.208 0.406)',
  purple7: 'color(display-p3 0.381 0.266 0.496)',
  purple8: 'color(display-p3 0.49 0.349 0.649)',
  purple9: 'color(display-p3 0.523 0.318 0.751)',
  purple10: 'color(display-p3 0.57 0.373 0.791)',
  purple11: 'color(display-p3 0.8 0.62 1)',
  purple12: 'color(display-p3 0.913 0.854 0.971)',
}

export const purpleDarkP3A = {
  purpleA1: 'color(display-p3 0.686 0.071 0.996 / 0.038)',
  purpleA2: 'color(display-p3 0.722 0.286 0.996 / 0.072)',
  purpleA3: 'color(display-p3 0.718 0.349 0.996 / 0.169)',
  purpleA4: 'color(display-p3 0.702 0.353 1 / 0.248)',
  purpleA5: 'color(display-p3 0.718 0.404 1 / 0.303)',
  purpleA6: 'color(display-p3 0.733 0.455 1 / 0.366)',
  purpleA7: 'color(display-p3 0.753 0.506 1 / 0.458)',
  purpleA8: 'color(display-p3 0.749 0.522 1 / 0.622)',
  purpleA9: 'color(display-p3 0.686 0.408 1 / 0.736)',
  purpleA10: 'color(display-p3 0.71 0.459 1 / 0.778)',
  purpleA11: 'color(display-p3 0.8 0.62 1)',
  purpleA12: 'color(display-p3 0.913 0.854 0.971)',
}

export const violetDark = {
  violet1: '#14121f',
  violet2: '#1b1525',
  violet3: '#291f43',
  violet4: '#33255b',
  violet5: '#3c2e69',
  violet6: '#473876',
  violet7: '#56468b',
  violet8: '#6958ad',
  violet9: '#6e56cf',
  violet10: '#7d66d9',
  violet11: '#baa7ff',
  violet12: '#e2ddfe',
}

export const violetDarkA = {
  violetA1: '#4422ff0f',
  violetA2: '#853ff916',
  violetA3: '#8354fe36',
  violetA4: '#7d51fd50',
  violetA5: '#845ffd5f',
  violetA6: '#8f6cfd6d',
  violetA7: '#9879ff83',
  violetA8: '#977dfea8',
  violetA9: '#8668ffcc',
  violetA10: '#9176fed7',
  violetA11: '#baa7ff',
  violetA12: '#e3defffe',
}

export const violetDarkP3 = {
  violet1: 'color(display-p3 0.077 0.071 0.118)',
  violet2: 'color(display-p3 0.101 0.084 0.141)',
  violet3: 'color(display-p3 0.154 0.123 0.256)',
  violet4: 'color(display-p3 0.191 0.148 0.345)',
  violet5: 'color(display-p3 0.226 0.182 0.396)',
  violet6: 'color(display-p3 0.269 0.223 0.449)',
  violet7: 'color(display-p3 0.326 0.277 0.53)',
  violet8: 'color(display-p3 0.399 0.346 0.656)',
  violet9: 'color(display-p3 0.417 0.341 0.784)',
  violet10: 'color(display-p3 0.477 0.402 0.823)',
  violet11: 'color(display-p3 0.72 0.65 1)',
  violet12: 'color(display-p3 0.883 0.867 0.986)',
}

export const violetDarkP3A = {
  violetA1: 'color(display-p3 0.282 0.141 0.996 / 0.055)',
  violetA2: 'color(display-p3 0.51 0.263 1 / 0.08)',
  violetA3: 'color(display-p3 0.494 0.337 0.996 / 0.202)',
  violetA4: 'color(display-p3 0.49 0.345 1 / 0.299)',
  violetA5: 'color(display-p3 0.525 0.392 1 / 0.353)',
  violetA6: 'color(display-p3 0.569 0.455 1 / 0.408)',
  violetA7: 'color(display-p3 0.588 0.494 1 / 0.496)',
  violetA8: 'color(display-p3 0.596 0.51 1 / 0.631)',
  violetA9: 'color(display-p3 0.522 0.424 1 / 0.769)',
  violetA10: 'color(display-p3 0.576 0.482 1 / 0.811)',
  violetA11: 'color(display-p3 0.72 0.65 1)',
  violetA12: 'color(display-p3 0.883 0.867 0.986)',
}

export const irisDark = {
  iris1: '#13131e',
  iris2: '#171625',
  iris3: '#202248',
  iris4: '#262a65',
  iris5: '#303374',
  iris6: '#3d3e82',
  iris7: '#4a4a95',
  iris8: '#5958b1',
  iris9: '#5b5bd6',
  iris10: '#6e6ade',
  iris11: '#b1a9ff',
  iris12: '#e0dffe',
}

export const irisDarkA = {
  irisA1: '#3636fe0e',
  irisA2: '#564bf916',
  irisA3: '#525bff3b',
  irisA4: '#4d58ff5a',
  irisA5: '#5b62fd6b',
  irisA6: '#6d6ffd7a',
  irisA7: '#7777fe8e',
  irisA8: '#7b7afeac',
  irisA9: '#6a6afed4',
  irisA10: '#7d79ffdc',
  irisA11: '#b1a9ff',
  irisA12: '#e1e0fffe',
}

export const irisDarkP3 = {
  iris1: 'color(display-p3 0.075 0.075 0.114)',
  iris2: 'color(display-p3 0.089 0.086 0.14)',
  iris3: 'color(display-p3 0.128 0.134 0.272)',
  iris4: 'color(display-p3 0.153 0.165 0.382)',
  iris5: 'color(display-p3 0.192 0.201 0.44)',
  iris6: 'color(display-p3 0.239 0.241 0.491)',
  iris7: 'color(display-p3 0.291 0.289 0.565)',
  iris8: 'color(display-p3 0.35 0.345 0.673)',
  iris9: 'color(display-p3 0.357 0.357 0.81)',
  iris10: 'color(display-p3 0.428 0.416 0.843)',
  iris11: 'color(display-p3 0.685 0.662 1)',
  iris12: 'color(display-p3 0.878 0.875 0.986)',
}

export const irisDarkP3A = {
  irisA1: 'color(display-p3 0.224 0.224 0.992 / 0.051)',
  irisA2: 'color(display-p3 0.361 0.314 1 / 0.08)',
  irisA3: 'color(display-p3 0.357 0.373 1 / 0.219)',
  irisA4: 'color(display-p3 0.325 0.361 1 / 0.337)',
  irisA5: 'color(display-p3 0.38 0.4 1 / 0.4)',
  irisA6: 'color(display-p3 0.447 0.447 1 / 0.454)',
  irisA7: 'color(display-p3 0.486 0.486 1 / 0.534)',
  irisA8: 'color(display-p3 0.502 0.494 1 / 0.652)',
  irisA9: 'color(display-p3 0.431 0.431 1 / 0.799)',
  irisA10: 'color(display-p3 0.502 0.486 1 / 0.832)',
  irisA11: 'color(display-p3 0.685 0.662 1)',
  irisA12: 'color(display-p3 0.878 0.875 0.986)',
}

export const indigoDark = {
  indigo1: '#11131f',
  indigo2: '#141726',
  indigo3: '#182449',
  indigo4: '#1d2e62',
  indigo5: '#253974',
  indigo6: '#304384',
  indigo7: '#3a4f97',
  indigo8: '#435db1',
  indigo9: '#3e63dd',
  indigo10: '#5472e4',
  indigo11: '#9eb1ff',
  indigo12: '#d6e1ff',
}

export const indigoDarkA = {
  indigoA1: '#1133ff0f',
  indigoA2: '#3354fa17',
  indigoA3: '#2f62ff3c',
  indigoA4: '#3566ff57',
  indigoA5: '#4171fd6b',
  indigoA6: '#5178fd7c',
  indigoA7: '#5a7fff90',
  indigoA8: '#5b81feac',
  indigoA9: '#4671ffdb',
  indigoA10: '#5c7efee3',
  indigoA11: '#9eb1ff',
  indigoA12: '#d6e1ff',
}

export const indigoDarkP3 = {
  indigo1: 'color(display-p3 0.068 0.074 0.118)',
  indigo2: 'color(display-p3 0.081 0.089 0.144)',
  indigo3: 'color(display-p3 0.105 0.141 0.275)',
  indigo4: 'color(display-p3 0.129 0.18 0.369)',
  indigo5: 'color(display-p3 0.163 0.22 0.439)',
  indigo6: 'color(display-p3 0.203 0.262 0.5)',
  indigo7: 'color(display-p3 0.245 0.309 0.575)',
  indigo8: 'color(display-p3 0.285 0.362 0.674)',
  indigo9: 'color(display-p3 0.276 0.384 0.837)',
  indigo10: 'color(display-p3 0.354 0.445 0.866)',
  indigo11: 'color(display-p3 0.63 0.69 1)',
  indigo12: 'color(display-p3 0.848 0.881 0.99)',
}

export const indigoDarkP3A = {
  indigoA1: 'color(display-p3 0.071 0.212 0.996 / 0.055)',
  indigoA2: 'color(display-p3 0.251 0.345 0.988 / 0.085)',
  indigoA3: 'color(display-p3 0.243 0.404 1 / 0.223)',
  indigoA4: 'color(display-p3 0.263 0.42 1 / 0.324)',
  indigoA5: 'color(display-p3 0.314 0.451 1 / 0.4)',
  indigoA6: 'color(display-p3 0.361 0.49 1 / 0.467)',
  indigoA7: 'color(display-p3 0.388 0.51 1 / 0.547)',
  indigoA8: 'color(display-p3 0.404 0.518 1 / 0.652)',
  indigoA9: 'color(display-p3 0.318 0.451 1 / 0.824)',
  indigoA10: 'color(display-p3 0.404 0.506 1 / 0.858)',
  indigoA11: 'color(display-p3 0.63 0.69 1)',
  indigoA12: 'color(display-p3 0.848 0.881 0.99)',
}

export const blueDark = {
  blue1: '#0d1520',
  blue2: '#111927',
  blue3: '#0d2847',
  blue4: '#003362',
  blue5: '#004074',
  blue6: '#104d87',
  blue7: '#205d9e',
  blue8: '#2870bd',
  blue9: '#0090ff',
  blue10: '#3b9eff',
  blue11: '#70b8ff',
  blue12: '#c2e6ff',
}

export const blueDarkA = {
  blueA1: '#004df211',
  blueA2: '#1166fb18',
  blueA3: '#0077ff3a',
  blueA4: '#0075ff57',
  blueA5: '#0081fd6b',
  blueA6: '#0f89fd7f',
  blueA7: '#2a91fe98',
  blueA8: '#3094feb9',
  blueA9: '#0090ff',
  blueA10: '#3b9eff',
  blueA11: '#70b8ff',
  blueA12: '#c2e6ff',
}

export const blueDarkP3 = {
  blue1: 'color(display-p3 0.057 0.081 0.122)',
  blue2: 'color(display-p3 0.072 0.098 0.147)',
  blue3: 'color(display-p3 0.078 0.154 0.27)',
  blue4: 'color(display-p3 0.033 0.197 0.37)',
  blue5: 'color(display-p3 0.08 0.245 0.441)',
  blue6: 'color(display-p3 0.14 0.298 0.511)',
  blue7: 'color(display-p3 0.195 0.361 0.6)',
  blue8: 'color(display-p3 0.239 0.434 0.72)',
  blue9: 'color(display-p3 0.247 0.556 0.969)',
  blue10: 'color(display-p3 0.344 0.612 0.973)',
  blue11: 'color(display-p3 0.49 0.72 1)',
  blue12: 'color(display-p3 0.788 0.898 0.99)',
}

export const blueDarkP3A = {
  blueA1: 'color(display-p3 0 0.333 1 / 0.059)',
  blueA2: 'color(display-p3 0.114 0.435 0.988 / 0.085)',
  blueA3: 'color(display-p3 0.122 0.463 1 / 0.219)',
  blueA4: 'color(display-p3 0 0.467 1 / 0.324)',
  blueA5: 'color(display-p3 0.098 0.51 1 / 0.4)',
  blueA6: 'color(display-p3 0.224 0.557 1 / 0.475)',
  blueA7: 'color(display-p3 0.294 0.584 1 / 0.572)',
  blueA8: 'color(display-p3 0.314 0.592 1 / 0.702)',
  blueA9: 'color(display-p3 0.251 0.573 0.996 / 0.967)',
  blueA10: 'color(display-p3 0.357 0.631 1 / 0.971)',
  blueA11: 'color(display-p3 0.49 0.72 1)',
  blueA12: 'color(display-p3 0.788 0.898 0.99)',
}

export const cyanDark = {
  cyan1: '#0b161a',
  cyan2: '#101b20',
  cyan3: '#082c36',
  cyan4: '#003848',
  cyan5: '#004558',
  cyan6: '#045468',
  cyan7: '#12677e',
  cyan8: '#11809c',
  cyan9: '#00a2c7',
  cyan10: '#23afd0',
  cyan11: '#4ccce6',
  cyan12: '#b6ecf7',
}

export const cyanDarkA = {
  cyanA1: '#0091f70a',
  cyanA2: '#02a7f211',
  cyanA3: '#00befd28',
  cyanA4: '#00baff3b',
  cyanA5: '#00befd4d',
  cyanA6: '#00c7fd5e',
  cyanA7: '#14cdff75',
  cyanA8: '#11cfff95',
  cyanA9: '#00cfffc3',
  cyanA10: '#28d6ffcd',
  cyanA11: '#52e1fee5',
  cyanA12: '#bbf3fef7',
}

export const cyanDarkP3 = {
  cyan1: 'color(display-p3 0.053 0.085 0.098)',
  cyan2: 'color(display-p3 0.072 0.105 0.122)',
  cyan3: 'color(display-p3 0.073 0.168 0.209)',
  cyan4: 'color(display-p3 0.063 0.216 0.277)',
  cyan5: 'color(display-p3 0.091 0.267 0.336)',
  cyan6: 'color(display-p3 0.137 0.324 0.4)',
  cyan7: 'color(display-p3 0.186 0.398 0.484)',
  cyan8: 'color(display-p3 0.23 0.496 0.6)',
  cyan9: 'color(display-p3 0.282 0.627 0.765)',
  cyan10: 'color(display-p3 0.331 0.675 0.801)',
  cyan11: 'color(display-p3 0.446 0.79 0.887)',
  cyan12: 'color(display-p3 0.757 0.919 0.962)',
}

export const cyanDarkP3A = {
  cyanA1: 'color(display-p3 0 0.647 0.992 / 0.034)',
  cyanA2: 'color(display-p3 0.133 0.733 1 / 0.059)',
  cyanA3: 'color(display-p3 0.122 0.741 0.996 / 0.152)',
  cyanA4: 'color(display-p3 0.051 0.725 1 / 0.227)',
  cyanA5: 'color(display-p3 0.149 0.757 1 / 0.29)',
  cyanA6: 'color(display-p3 0.267 0.792 1 / 0.358)',
  cyanA7: 'color(display-p3 0.333 0.808 1 / 0.446)',
  cyanA8: 'color(display-p3 0.357 0.816 1 / 0.572)',
  cyanA9: 'color(display-p3 0.357 0.82 1 / 0.748)',
  cyanA10: 'color(display-p3 0.4 0.839 1 / 0.786)',
  cyanA11: 'color(display-p3 0.446 0.79 0.887)',
  cyanA12: 'color(display-p3 0.757 0.919 0.962)',
}

export const tealDark = {
  teal1: '#0d1514',
  teal2: '#111c1b',
  teal3: '#0d2d2a',
  teal4: '#023b37',
  teal5: '#084843',
  teal6: '#145750',
  teal7: '#1c6961',
  teal8: '#207e73',
  teal9: '#12a594',
  teal10: '#0eb39e',
  teal11: '#0bd8b6',
  teal12: '#adf0dd',
}

export const tealDarkA = {
  tealA1: '#00deab05',
  tealA2: '#12fbe60c',
  tealA3: '#00ffe61e',
  tealA4: '#00ffe92d',
  tealA5: '#00ffea3b',
  tealA6: '#1cffe84b',
  tealA7: '#2efde85f',
  tealA8: '#32ffe775',
  tealA9: '#13ffe49f',
  tealA10: '#0dffe0ae',
  tealA11: '#0afed5d6',
  tealA12: '#b8ffebef',
}

export const tealDarkP3 = {
  teal1: 'color(display-p3 0.059 0.083 0.079)',
  teal2: 'color(display-p3 0.075 0.11 0.107)',
  teal3: 'color(display-p3 0.087 0.175 0.165)',
  teal4: 'color(display-p3 0.087 0.227 0.214)',
  teal5: 'color(display-p3 0.12 0.277 0.261)',
  teal6: 'color(display-p3 0.162 0.335 0.314)',
  teal7: 'color(display-p3 0.205 0.406 0.379)',
  teal8: 'color(display-p3 0.245 0.489 0.453)',
  teal9: 'color(display-p3 0.297 0.637 0.581)',
  teal10: 'color(display-p3 0.319 0.69 0.62)',
  teal11: 'color(display-p3 0.388 0.835 0.719)',
  teal12: 'color(display-p3 0.734 0.934 0.87)',
}

export const tealDarkP3A = {
  tealA1: 'color(display-p3 0 0.992 0.761 / 0.017)',
  tealA2: 'color(display-p3 0.235 0.988 0.902 / 0.047)',
  tealA3: 'color(display-p3 0.235 1 0.898 / 0.118)',
  tealA4: 'color(display-p3 0.18 0.996 0.929 / 0.173)',
  tealA5: 'color(display-p3 0.31 1 0.933 / 0.227)',
  tealA6: 'color(display-p3 0.396 1 0.933 / 0.286)',
  tealA7: 'color(display-p3 0.443 1 0.925 / 0.366)',
  tealA8: 'color(display-p3 0.459 1 0.925 / 0.454)',
  tealA9: 'color(display-p3 0.443 0.996 0.906 / 0.61)',
  tealA10: 'color(display-p3 0.439 0.996 0.89 / 0.669)',
  tealA11: 'color(display-p3 0.388 0.835 0.719)',
  tealA12: 'color(display-p3 0.734 0.934 0.87)',
}

export const jadeDark = {
  jade1: '#0d1512',
  jade2: '#121c18',
  jade3: '#0f2e22',
  jade4: '#0b3b2c',
  jade5: '#114837',
  jade6: '#1b5745',
  jade7: '#246854',
  jade8: '#2a7e68',
  jade9: '#29a383',
  jade10: '#27b08b',
  jade11: '#1fd8a4',
  jade12: '#adf0d4',
}

export const jadeDarkA = {
  jadeA1: '#00de4505',
  jadeA2: '#27fba60c',
  jadeA3: '#02f99920',
  jadeA4: '#00ffaa2d',
  jadeA5: '#11ffb63b',
  jadeA6: '#34ffc24b',
  jadeA7: '#45fdc75e',
  jadeA8: '#48ffcf75',
  jadeA9: '#38feca9d',
  jadeA10: '#31fec7ab',
  jadeA11: '#21fec0d6',
  jadeA12: '#b8ffe1ef',
}

export const jadeDarkP3 = {
  jade1: 'color(display-p3 0.059 0.083 0.071)',
  jade2: 'color(display-p3 0.078 0.11 0.094)',
  jade3: 'color(display-p3 0.091 0.176 0.138)',
  jade4: 'color(display-p3 0.102 0.228 0.177)',
  jade5: 'color(display-p3 0.133 0.279 0.221)',
  jade6: 'color(display-p3 0.174 0.334 0.273)',
  jade7: 'color(display-p3 0.219 0.402 0.335)',
  jade8: 'color(display-p3 0.263 0.488 0.411)',
  jade9: 'color(display-p3 0.319 0.63 0.521)',
  jade10: 'color(display-p3 0.338 0.68 0.555)',
  jade11: 'color(display-p3 0.4 0.835 0.656)',
  jade12: 'color(display-p3 0.734 0.934 0.838)',
}

export const jadeDarkP3A = {
  jadeA1: 'color(display-p3 0 0.992 0.298 / 0.017)',
  jadeA2: 'color(display-p3 0.318 0.988 0.651 / 0.047)',
  jadeA3: 'color(display-p3 0.267 1 0.667 / 0.118)',
  jadeA4: 'color(display-p3 0.275 0.996 0.702 / 0.173)',
  jadeA5: 'color(display-p3 0.361 1 0.741 / 0.227)',
  jadeA6: 'color(display-p3 0.439 1 0.796 / 0.286)',
  jadeA7: 'color(display-p3 0.49 1 0.804 / 0.362)',
  jadeA8: 'color(display-p3 0.506 1 0.835 / 0.45)',
  jadeA9: 'color(display-p3 0.478 0.996 0.816 / 0.606)',
  jadeA10: 'color(display-p3 0.478 1 0.816 / 0.656)',
  jadeA11: 'color(display-p3 0.4 0.835 0.656)',
  jadeA12: 'color(display-p3 0.734 0.934 0.838)',
}

export const greenDark = {
  green1: '#0e1512',
  green2: '#121b17',
  green3: '#132d21',
  green4: '#113b29',
  green5: '#174933',
  green6: '#20573e',
  green7: '#28684a',
  green8: '#2f7c57',
  green9: '#30a46c',
  green10: '#33b074',
  green11: '#3dd68c',
  green12: '#b1f1cb',
}

export const greenDarkA = {
  greenA1: '#00de4505',
  greenA2: '#29f99d0b',
  greenA3: '#22ff991e',
  greenA4: '#11ff992d',
  greenA5: '#2bffa23c',
  greenA6: '#44ffaa4b',
  greenA7: '#50fdac5e',
  greenA8: '#54ffad73',
  greenA9: '#44ffa49e',
  greenA10: '#43fea4ab',
  greenA11: '#46fea5d4',
  greenA12: '#bbffd7f0',
}

export const greenDarkP3 = {
  green1: 'color(display-p3 0.062 0.083 0.071)',
  green2: 'color(display-p3 0.079 0.106 0.09)',
  green3: 'color(display-p3 0.1 0.173 0.133)',
  green4: 'color(display-p3 0.115 0.229 0.166)',
  green5: 'color(display-p3 0.147 0.282 0.206)',
  green6: 'color(display-p3 0.185 0.338 0.25)',
  green7: 'color(display-p3 0.227 0.403 0.298)',
  green8: 'color(display-p3 0.27 0.479 0.351)',
  green9: 'color(display-p3 0.332 0.634 0.442)',
  green10: 'color(display-p3 0.357 0.682 0.474)',
  green11: 'color(display-p3 0.434 0.828 0.573)',
  green12: 'color(display-p3 0.747 0.938 0.807)',
}

export const greenDarkP3A = {
  greenA1: 'color(display-p3 0 0.992 0.298 / 0.017)',
  greenA2: 'color(display-p3 0.341 0.98 0.616 / 0.043)',
  greenA3: 'color(display-p3 0.376 0.996 0.655 / 0.114)',
  greenA4: 'color(display-p3 0.341 0.996 0.635 / 0.173)',
  greenA5: 'color(display-p3 0.408 1 0.678 / 0.232)',
  greenA6: 'color(display-p3 0.475 1 0.706 / 0.29)',
  greenA7: 'color(display-p3 0.514 1 0.706 / 0.362)',
  greenA8: 'color(display-p3 0.529 1 0.718 / 0.442)',
  greenA9: 'color(display-p3 0.502 0.996 0.682 / 0.61)',
  greenA10: 'color(display-p3 0.506 1 0.682 / 0.66)',
  greenA11: 'color(display-p3 0.434 0.828 0.573)',
  greenA12: 'color(display-p3 0.747 0.938 0.807)',
}

export const grassDark = {
  grass1: '#0e1511',
  grass2: '#141a15',
  grass3: '#1b2a1e',
  grass4: '#1d3a24',
  grass5: '#25482d',
  grass6: '#2d5736',
  grass7: '#366740',
  grass8: '#3e7949',
  grass9: '#46a758',
  grass10: '#53b365',
  grass11: '#71d083',
  grass12: '#c2f0c2',
}

export const grassDarkA = {
  grassA1: '#00de1205',
  grassA2: '#5ef7780a',
  grassA3: '#70fe8c1b',
  grassA4: '#57ff802c',
  grassA5: '#68ff8b3b',
  grassA6: '#71ff8f4b',
  grassA7: '#77fd925d',
  grassA8: '#77fd9070',
  grassA9: '#65ff82a1',
  grassA10: '#72ff8dae',
  grassA11: '#89ff9fcd',
  grassA12: '#ceffceef',
}

export const grassDarkP3 = {
  grass1: 'color(display-p3 0.062 0.083 0.067)',
  grass2: 'color(display-p3 0.083 0.103 0.085)',
  grass3: 'color(display-p3 0.118 0.163 0.122)',
  grass4: 'color(display-p3 0.142 0.225 0.15)',
  grass5: 'color(display-p3 0.178 0.279 0.186)',
  grass6: 'color(display-p3 0.217 0.337 0.224)',
  grass7: 'color(display-p3 0.258 0.4 0.264)',
  grass8: 'color(display-p3 0.302 0.47 0.305)',
  grass9: 'color(display-p3 0.38 0.647 0.378)',
  grass10: 'color(display-p3 0.426 0.694 0.426)',
  grass11: 'color(display-p3 0.535 0.807 0.542)',
  grass12: 'color(display-p3 0.797 0.936 0.776)',
}

export const grassDarkP3A = {
  grassA1: 'color(display-p3 0 0.992 0.071 / 0.017)',
  grassA2: 'color(display-p3 0.482 0.996 0.584 / 0.038)',
  grassA3: 'color(display-p3 0.549 0.992 0.588 / 0.106)',
  grassA4: 'color(display-p3 0.51 0.996 0.557 / 0.169)',
  grassA5: 'color(display-p3 0.553 1 0.588 / 0.227)',
  grassA6: 'color(display-p3 0.584 1 0.608 / 0.29)',
  grassA7: 'color(display-p3 0.604 1 0.616 / 0.358)',
  grassA8: 'color(display-p3 0.608 1 0.62 / 0.433)',
  grassA9: 'color(display-p3 0.573 1 0.569 / 0.622)',
  grassA10: 'color(display-p3 0.6 0.996 0.6 / 0.673)',
  grassA11: 'color(display-p3 0.535 0.807 0.542)',
  grassA12: 'color(display-p3 0.797 0.936 0.776)',
}

export const brownDark = {
  brown1: '#12110f',
  brown2: '#1c1816',
  brown3: '#28211d',
  brown4: '#322922',
  brown5: '#3e3128',
  brown6: '#4d3c2f',
  brown7: '#614a39',
  brown8: '#7c5f46',
  brown9: '#ad7f58',
  brown10: '#b88c67',
  brown11: '#dbb594',
  brown12: '#f2e1ca',
}

export const brownDarkA = {
  brownA1: '#91110002',
  brownA2: '#fba67c0c',
  brownA3: '#fcb58c19',
  brownA4: '#fbbb8a24',
  brownA5: '#fcb88931',
  brownA6: '#fdba8741',
  brownA7: '#ffbb8856',
  brownA8: '#ffbe8773',
  brownA9: '#feb87da8',
  brownA10: '#ffc18cb3',
  brownA11: '#fed1aad9',
  brownA12: '#feecd4f2',
}

export const brownDarkP3 = {
  brown1: 'color(display-p3 0.071 0.067 0.059)',
  brown2: 'color(display-p3 0.107 0.095 0.087)',
  brown3: 'color(display-p3 0.151 0.13 0.115)',
  brown4: 'color(display-p3 0.191 0.161 0.138)',
  brown5: 'color(display-p3 0.235 0.194 0.162)',
  brown6: 'color(display-p3 0.291 0.237 0.192)',
  brown7: 'color(display-p3 0.365 0.295 0.232)',
  brown8: 'color(display-p3 0.469 0.377 0.287)',
  brown9: 'color(display-p3 0.651 0.505 0.368)',
  brown10: 'color(display-p3 0.697 0.557 0.423)',
  brown11: 'color(display-p3 0.835 0.715 0.597)',
  brown12: 'color(display-p3 0.938 0.885 0.802)',
}

export const brownDarkP3A = {
  brownA1: 'color(display-p3 0.855 0.071 0 / 0.005)',
  brownA2: 'color(display-p3 0.98 0.706 0.525 / 0.043)',
  brownA3: 'color(display-p3 0.996 0.745 0.576 / 0.093)',
  brownA4: 'color(display-p3 1 0.765 0.592 / 0.135)',
  brownA5: 'color(display-p3 1 0.761 0.588 / 0.181)',
  brownA6: 'color(display-p3 1 0.773 0.592 / 0.24)',
  brownA7: 'color(display-p3 0.996 0.776 0.58 / 0.32)',
  brownA8: 'color(display-p3 1 0.78 0.573 / 0.433)',
  brownA9: 'color(display-p3 1 0.769 0.549 / 0.627)',
  brownA10: 'color(display-p3 1 0.792 0.596 / 0.677)',
  brownA11: 'color(display-p3 0.835 0.715 0.597)',
  brownA12: 'color(display-p3 0.938 0.885 0.802)',
}

export const bronzeDark = {
  bronze1: '#141110',
  bronze2: '#1c1917',
  bronze3: '#262220',
  bronze4: '#302a27',
  bronze5: '#3b3330',
  bronze6: '#493e3a',
  bronze7: '#5a4c47',
  bronze8: '#6f5f58',
  bronze9: '#a18072',
  bronze10: '#ae8c7e',
  bronze11: '#d4b3a5',
  bronze12: '#ede0d9',
}

export const bronzeDarkA = {
  bronzeA1: '#d1110004',
  bronzeA2: '#fbbc910c',
  bronzeA3: '#faceb817',
  bronzeA4: '#facdb622',
  bronzeA5: '#ffd2c12d',
  bronzeA6: '#ffd1c03c',
  bronzeA7: '#fdd0c04f',
  bronzeA8: '#ffd6c565',
  bronzeA9: '#fec7b09b',
  bronzeA10: '#fecab5a9',
  bronzeA11: '#ffd7c6d1',
  bronzeA12: '#fff1e9ec',
}

export const bronzeDarkP3 = {
  bronze1: 'color(display-p3 0.076 0.067 0.063)',
  bronze2: 'color(display-p3 0.106 0.097 0.093)',
  bronze3: 'color(display-p3 0.147 0.132 0.125)',
  bronze4: 'color(display-p3 0.185 0.166 0.156)',
  bronze5: 'color(display-p3 0.227 0.202 0.19)',
  bronze6: 'color(display-p3 0.278 0.246 0.23)',
  bronze7: 'color(display-p3 0.343 0.302 0.281)',
  bronze8: 'color(display-p3 0.426 0.374 0.347)',
  bronze9: 'color(display-p3 0.611 0.507 0.455)',
  bronze10: 'color(display-p3 0.66 0.556 0.504)',
  bronze11: 'color(display-p3 0.81 0.707 0.655)',
  bronze12: 'color(display-p3 0.921 0.88 0.854)',
}

export const bronzeDarkP3A = {
  bronzeA1: 'color(display-p3 0.941 0.067 0 / 0.009)',
  bronzeA2: 'color(display-p3 0.98 0.8 0.706 / 0.043)',
  bronzeA3: 'color(display-p3 0.988 0.851 0.761 / 0.085)',
  bronzeA4: 'color(display-p3 0.996 0.839 0.78 / 0.127)',
  bronzeA5: 'color(display-p3 0.996 0.863 0.773 / 0.173)',
  bronzeA6: 'color(display-p3 1 0.863 0.796 / 0.227)',
  bronzeA7: 'color(display-p3 1 0.867 0.8 / 0.295)',
  bronzeA8: 'color(display-p3 1 0.859 0.788 / 0.387)',
  bronzeA9: 'color(display-p3 1 0.82 0.733 / 0.585)',
  bronzeA10: 'color(display-p3 1 0.839 0.761 / 0.635)',
  bronzeA11: 'color(display-p3 0.81 0.707 0.655)',
  bronzeA12: 'color(display-p3 0.921 0.88 0.854)',
}

export const goldDark = {
  gold1: '#121211',
  gold2: '#1b1a17',
  gold3: '#24231f',
  gold4: '#2d2b26',
  gold5: '#38352e',
  gold6: '#444039',
  gold7: '#544f46',
  gold8: '#696256',
  gold9: '#978365',
  gold10: '#a39073',
  gold11: '#cbb99f',
  gold12: '#e8e2d9',
}

export const goldDarkA = {
  goldA1: '#91911102',
  goldA2: '#f9e29d0b',
  goldA3: '#f8ecbb15',
  goldA4: '#ffeec41e',
  goldA5: '#feecc22a',
  goldA6: '#feebcb37',
  goldA7: '#ffedcd48',
  goldA8: '#fdeaca5f',
  goldA9: '#ffdba690',
  goldA10: '#fedfb09d',
  goldA11: '#fee7c6c8',
  goldA12: '#fef7ede7',
}

export const goldDarkP3 = {
  gold1: 'color(display-p3 0.071 0.071 0.067)',
  gold2: 'color(display-p3 0.104 0.101 0.09)',
  gold3: 'color(display-p3 0.141 0.136 0.122)',
  gold4: 'color(display-p3 0.177 0.17 0.152)',
  gold5: 'color(display-p3 0.217 0.207 0.185)',
  gold6: 'color(display-p3 0.265 0.252 0.225)',
  gold7: 'color(display-p3 0.327 0.31 0.277)',
  gold8: 'color(display-p3 0.407 0.384 0.342)',
  gold9: 'color(display-p3 0.579 0.517 0.41)',
  gold10: 'color(display-p3 0.628 0.566 0.463)',
  gold11: 'color(display-p3 0.784 0.728 0.635)',
  gold12: 'color(display-p3 0.906 0.887 0.855)',
}

export const goldDarkP3A = {
  goldA1: 'color(display-p3 0.855 0.855 0.071 / 0.005)',
  goldA2: 'color(display-p3 0.98 0.89 0.616 / 0.043)',
  goldA3: 'color(display-p3 1 0.949 0.753 / 0.08)',
  goldA4: 'color(display-p3 1 0.933 0.8 / 0.118)',
  goldA5: 'color(display-p3 1 0.949 0.804 / 0.16)',
  goldA6: 'color(display-p3 1 0.925 0.8 / 0.215)',
  goldA7: 'color(display-p3 1 0.945 0.831 / 0.278)',
  goldA8: 'color(display-p3 1 0.937 0.82 / 0.366)',
  goldA9: 'color(display-p3 0.996 0.882 0.69 / 0.551)',
  goldA10: 'color(display-p3 1 0.894 0.725 / 0.601)',
  goldA11: 'color(display-p3 0.784 0.728 0.635)',
  goldA12: 'color(display-p3 0.906 0.887 0.855)',
}

export const skyDark = {
  sky1: '#0d141f',
  sky2: '#111a27',
  sky3: '#112840',
  sky4: '#113555',
  sky5: '#154467',
  sky6: '#1b537b',
  sky7: '#1f6692',
  sky8: '#197cae',
  sky9: '#7ce2fe',
  sky10: '#a8eeff',
  sky11: '#75c7f0',
  sky12: '#c2f3ff',
}

export const skyDarkA = {
  skyA1: '#0044ff0f',
  skyA2: '#1171fb18',
  skyA3: '#1184fc33',
  skyA4: '#128fff49',
  skyA5: '#1c9dfd5d',
  skyA6: '#28a5ff72',
  skyA7: '#2badfe8b',
  skyA8: '#1db2fea9',
  skyA9: '#7ce3fffe',
  skyA10: '#a8eeff',
  skyA11: '#7cd3ffef',
  skyA12: '#c2f3ff',
}

export const skyDarkP3 = {
  sky1: 'color(display-p3 0.056 0.078 0.116)',
  sky2: 'color(display-p3 0.075 0.101 0.149)',
  sky3: 'color(display-p3 0.089 0.154 0.244)',
  sky4: 'color(display-p3 0.106 0.207 0.323)',
  sky5: 'color(display-p3 0.135 0.261 0.394)',
  sky6: 'color(display-p3 0.17 0.322 0.469)',
  sky7: 'color(display-p3 0.205 0.394 0.557)',
  sky8: 'color(display-p3 0.232 0.48 0.665)',
  sky9: 'color(display-p3 0.585 0.877 0.983)',
  sky10: 'color(display-p3 0.718 0.925 0.991)',
  sky11: 'color(display-p3 0.536 0.772 0.924)',
  sky12: 'color(display-p3 0.799 0.947 0.993)',
}

export const skyDarkP3A = {
  skyA1: 'color(display-p3 0 0.282 0.996 / 0.055)',
  skyA2: 'color(display-p3 0.157 0.467 0.992 / 0.089)',
  skyA3: 'color(display-p3 0.192 0.522 0.996 / 0.19)',
  skyA4: 'color(display-p3 0.212 0.584 1 / 0.274)',
  skyA5: 'color(display-p3 0.259 0.631 1 / 0.349)',
  skyA6: 'color(display-p3 0.302 0.655 1 / 0.433)',
  skyA7: 'color(display-p3 0.329 0.686 1 / 0.526)',
  skyA8: 'color(display-p3 0.325 0.71 1 / 0.643)',
  skyA9: 'color(display-p3 0.592 0.894 1 / 0.984)',
  skyA10: 'color(display-p3 0.722 0.933 1 / 0.992)',
  skyA11: 'color(display-p3 0.536 0.772 0.924)',
  skyA12: 'color(display-p3 0.799 0.947 0.993)',
}

export const mintDark = {
  mint1: '#0e1515',
  mint2: '#0f1b1b',
  mint3: '#092c2b',
  mint4: '#003a38',
  mint5: '#004744',
  mint6: '#105650',
  mint7: '#1e685f',
  mint8: '#277f70',
  mint9: '#86ead4',
  mint10: '#a8f5e5',
  mint11: '#58d5ba',
  mint12: '#c4f5e1',
}

export const mintDarkA = {
  mintA1: '#00dede05',
  mintA2: '#00f9f90b',
  mintA3: '#00fff61d',
  mintA4: '#00fff42c',
  mintA5: '#00fff23a',
  mintA6: '#0effeb4a',
  mintA7: '#34fde55e',
  mintA8: '#41ffdf76',
  mintA9: '#92ffe7e9',
  mintA10: '#aefeedf5',
  mintA11: '#67ffded2',
  mintA12: '#cbfee9f5',
}

export const mintDarkP3 = {
  mint1: 'color(display-p3 0.059 0.082 0.081)',
  mint2: 'color(display-p3 0.068 0.104 0.105)',
  mint3: 'color(display-p3 0.077 0.17 0.168)',
  mint4: 'color(display-p3 0.068 0.224 0.22)',
  mint5: 'color(display-p3 0.104 0.275 0.264)',
  mint6: 'color(display-p3 0.154 0.332 0.313)',
  mint7: 'color(display-p3 0.207 0.403 0.373)',
  mint8: 'color(display-p3 0.258 0.49 0.441)',
  mint9: 'color(display-p3 0.62 0.908 0.834)',
  mint10: 'color(display-p3 0.725 0.954 0.898)',
  mint11: 'color(display-p3 0.482 0.825 0.733)',
  mint12: 'color(display-p3 0.807 0.955 0.887)',
}

export const mintDarkP3A = {
  mintA1: 'color(display-p3 0 0.992 0.992 / 0.017)',
  mintA2: 'color(display-p3 0.071 0.98 0.98 / 0.043)',
  mintA3: 'color(display-p3 0.176 0.996 0.996 / 0.11)',
  mintA4: 'color(display-p3 0.071 0.996 0.973 / 0.169)',
  mintA5: 'color(display-p3 0.243 1 0.949 / 0.223)',
  mintA6: 'color(display-p3 0.369 1 0.933 / 0.286)',
  mintA7: 'color(display-p3 0.459 1 0.914 / 0.362)',
  mintA8: 'color(display-p3 0.49 1 0.89 / 0.454)',
  mintA9: 'color(display-p3 0.678 0.996 0.914 / 0.904)',
  mintA10: 'color(display-p3 0.761 1 0.941 / 0.95)',
  mintA11: 'color(display-p3 0.482 0.825 0.733)',
  mintA12: 'color(display-p3 0.807 0.955 0.887)',
}

export const limeDark = {
  lime1: '#11130c',
  lime2: '#151a10',
  lime3: '#1f2917',
  lime4: '#29371d',
  lime5: '#334423',
  lime6: '#3d522a',
  lime7: '#496231',
  lime8: '#577538',
  lime9: '#bdee63',
  lime10: '#d4ff70',
  lime11: '#bde56c',
  lime12: '#e3f7ba',
}

export const limeDarkA = {
  limeA1: '#11bb0003',
  limeA2: '#78f7000a',
  limeA3: '#9bfd4c1a',
  limeA4: '#a7fe5c29',
  limeA5: '#affe6537',
  limeA6: '#b2fe6d46',
  limeA7: '#b6ff6f57',
  limeA8: '#b6fd6d6c',
  limeA9: '#caff69ed',
  limeA10: '#d4ff70',
  limeA11: '#d1fe77e4',
  limeA12: '#e9febff7',
}

export const limeDarkP3 = {
  lime1: 'color(display-p3 0.067 0.073 0.048)',
  lime2: 'color(display-p3 0.086 0.1 0.067)',
  lime3: 'color(display-p3 0.13 0.16 0.099)',
  lime4: 'color(display-p3 0.172 0.214 0.126)',
  lime5: 'color(display-p3 0.213 0.266 0.153)',
  lime6: 'color(display-p3 0.257 0.321 0.182)',
  lime7: 'color(display-p3 0.307 0.383 0.215)',
  lime8: 'color(display-p3 0.365 0.456 0.25)',
  lime9: 'color(display-p3 0.78 0.928 0.466)',
  lime10: 'color(display-p3 0.865 0.995 0.519)',
  lime11: 'color(display-p3 0.771 0.893 0.485)',
  lime12: 'color(display-p3 0.905 0.966 0.753)',
}

export const limeDarkP3A = {
  limeA1: 'color(display-p3 0.067 0.941 0 / 0.009)',
  limeA2: 'color(display-p3 0.584 0.996 0.071 / 0.038)',
  limeA3: 'color(display-p3 0.69 1 0.38 / 0.101)',
  limeA4: 'color(display-p3 0.729 1 0.435 / 0.16)',
  limeA5: 'color(display-p3 0.745 1 0.471 / 0.215)',
  limeA6: 'color(display-p3 0.769 1 0.482 / 0.274)',
  limeA7: 'color(display-p3 0.769 1 0.506 / 0.341)',
  limeA8: 'color(display-p3 0.784 1 0.51 / 0.416)',
  limeA9: 'color(display-p3 0.839 1 0.502 / 0.925)',
  limeA10: 'color(display-p3 0.871 1 0.522 / 0.996)',
  limeA11: 'color(display-p3 0.771 0.893 0.485)',
  limeA12: 'color(display-p3 0.905 0.966 0.753)',
}

export const yellowDark = {
  yellow1: '#14120b',
  yellow2: '#1b180f',
  yellow3: '#2d2305',
  yellow4: '#362b00',
  yellow5: '#433500',
  yellow6: '#524202',
  yellow7: '#665417',
  yellow8: '#836a21',
  yellow9: '#ffe629',
  yellow10: '#ffff57',
  yellow11: '#f5e147',
  yellow12: '#f6eeb4',
}

export const yellowDarkA = {
  yellowA1: '#d1510004',
  yellowA2: '#f9b4000b',
  yellowA3: '#ffaa001e',
  yellowA4: '#fdb70028',
  yellowA5: '#febb0036',
  yellowA6: '#fec40046',
  yellowA7: '#fdcb225c',
  yellowA8: '#fdca327b',
  yellowA9: '#ffe629',
  yellowA10: '#ffff57',
  yellowA11: '#fee949f5',
  yellowA12: '#fef6baf6',
}

export const yellowDarkP3 = {
  yellow1: 'color(display-p3 0.078 0.069 0.047)',
  yellow2: 'color(display-p3 0.103 0.094 0.063)',
  yellow3: 'color(display-p3 0.168 0.137 0.039)',
  yellow4: 'color(display-p3 0.209 0.169 0)',
  yellow5: 'color(display-p3 0.255 0.209 0)',
  yellow6: 'color(display-p3 0.31 0.261 0.07)',
  yellow7: 'color(display-p3 0.389 0.331 0.135)',
  yellow8: 'color(display-p3 0.497 0.42 0.182)',
  yellow9: 'color(display-p3 1 0.92 0.22)',
  yellow10: 'color(display-p3 1 1 0.456)',
  yellow11: 'color(display-p3 0.948 0.885 0.392)',
  yellow12: 'color(display-p3 0.959 0.934 0.731)',
}

export const yellowDarkP3A = {
  yellowA1: 'color(display-p3 0.973 0.369 0 / 0.013)',
  yellowA2: 'color(display-p3 0.996 0.792 0 / 0.038)',
  yellowA3: 'color(display-p3 0.996 0.71 0 / 0.11)',
  yellowA4: 'color(display-p3 0.996 0.741 0 / 0.152)',
  yellowA5: 'color(display-p3 0.996 0.765 0 / 0.202)',
  yellowA6: 'color(display-p3 0.996 0.816 0.082 / 0.261)',
  yellowA7: 'color(display-p3 1 0.831 0.263 / 0.345)',
  yellowA8: 'color(display-p3 1 0.831 0.314 / 0.463)',
  yellowA9: 'color(display-p3 1 0.922 0.22)',
  yellowA10: 'color(display-p3 1 1 0.455)',
  yellowA11: 'color(display-p3 0.948 0.885 0.392)',
  yellowA12: 'color(display-p3 0.959 0.934 0.731)',
}

export const amberDark = {
  amber1: '#16120c',
  amber2: '#1d180f',
  amber3: '#302008',
  amber4: '#3f2700',
  amber5: '#4d3000',
  amber6: '#5c3d05',
  amber7: '#714f19',
  amber8: '#8f6424',
  amber9: '#ffc53d',
  amber10: '#ffd60a',
  amber11: '#ffca16',
  amber12: '#ffe7b3',
}

export const amberDarkA = {
  amberA1: '#e63c0006',
  amberA2: '#fd9b000d',
  amberA3: '#fa820022',
  amberA4: '#fc820032',
  amberA5: '#fd8b0041',
  amberA6: '#fd9b0051',
  amberA7: '#ffab2567',
  amberA8: '#ffae3587',
  amberA9: '#ffc53d',
  amberA10: '#ffd60a',
  amberA11: '#ffca16',
  amberA12: '#ffe7b3',
}

export const amberDarkP3 = {
  amber1: 'color(display-p3 0.082 0.07 0.05)',
  amber2: 'color(display-p3 0.111 0.094 0.064)',
  amber3: 'color(display-p3 0.178 0.128 0.049)',
  amber4: 'color(display-p3 0.239 0.156 0)',
  amber5: 'color(display-p3 0.29 0.193 0)',
  amber6: 'color(display-p3 0.344 0.245 0.076)',
  amber7: 'color(display-p3 0.422 0.314 0.141)',
  amber8: 'color(display-p3 0.535 0.399 0.189)',
  amber9: 'color(display-p3 1 0.77 0.26)',
  amber10: 'color(display-p3 1 0.87 0.15)',
  amber11: 'color(display-p3 1 0.8 0.29)',
  amber12: 'color(display-p3 0.984 0.909 0.726)',
}

export const amberDarkP3A = {
  amberA1: 'color(display-p3 0.992 0.298 0 / 0.017)',
  amberA2: 'color(display-p3 0.988 0.651 0 / 0.047)',
  amberA3: 'color(display-p3 1 0.6 0 / 0.118)',
  amberA4: 'color(display-p3 1 0.557 0 / 0.185)',
  amberA5: 'color(display-p3 1 0.592 0 / 0.24)',
  amberA6: 'color(display-p3 1 0.659 0.094 / 0.299)',
  amberA7: 'color(display-p3 1 0.714 0.263 / 0.383)',
  amberA8: 'color(display-p3 0.996 0.729 0.306 / 0.5)',
  amberA9: 'color(display-p3 1 0.769 0.259)',
  amberA10: 'color(display-p3 1 0.871 0.149)',
  amberA11: 'color(display-p3 1 0.8 0.29)',
  amberA12: 'color(display-p3 0.984 0.909 0.726)',
}

export const orangeDark = {
  orange1: '#17120e',
  orange2: '#1e160f',
  orange3: '#331e0b',
  orange4: '#462100',
  orange5: '#562800',
  orange6: '#66350c',
  orange7: '#7e451d',
  orange8: '#a35829',
  orange9: '#f76b15',
  orange10: '#ff801f',
  orange11: '#ffa057',
  orange12: '#ffe0c2',
}

export const orangeDarkA = {
  orangeA1: '#ec360007',
  orangeA2: '#fe6d000e',
  orangeA3: '#fb6a0025',
  orangeA4: '#ff590039',
  orangeA5: '#ff61004a',
  orangeA6: '#fd75045c',
  orangeA7: '#ff832c75',
  orangeA8: '#fe84389d',
  orangeA9: '#fe6d15f7',
  orangeA10: '#ff801f',
  orangeA11: '#ffa057',
  orangeA12: '#ffe0c2',
}

export const orangeDarkP3 = {
  orange1: 'color(display-p3 0.088 0.07 0.057)',
  orange2: 'color(display-p3 0.113 0.089 0.061)',
  orange3: 'color(display-p3 0.189 0.12 0.056)',
  orange4: 'color(display-p3 0.262 0.132 0)',
  orange5: 'color(display-p3 0.315 0.168 0.016)',
  orange6: 'color(display-p3 0.376 0.219 0.088)',
  orange7: 'color(display-p3 0.465 0.283 0.147)',
  orange8: 'color(display-p3 0.601 0.359 0.201)',
  orange9: 'color(display-p3 0.9 0.45 0.2)',
  orange10: 'color(display-p3 0.98 0.51 0.23)',
  orange11: 'color(display-p3 1 0.63 0.38)',
  orange12: 'color(display-p3 0.98 0.883 0.775)',
}

export const orangeDarkP3A = {
  orangeA1: 'color(display-p3 0.961 0.247 0 / 0.022)',
  orangeA2: 'color(display-p3 0.992 0.529 0 / 0.051)',
  orangeA3: 'color(display-p3 0.996 0.486 0 / 0.131)',
  orangeA4: 'color(display-p3 0.996 0.384 0 / 0.211)',
  orangeA5: 'color(display-p3 1 0.455 0 / 0.265)',
  orangeA6: 'color(display-p3 1 0.529 0.129 / 0.332)',
  orangeA7: 'color(display-p3 1 0.569 0.251 / 0.429)',
  orangeA8: 'color(display-p3 1 0.584 0.302 / 0.572)',
  orangeA9: 'color(display-p3 1 0.494 0.216 / 0.895)',
  orangeA10: 'color(display-p3 1 0.522 0.235 / 0.979)',
  orangeA11: 'color(display-p3 1 0.63 0.38)',
  orangeA12: 'color(display-p3 0.98 0.883 0.775)',
}

export const colors = {
  ...grayDark,
  ...mauveDark,
  ...slateDark,
  ...sageDark,
  ...oliveDark,
  ...sandDark,
  ...goldDark,
  ...bronzeDark,
  ...brownDark,
  ...yellowDark,
  ...amberDark,
  ...orangeDark,
  ...tomatoDark,
  ...redDark,
  ...rubyDark,
  ...crimsonDark,
  ...pinkDark,
  ...plumDark,
  ...purpleDark,
  ...violetDark,
  ...irisDark,
  ...indigoDark,
  ...blueDark,
  ...cyanDark,
  ...tealDark,
  ...jadeDark,
  ...greenDark,
  ...grassDark,
  ...limeDark,
  ...mintDark,
  ...skyDark,
}
