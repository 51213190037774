import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { pointerOutsideOfPreview } from '@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview'
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview'
import classnames from 'classnames'
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import ReactDOM from 'react-dom'
import invariant from 'tiny-invariant'

import { REVEAL_TIME_MS } from '../../constants/settings'
import { useGameBoard } from '../../context/GameBoardContext'
import { useBreakpoint } from '../../hooks/use-breakpoint'
import { useDoubleTap } from '../../hooks/use-double-tap'
import { useLongPress } from '../../hooks/use-long-press'
import { LongPressEventType } from '../../hooks/use-long-press/types'
import { getStoredIsHighContrastMode } from '../../lib/localStorage'
import { CharType } from '../../lib/statuses'
import { cn, isNullish } from '../../lib/utils'
import { solution } from '../../lib/words'
import { GradientPicker } from '../shared/gradient-picker'
import { Button } from '../ui/button'
import { hasOwnProperty } from './Keyboard'

type Props = {
  children?: ReactNode
  value: string
  width?: number
  status?: CharType
  onClick: (value: string) => void
  onOpenPaintTool?: (key: string, index?: number) => void
  isRevealing?: boolean
  annotedColor?: string
}

export const Key = ({
  children,
  status,
  width = 40,
  value,
  onClick,
  onOpenPaintTool,
  isRevealing,
  annotedColor,
}: Props) => {
  const ref = useRef(null)
  const [dragging, setDragging] = useState<boolean>(false)

  useEffect(() => {
    const el = ref.current
    invariant(el)

    return draggable({
      element: el,
      onGenerateDragPreview(args) {
        setCustomNativeDragPreview({
          getOffset: pointerOutsideOfPreview({
            x: `0px`,
            y: `-100px`,
          }),
          render: function render({ container }) {
            ReactDOM.render(
              <div className="flex bg-transparent p-3">
                {' '}
                <Button variant={'gradient'} className=" text-lg" size={'xl'}>
                  {args.source.element.innerText}
                </Button>
              </div>,
              container
            )
            return function cleanup() {
              ReactDOM.unmountComponentAtNode(container)
            }
          },
          nativeSetDragImage: args.nativeSetDragImage,
        })
      },
      onDragStart: () => setDragging(true),
      onDrop: () => setDragging(false),
      getInitialData(args) {
        return { value }
      },
      canDrag(args) {
        if (
          args.element.innerText?.toLowerCase().includes('delete') ||
          args.element.innerText?.toLowerCase().includes('enter')
        ) {
          return false
        }
        return true
      },
    })
  }, [])
  const keyDelayMs = REVEAL_TIME_MS * solution.length
  const isHighContrast = getStoredIsHighContrastMode()

  const classes = classnames(
    'xxshort:h-8 xxshort:w-8 xxshort:text-xs xshort:w-10 xshort:h-10 flex short:h-12 h-14 w-14 items-center justify-center rounded mx-0.5 text-sm font-bold cursor-pointer select-none text-white dark:text-white bevel bevel-2xl',
    {
      'transition ease-in-out': isRevealing,
      'bg-key dark:bg-key hover:bg-slate-300 hover:dark:bg-blue-700 active:bg-slate-400 active:dark:bg-blue-600':
        !status,
      'bg-slate-400 dark:bg-slate-800 text-white': status === 'absent',
      'bg-orange-500 hover:bg-orange-600 active:bg-orange-700 text-white':
        status === 'correct' && isHighContrast,
      'bg-cyan-500 hover:bg-cyan-600 active:bg-cyan-700 text-white':
        status === 'present' && isHighContrast,
      'bg-green-500 hover:bg-green-600 active:bg-green-700 text-white border-white':
        status === 'correct' && !isHighContrast,
      'bg-yellow-500 hover:bg-yellow-600 active:bg-yellow-700 text-white':
        status === 'present' && !isHighContrast,
    }
  )

  const styles = {
    transitionDelay: isRevealing ? `${keyDelayMs}ms` : 'unset',
    width: `${width}px`,
  }

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    onClick(value)
    event.currentTarget.blur()
  }
  const [isPaintToolOpen, SetPaintToolOpen] = useState(false)
  const [longPressed, setLongPressed] = useState(false)
  const { annotateKey, annotatedKeys, setKeyInFocus } = useGameBoard()
  const onAnnotateKey = useCallback<(color: string) => void>(
    (color) => {
      console.info(`Annotating key ${value} with ${color}`)
      annotateKey(value, color)
    },
    [value]
  )
  const onDetectLongPress = useCallback(() => {
    if (onOpenPaintTool) {
      onOpenPaintTool(value)
    }
    if (setKeyInFocus) {
      setKeyInFocus(value)
    }
    SetPaintToolOpen(true)
    setLongPressed(false)
  }, [value, setKeyInFocus])

  // useDoubleTap((args) => args, 200, {
  //   onSingleTap: (t) => console.info('signle tap'),
  // })
  const callback = useCallback(() => {
    if (!dragging) {
      setLongPressed(true)
    }
  }, [dragging])

  const isDesktop = useBreakpoint('sm')?.isAboveSm

  const longPressDetection = useMemo(
    () => (isDesktop ? LongPressEventType.Mouse : LongPressEventType.Touch),
    [isDesktop]
  )

  const bind = useLongPress(true ? callback : null, {
    onStart: (event, meta) => {
      console.info(`Press started event`, event)
      console.info(`Press started meta`, meta)
    },
    onFinish: (event, meta) => {
      console.info(`Press finished event`, event)
      console.info(`Press finished meta`, meta)
      onDetectLongPress()
    },
    onCancel: (event, meta) => {
      console.info(`Press cancelled event`, event)
      console.info(`Press cancelled meta`, meta)
    },

    //onMove: (e) => console.log("Detected mouse or touch movement"),
    filterEvents: (event) => {
      return true
    }, // All events can potentially trigger long press
    threshold: 150,
    captureEvent: true,
    cancelOnMovement: false,
    cancelOutsideElement: true,
    detect: longPressDetection,
  })
  const handlers = bind('Keyboard Key Context')
  // console.info(className)
  return (
    // <div className="relative">
    // <div className="absolute -inset-1 rounded-lg bg-gradient-to-r from-yellow-600 via-orange-600 to-red-600 opacity-75 blur"></div> */}
    // <GradientPicker
    //   open={isPaintToolOpen && !dragging}
    //   setIsOpen={SetPaintToolOpen}
    //   setBackground={(color) => {
    //     onAnnotateKey(color)
    //   }}
    // >
    <button
      ref={ref}
      style={annotedColor ? { background: annotedColor, width: 40 } : styles}
      aria-label={`${value}${status ? ' ' + status : ''}`}
      className={classes}
      onClick={handleClick}
      {...handlers}
    >
      {children || value}
    </button>
    // </GradientPicker>
    // </div>
  )
}
