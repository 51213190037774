import './App.css'

import useUrlState from '@ahooksjs/use-url-state'
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { ClockIcon } from '@heroicons/react/outline'
import {
  IconLine,
  IconPointFilled,
  IconSeparatorHorizontal,
  IconSeparatorVertical,
} from '@tabler/icons-react'
import { useLocalStorageState } from 'ahooks'
import classNames from 'classnames'
import { format } from 'date-fns'
import { default as GraphemeSplitter } from 'grapheme-splitter'
import Plausible from 'plausible-tracker'
import { useCallback, useEffect, useState } from 'react'
import Div100vh from 'react-div-100vh'

import { AlertContainer } from './components/alerts/AlertContainer'
import { Grid } from './components/grid/Grid'
import { Keyboard } from './components/keyboard/Keyboard'
// import { Chessboard } from './components/keyboard/dnd/DndKeyboard'
import { DatePickerModal } from './components/modals/DatePickerModal'
import { InfoModal } from './components/modals/InfoModal'
import { MigrateStatsModal } from './components/modals/MigrateStatsModal'
import { SettingsModal } from './components/modals/SettingsModal'
import { StatsModal } from './components/modals/StatsModal'
import { Navbar } from './components/navbar/Navbar'
import { TimerValue, Unit, createTimeModel, useTimer } from './components/timer'
import Display from './components/timer/display'
import {
  DATE_LOCALE,
  DISCOURAGE_INAPP_BROWSERS,
  EMPTY_LETTER,
  LONG_ALERT_TIME_MS,
  MAX_CHALLENGES,
  REVEAL_TIME_MS,
  WELCOME_INFO_MODAL_MS,
  WORD_LENGTH,
} from './constants/settings'
import {
  CORRECT_WORD_MESSAGE,
  DISCOURAGE_INAPP_BROWSER_TEXT,
  GAME_COPIED_MESSAGE,
  HARD_MODE_ALERT_MESSAGE,
  NOT_ENOUGH_LETTERS_MESSAGE,
  SHARE_FAILURE_TEXT,
  WIN_MESSAGES,
  WORD_NOT_FOUND_MESSAGE,
} from './constants/strings'
import { useAlert } from './context/AlertContext'
import { GameBoardProvider } from './context/GameBoardContext'
import { isInAppBrowser } from './lib/browser'
import {
  getStoredIsHighContrastMode,
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
  setStoredIsHighContrastMode,
} from './lib/localStorage'
import { addStatsForCompletedGame, loadStats } from './lib/stats'
import { getGuessStatuses } from './lib/statuses'
import { replaceCharAt } from './lib/string-utils'
import {
  findFirstUnusedReveal,
  getGameDate,
  getIsLatestGame,
  isWinningWord,
  isWordInWordList,
  setGameDate,
  solution,
  solutionGameDate,
  unicodeLength,
} from './lib/words'

const startingScore = 1000

const baseTimeInterval = { unit: 's', value: 1 }
const baseTimeCost = 10
const baseUnitTime = 1
const baseUnitTimeCost = 1
const baseGuessCost = 25

const indirectHitBonusModifier = 10
const directHitBonusModifier = 25

export interface GameRules {
  startingScore: number
  timeUnit: Unit
  unitTime: number
  unitTimeCost: number
  guessCost: number
  guessLimit?: number
  indirectHitBonusModifier?: number
  directHitBonusModifier?: number
}

export const buildDefaultRules = (): GameRules => {
  return {
    startingScore,
    timeUnit: 's',
    unitTime: baseUnitTime,
    unitTimeCost: baseUnitTimeCost,
    guessCost: baseGuessCost,
  }
}

export const buildRulesWithBonuses = (): GameRules => {
  return {
    ...buildDefaultRules(),
    indirectHitBonusModifier,
    directHitBonusModifier,
  }
}

const frequentUpdatesStopwatch = createTimeModel(
  {
    // default timer to give player 10 minutes
    initialTime: 1000 * 60 * 10,
    // update every second
    timeToUpdate: 1000,
    roundUnit: 's',
    lastUnit: 'm',
    direction: 'backward',
    checkpoints: [
      {
        time: 90000,
        action: () => console.info('Event raised at checkpoint 90 seconds'),
      },
      {
        time: 82000,
        action: () => console.info('Event raised at 82 second mark'),
      },
    ],
    startImmediately: true,
  },
  {
    onChange(timerValue) {
      console.info(timerValue.s)
    },
  }
)
const { enableAutoPageviews, trackEvent } = Plausible({ domain: 'hunch.game' })

// This tracks the current page view and all future ones as well
enableAutoPageviews()

function App() {
  const [score, setScore] = useState(startingScore)
  const [rules, setRules] = useUrlState<GameRules>({ ...buildDefaultRules() })

  useEffect(() => {
    if (!rules) {
      setRules(buildDefaultRules())
    }
  }, [])
  // const [rules, setRules] = useLocalStorageState<GameRules>('@hunch/rules', {
  //   serializer: (rules) => JSON.stringify(rules),
  //   deserializer: (v) => JSON.parse(v) as GameRules,
  //   defaultValue: buildDefaultRules(),
  // });
  const loops = [
    {
      predicate: (v: TimerValue) =>
        v.state === 'PLAYING' && v.s % rules?.unitTime! === 0,
      action: (v: TimerValue) =>
        console.info(`${v.s}:`, setScore(score - rules?.unitTimeCost!)),
    },
    // {
    //   predicate: (v: TimerValue) => v.s % 9 === 0,
    //   action: (v: TimerValue) => console.info(`${v.s}:`, `Every 9 seconds`),
    // },
  ]
  const { value, start, stop, pause, resume, reset } = useTimer(
    frequentUpdatesStopwatch
  )

  useEffect(() => {
    loops.forEach((config, index) => {
      if (config.predicate && config.predicate(value)) {
        config.action(value)
      }
    })
    if (score <= 0) {
      stop()
      setScore(0)
    }
  }, [value.s])

  useEffect(() => {
    // stop after 20 seconds
    const timeout = setTimeout(() => {
      frequentUpdatesStopwatch.stop()
    }, 1000000)

    return () => clearTimeout(timeout)
  }, [])

  const isLatestGame = getIsLatestGame()
  const gameDate = getGameDate()
  const prefersDarkMode = true
  // const prefersDarkMode = window.matchMedia(
  //   '(prefers-color-scheme: dark)'
  // ).matches

  const [startedPlaying, setStartedPlaying] = useState<boolean>(false)
  const [cursor, setCursor] = useState(0)

  const { showError: showErrorAlert, showSuccess: showSuccessAlert } =
    useAlert()
  const [currentGuess, setCurrentGuess] = useState('     ')

  const [pendingGameCompleteEvent, setPendingGameCompleteEvent] = useState<
    'none' | 'won' | 'lost' | 'won_again' | 'lost_again'
  >('none')
  const [isGameWon, setIsGameWon] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isDatePickerModalOpen, setIsDatePickerModalOpen] = useState(false)
  const [isMigrateStatsModalOpen, setIsMigrateStatsModalOpen] = useState(false)
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
  const [currentRowClass, setCurrentRowClass] = useState('')
  const [isGameLost, setIsGameLost] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('theme')
      ? localStorage.getItem('theme') === 'dark'
      : prefersDarkMode
        ? true
        : false
  )
  const [isHighContrastMode, setIsHighContrastMode] = useState(
    getStoredIsHighContrastMode()
  )
  const [isRevealing, setIsRevealing] = useState(false)
  const [guesses, setGuesses] = useState<string[]>(() => {
    const loaded = loadGameStateFromLocalStorage(isLatestGame)
    if ((loaded?.guesses?.length || 0) > 0) {
      setStartedPlaying(true)
    }
    if (loaded?.solution !== solution) {
      return []
    }
    const gameWasWon = loaded.guesses.includes(solution)
    if (gameWasWon) {
      setIsGameWon(true)
    }
    if (loaded.guesses.length === MAX_CHALLENGES && !gameWasWon) {
      setIsGameLost(true)
      showErrorAlert(CORRECT_WORD_MESSAGE(solution), {
        persist: true,
      })
    }
    return loaded.guesses
  })

  const [stats, setStats] = useState(() => loadStats())

  const [lastEvent, setLastEvent] = useState<{
    value: string
    type: 'direct' | 'indirect'
  }>()

  const [isHardMode, setIsHardMode] = useState(
    localStorage.getItem('gameMode')
      ? localStorage.getItem('gameMode') === 'hard'
      : false
  )

  useEffect(() => {
    // if no game state on load,
    // show the user the how-to info modal
    if (!loadGameStateFromLocalStorage(true)) {
      trackEvent('view_tutorial', {
        props: {
          solution,
          date: gameDate?.toISOString(),
        },
      })
      setTimeout(() => {
        setIsInfoModalOpen(true)
      }, WELCOME_INFO_MODAL_MS)
    }
  })

  useEffect(() => {
    DISCOURAGE_INAPP_BROWSERS &&
      isInAppBrowser() &&
      showErrorAlert(DISCOURAGE_INAPP_BROWSER_TEXT, {
        persist: false,
        durationMs: 7000,
      })
  }, [showErrorAlert])

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }

    if (isHighContrastMode) {
      document.documentElement.classList.add('high-contrast')
    } else {
      document.documentElement.classList.remove('high-contrast')
    }
  }, [isDarkMode, isHighContrastMode])

  const handleDarkMode = (isDark: boolean) => {
    setIsDarkMode(isDark)
    localStorage.setItem('theme', isDark ? 'dark' : 'light')
  }

  const handleHardMode = (isHard: boolean) => {
    if (guesses.length === 0 || localStorage.getItem('gameMode') === 'hard') {
      setIsHardMode(isHard)
      localStorage.setItem('gameMode', isHard ? 'hard' : 'normal')
    } else {
      showErrorAlert(HARD_MODE_ALERT_MESSAGE)
    }
  }

  const handleHighContrastMode = (isHighContrast: boolean) => {
    setIsHighContrastMode(isHighContrast)
    setStoredIsHighContrastMode(isHighContrast)
  }

  const clearCurrentRowClass = () => {
    setCurrentRowClass('')
  }

  const updateCurrentGuess = useCallback(
    (value: string, index: number) => {
      console.info(`${value} at ${index}`)
      if (index > -1 && index < 5 && index + 1 <= currentGuess.length) {
        setCurrentGuess((current) => {
          return replaceCharAt(current.padEnd(index + 1, ' '), value, index)
        })
      } else if (index < 5 && currentGuess.length > index + 1) {
        onChar(value)
      }
    },
    [currentGuess]
  )

  useEffect(() => {
    saveGameStateToLocalStorage(getIsLatestGame(), { guesses, solution })
  }, [guesses])

  useEffect(() => {
    if (pendingGameCompleteEvent !== 'none') {
      if (pendingGameCompleteEvent === 'lost') {
        trackEvent('lost_game', {
          props: {
            solution,
            date: gameDate?.toISOString(),
            guesses: JSON.stringify(guesses),
          },
        })
      } else if (pendingGameCompleteEvent === 'won') {
        trackEvent('won_game', {
          props: {
            solution,
            date: gameDate?.toISOString(),
            guessCount: guesses?.length || 0,
            guesses: JSON.stringify(guesses),
          },
        })
      } else if (pendingGameCompleteEvent === 'won_again') {
        trackEvent('won_game_again', {
          props: {
            solution,
            date: gameDate?.toISOString(),
            guessCount: guesses?.length || 0,
            guesses: JSON.stringify(guesses),
            stats: JSON.stringify(stats),
          },
        })
      } else if (pendingGameCompleteEvent === 'lost_again') {
        trackEvent('lost_game_again', {
          props: {
            solution,
            date: gameDate?.toISOString(),
            guessCount: guesses?.length || 0,
            guesses: JSON.stringify(guesses),
            stats: JSON.stringify(stats),
          },
        })
      }
      setPendingGameCompleteEvent('none')
    }
  }, [pendingGameCompleteEvent])

  useEffect(() => {
    if (isGameWon) {
      const winMessage =
        WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)]
      const delayMs = REVEAL_TIME_MS * solution.length

      if (stats.totalGames > 0 && stats.gamesFailed < stats.totalGames) {
        setPendingGameCompleteEvent('won_again')
      } else {
        setPendingGameCompleteEvent('won')
      }
      showSuccessAlert(winMessage, {
        delayMs,
        onClose: () => setIsStatsModalOpen(true),
      })
    }

    if (isGameLost) {
      if (stats.totalGames > 0 && stats.gamesFailed > 0) {
        setPendingGameCompleteEvent('lost_again')
      } else {
        setPendingGameCompleteEvent('lost')
      }
      setTimeout(
        () => {
          setIsStatsModalOpen(true)
        },
        (solution.length + 1) * REVEAL_TIME_MS
      )
    }
  }, [isGameWon, isGameLost, showSuccessAlert])

  useEffect(() => {
    return monitorForElements({
      onDrop({ source, location }) {
        const destination = location.current.dropTargets[0]
        if (!destination) {
          return
        }
        console.info('location', location)
        console.info('destination', destination)
        console.info('source', source)
        const sourceKeyValue = source?.data?.value as string
        const targetCellPosition = destination.data.position as number
        const targetCellStatus = destination.data.status
        const targetCellValue = destination.data.value

        setCursor(targetCellPosition)
        updateCurrentGuess(sourceKeyValue, targetCellPosition)

        console.info(targetCellPosition)

        // if (
        //   !isCoord(destinationLocation) ||
        //   !isCoord(sourceLocation) ||
        //   !isPieceType(pieceType)
        // ) {
        //   return
        // }

        // const piece = pieces.find((p) =>
        //   isEqualCoord(p.location, sourceLocation)
        // )
        // const restOfPieces = pieces.filter((p) => p !== piece)

        // if (
        //   canMove(sourceLocation, destinationLocation, pieceType, pieces) &&
        //   piece !== undefined
        // ) {
        //   setPieces([
        //     { type: piece.type, location: destinationLocation },
        //     ...restOfPieces,
        //   ])
        // }
      },
    })
  }, [guesses])

  const onChar = useCallback(
    (value: string) => {
      console.info(
        `[onChar] w/ value ${value} and cursor position ${cursor} and current guess length ${currentGuess.length} and guess ${currentGuess}`
      )
      const newGuess = replaceCharAt(currentGuess, value, cursor)

      if (
        unicodeLength(`${newGuess}`) <= solution.length &&
        guesses.length < MAX_CHALLENGES &&
        !isGameWon
      ) {
        console.info('here')
        setCurrentGuess(newGuess)
        //setCurrentGuess(`${currentGuess}${value}`)
      }
    },
    [currentGuess, cursor, guesses, isGameWon]
  )

  const onDelete = useCallback(() => {
    if (cursor < WORD_LENGTH && cursor > -1) {
      if (currentGuess.charAt(cursor) !== EMPTY_LETTER) {
        setCurrentGuess(replaceCharAt(currentGuess, EMPTY_LETTER, cursor))
      } else if (cursor > 0) {
        setCurrentGuess(replaceCharAt(currentGuess, EMPTY_LETTER, cursor - 1))
        setCursor(cursor - 1)
      }
    } else {
      setCurrentGuess(
        new GraphemeSplitter()
          .splitGraphemes(currentGuess)
          .slice(0, -1)
          .join('')
      )
    }
  }, [currentGuess, cursor])

  const onEnter = () => {
    if (isGameWon || isGameLost) {
      return
    }

    if (!startedPlaying && stats?.totalGames <= 0 && guesses?.length <= 0) {
      trackEvent('enter_first_guess', {
        props: {
          solution,
          date: gameDate?.toISOString(),
          guessOutcome: !(unicodeLength(currentGuess) === solution.length)
            ? NOT_ENOUGH_LETTERS_MESSAGE
            : !isWordInWordList(currentGuess)
              ? WORD_NOT_FOUND_MESSAGE
              : 'Valid guess',
          guess: currentGuess,
        },
      })
      setStartedPlaying(true)
    } else {
      trackEvent('enter_guess', {
        props: {
          guess: currentGuess,
          guessOutcome: !(unicodeLength(currentGuess) === solution.length)
            ? NOT_ENOUGH_LETTERS_MESSAGE
            : !isWordInWordList(currentGuess)
              ? WORD_NOT_FOUND_MESSAGE
              : 'Valid guess',
          date: new Date().toUTCString(),
          solution,
        },
      })
    }

    if (!(unicodeLength(currentGuess) === solution.length)) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(NOT_ENOUGH_LETTERS_MESSAGE, {
        onClose: clearCurrentRowClass,
      })
    }

    if (!isWordInWordList(currentGuess)) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(WORD_NOT_FOUND_MESSAGE, {
        onClose: clearCurrentRowClass,
      })
    }

    // enforce hard mode - all guesses must contain all previously revealed letters
    if (isHardMode) {
      const firstMissingReveal = findFirstUnusedReveal(currentGuess, guesses)
      if (firstMissingReveal) {
        setCurrentRowClass('jiggle')
        return showErrorAlert(firstMissingReveal, {
          onClose: clearCurrentRowClass,
        })
      }
    }

    setIsRevealing(true)
    // turn this back off after all
    // chars have been revealed
    setTimeout(() => {
      setIsRevealing(false)
    }, REVEAL_TIME_MS * solution.length)

    const winningWord = isWinningWord(currentGuess)

    if (
      unicodeLength(currentGuess) === solution.length &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      setGuesses([...guesses, currentGuess])
      setCurrentGuess('     ')
      setCursor(0)
      setScore(score - baseGuessCost)
      // const lastGuess = guesses[guesses.length - 1]
      // const { statuses, stats: rowStats } = getGuessStatuses(
      //   solution,
      //   currentGuess
      // )
      // const { stats: lastRowStats } = getGuessStatuses(solution, lastGuess)
      // if (rowStats.directHits > lastRowStats.directHits) {
      //   setScore(
      //     score +
      //       directHitBonusModifier *
      //         (rowStats.directHits - lastRowStats.directHits)
      //   )
      //   setLastEvent({
      //     type: 'direct',
      //     value: `${rowStats.directHits - lastRowStats.directHits}X`,
      //   })
      //   showSuccessAlert(
      //     `${rowStats.directHits - lastRowStats.directHits}X Direct Combo`
      //   )
      // }

      // if (rowStats.indirectHits > lastRowStats.indirectHits) {
      //   setScore(
      //     score +
      //       indirectHitBonusModifier *
      //         (rowStats.indirectHits - lastRowStats.indirectHits)
      //   )
      //   showSuccessAlert(
      //     `${rowStats.indirectHits - lastRowStats.indirectHits}X Indirect Combo`
      //   )
      //   setLastEvent({
      //     type: 'indirect',
      //     value: `${rowStats.indirectHits - lastRowStats.indirectHits}X`,
      //   })
      // }

      // if (rowStats.indirectHits > lastRowStats.indirectHits) {
      //   setScore
      // }

      if (winningWord) {
        if (isLatestGame) {
          setStats(addStatsForCompletedGame(stats, guesses.length))
        }
        return setIsGameWon(true)
      }

      if (guesses.length === MAX_CHALLENGES - 1 || score < 25) {
        if (isLatestGame) {
          setStats(addStatsForCompletedGame(stats, guesses.length + 1))
        }
        setIsGameLost(true)
        showErrorAlert(CORRECT_WORD_MESSAGE(solution), {
          persist: true,
          delayMs: REVEAL_TIME_MS * solution.length + 1,
        })
      }
    }
  }

  return (
    <>
      <Div100vh>
        <div className="flex h-full flex-col bg-gradient-to-br from-gray-900 to-gray-950 ">
          <GameBoardProvider
            currentGuess={currentGuess}
            setCurrentGuess={setCurrentGuess}
            gameState={{ guesses, solution }}
            setCursor={setCursor}
            cursor={cursor}
          >
            <Navbar
              score={score}
              lastModifier={lastEvent}
              setIsInfoModalOpen={setIsInfoModalOpen}
              setIsStatsModalOpen={setIsStatsModalOpen}
              setIsDatePickerModalOpen={setIsDatePickerModalOpen}
              setIsSettingsModalOpen={setIsSettingsModalOpen}
            />

            {/* Optional Clock and Date Display */}
            {!isLatestGame && (
              <div className="flex items-center justify-center bg-white py-2 dark:bg-gray-800">
                <ClockIcon className="mr-2 h-6 w-6 stroke-gray-600 dark:stroke-gray-300" />
                <p className="text-base text-gray-600 dark:text-gray-300">
                  {format(gameDate, 'd MMMM yyyy', { locale: DATE_LOCALE })}
                </p>
              </div>
            )}

            {/* Main Content: Scrollable Grid */}
            <div className="flex-1 overflow-auto">
              <div className="mx-auto flex w-full flex-col px-4 sm:px-6 md:max-w-7xl lg:px-8">
                <div className="flex flex-col justify-center py-6">
                  <Grid
                    solution={solution}
                    guesses={guesses}
                    currentGuess={currentGuess}
                    isRevealing={isRevealing}
                    currentRowClassName={currentRowClass}
                    isGameOver={isGameWon || isGameLost}
                  />
                </div>
              </div>
            </div>

            {/* Keyboard */}
            <div className="pb-5 sm:flex sm:w-full sm:justify-center">
              <Keyboard
                className="shrink-0"
                onChar={onChar}
                onDelete={onDelete}
                onEnter={onEnter}
                solution={solution}
                guesses={guesses}
                isRevealing={isRevealing}
              />
            </div>

            <InfoModal
              isOpen={isInfoModalOpen}
              handleClose={() => setIsInfoModalOpen(false)}
            />
            <StatsModal
              isOpen={isStatsModalOpen}
              handleClose={() => setIsStatsModalOpen(false)}
              solution={solution}
              guesses={guesses}
              gameStats={stats}
              isLatestGame={isLatestGame}
              isGameLost={isGameLost}
              isGameWon={isGameWon}
              handleShareToClipboard={() =>
                showSuccessAlert(GAME_COPIED_MESSAGE)
              }
              handleShareFailure={() =>
                showErrorAlert(SHARE_FAILURE_TEXT, {
                  durationMs: LONG_ALERT_TIME_MS,
                })
              }
              handleMigrateStatsButton={() => {
                setIsStatsModalOpen(false)
                setIsMigrateStatsModalOpen(true)
              }}
              isHardMode={isHardMode}
              isDarkMode={isDarkMode}
              isHighContrastMode={isHighContrastMode}
              numberOfGuessesMade={guesses.length}
            />
            <DatePickerModal
              isOpen={isDatePickerModalOpen}
              initialDate={solutionGameDate}
              handleSelectDate={(d) => {
                setIsDatePickerModalOpen(false)
                setGameDate(d)
              }}
              handleClose={() => setIsDatePickerModalOpen(false)}
            />
            <MigrateStatsModal
              isOpen={isMigrateStatsModalOpen}
              handleClose={() => setIsMigrateStatsModalOpen(false)}
            />
            <SettingsModal
              isOpen={isSettingsModalOpen}
              handleClose={() => setIsSettingsModalOpen(false)}
              isHardMode={isHardMode}
              handleHardMode={handleHardMode}
              isDarkMode={isDarkMode}
              handleDarkMode={handleDarkMode}
              isHighContrastMode={isHighContrastMode}
              handleHighContrastMode={handleHighContrastMode}
            />

            <AlertContainer />
          </GameBoardProvider>
        </div>
      </Div100vh>
    </>
  )
}

export default App
