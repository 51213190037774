'use client'

import { Paintbrush } from 'lucide-react'
import { ReactNode, useCallback, useMemo, useState } from 'react'

import { useGameBoard } from '../../context/GameBoardContext'
import { useBreakpoint } from '../../hooks/use-breakpoint'
import { colors } from '../../lib/colors.light'
import { chunkArray, cn, isNullish, takeLastItems } from '../../lib/utils'
import { Button } from '../ui/button'
import { Drawer } from '../ui/drawer'
import { Input } from '../ui/input'
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover'
import { ScrollArea, ScrollBar } from '../ui/scroll-area'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../ui/tabs'
import If from './if'
import { Modal } from './modal'

export function PickerExample() {
  const [background, setBackground] = useState('#B4D455')

  return (
    <div
      className="preview flex h-full min-h-[350px] w-full items-center justify-center rounded !bg-cover !bg-center p-10 transition-all"
      style={{ background }}
    >
      {/* <GradientPicker background={background} setBackground={setBackground} /> */}
    </div>
  )
}

export function GradientPicker({
  background,
  setBackground,
  className,
  open,
  setIsOpen,
  children,
}: {
  background?: string
  setBackground?: (background: string) => void
  className?: string
  open?: boolean
  setIsOpen?: (v: any) => void
  children?: ReactNode
}) {
  const { annotateKey, annotatedKeys, keyInFocus } = useGameBoard()
  const [selection, setSelection] = useState(background ?? '')
  const handleAnnotation = (color: string) => {
    if (setBackground) {
      setBackground(color)
    }
    setSelection(color)
    if (setIsOpen) {
      setIsOpen(false)
    }
  }
  const softSolids = [
    '#E2E2E2',
    '#ff75c3',
    '#ffa647',
    '#ffe83f',
    '#9fff5b',
    '#70e2ff',
    '#cd93ff',
    '#09203f',
  ]
  const solids = [
    ...Object.entries(colors).map(([key, value]) => value),
    // Object.entries([key, color] => color!)
  ]
  const chunkedSolids = chunkArray(solids, 12)

  const gradientsExt = [
    'linear-gradient(to top left,#accbee,#e7f0fd)',
    'linear-gradient(to top left,#d5d4d0,#d5d4d0,#eeeeec)',
    'linear-gradient(to top left,#000000,#434343)',
    'linear-gradient(to top left,#09203f,#537895)',
    'linear-gradient(to top left,#AC32E4,#7918F2,#4801FF)',
    'linear-gradient(to top left,#f953c6,#b91d73)',
    'linear-gradient(to top left,#ee0979,#ff6a00)',
    'linear-gradient(to top left,#F00000,#DC281E)',
    'linear-gradient(to top left,#00c6ff,#0072ff)',
    'linear-gradient(to top left,#4facfe,#00f2fe)',
    'linear-gradient(to top left,#0ba360,#3cba92)',
    'linear-gradient(to top left,#FDFC47,#24FE41)',
    'linear-gradient(to top left,#8a2be2,#0000cd,#228b22,#ccff00)',
    'linear-gradient(to top left,#40E0D0,#FF8C00,#FF0080)',
    'linear-gradient(to top left,#fcc5e4,#fda34b,#ff7882,#c8699e,#7046aa,#0c1db8,#020f75)',
    'linear-gradient(to top left,#ff75c3,#ffa647,#ffe83f,#9fff5b,#70e2ff,#cd93ff)',
    'linear-gradient(to left top, rgb(9, 30, 58), rgb(47, 128, 237), rgb(45, 158, 224))',
    'linear-gradient(to left top, rgb(148, 0, 211), rgb(75, 0, 130))',
    'linear-gradient(to left top, rgb(200, 78, 137), rgb(241, 95, 121))',
    'linear-gradient(to left top, rgb(0, 245, 160), rgb(0, 217, 245))',
    'linear-gradient(to left top, rgb(247, 148, 30), rgb(114, 198, 239), rgb(0, 166, 81))',
    'linear-gradient(to left top, rgb(247, 148, 30), rgb(0, 78, 143))',
    'linear-gradient(to left top, rgb(114, 198, 239), rgb(0, 78, 143))',
    'linear-gradient(to left top, rgb(253, 129, 18), rgb(0, 133, 202))',
    'linear-gradient(to left top, rgb(191, 90, 224), rgb(168, 17, 218))',
    'linear-gradient(to left top, rgb(251, 237, 150), rgb(171, 236, 214))',
    'linear-gradient(to left top, rgb(255, 224, 0), rgb(121, 159, 12))',
    'linear-gradient(to left top, rgb(247, 248, 248), rgb(172, 187, 120))',
    'linear-gradient(to left top, rgb(0, 65, 106), rgb(121, 159, 12), rgb(255, 224, 0))',
    'linear-gradient(to left top, rgb(51, 77, 80), rgb(203, 202, 165))',
    'linear-gradient(to left top, rgb(0, 82, 212), rgb(67, 100, 247), rgb(111, 177, 252))',
    'linear-gradient(to left top, rgb(84, 51, 255), rgb(32, 189, 255), rgb(165, 254, 203))',
    'linear-gradient(to left top, rgb(121, 159, 12), rgb(172, 187, 120))',
    'linear-gradient(to left top, rgb(255, 226, 89), rgb(255, 167, 81))',
    'linear-gradient(to left top, rgb(172, 182, 229), rgb(134, 253, 232))',
    'linear-gradient(to left top, rgb(83, 105, 118), rgb(41, 46, 73))',
    'linear-gradient(to left top, rgb(183, 152, 145), rgb(148, 113, 107))',
    'linear-gradient(to left top, rgb(151, 150, 240), rgb(251, 199, 212))',
    'linear-gradient(to left top, rgb(187, 210, 197), rgb(83, 105, 118))',
    'linear-gradient(to left top, rgb(7, 101, 133), rgb(255, 255, 255))',
    'linear-gradient(to left top, rgb(0, 70, 127), rgb(165, 204, 130))',
    'linear-gradient(to left top, rgb(0, 12, 64), rgb(96, 125, 139))',
    'linear-gradient(to left top, rgb(20, 136, 204), rgb(43, 50, 178))',
    'linear-gradient(to left top, rgb(236, 0, 140), rgb(252, 103, 103))',
    'linear-gradient(to left top, rgb(204, 43, 94), rgb(117, 58, 136))',
    'linear-gradient(to left top, rgb(230, 92, 0), rgb(249, 212, 35))',
    'linear-gradient(to left top, rgb(43, 88, 118), rgb(78, 67, 118))',
    'linear-gradient(to left top, rgb(49, 71, 85), rgb(38, 160, 218))',
    'linear-gradient(to left top, rgb(119, 161, 211), rgb(121, 203, 202), rgb(230, 132, 174))',
    'linear-gradient(to left top, rgb(255, 110, 127), rgb(191, 233, 255))',
    'linear-gradient(to left top, rgb(229, 45, 39), rgb(179, 18, 23))',
    'linear-gradient(to left top, rgb(96, 56, 19), rgb(178, 159, 148))',
    'linear-gradient(to left top, rgb(22, 160, 133), rgb(244, 208, 63))',
    'linear-gradient(to left top, rgb(211, 16, 39), rgb(234, 56, 77))',
    'linear-gradient(to left top, rgb(237, 229, 116), rgb(225, 245, 196))',
    'linear-gradient(to left top, rgb(2, 170, 176), rgb(0, 205, 172))',
    'linear-gradient(to left top, rgb(218, 34, 255), rgb(151, 51, 238))',
    'linear-gradient(to left top, rgb(52, 143, 80), rgb(86, 180, 211))',
    'linear-gradient(to left top, rgb(60, 165, 92), rgb(181, 172, 73))',
    'linear-gradient(to left top, rgb(204, 149, 192), rgb(219, 212, 180), rgb(122, 161, 210))',
    'linear-gradient(to left top, rgb(0, 57, 115), rgb(229, 229, 190))',
    'linear-gradient(to left top, rgb(229, 93, 135), rgb(95, 195, 228))',
    'linear-gradient(to left top, rgb(64, 59, 74), rgb(231, 233, 187))',
    'linear-gradient(to left top, rgb(240, 152, 25), rgb(237, 222, 93))',
    'linear-gradient(to left top, rgb(255, 81, 47), rgb(221, 36, 118))',
    'linear-gradient(to left top, rgb(170, 7, 107), rgb(97, 4, 95))',
    'linear-gradient(to left top, rgb(26, 41, 128), rgb(38, 208, 206))',
    'linear-gradient(to left top, rgb(255, 81, 47), rgb(240, 152, 25))',
    'linear-gradient(to left top, rgb(29, 43, 100), rgb(248, 205, 218))',
    'linear-gradient(to left top, rgb(31, 162, 255), rgb(18, 216, 250), rgb(166, 255, 203))',
    'linear-gradient(to left top, rgb(76, 184, 196), rgb(60, 211, 173))',
    'linear-gradient(to left top, rgb(221, 94, 137), rgb(247, 187, 151))',
    'linear-gradient(to left top, rgb(235, 51, 73), rgb(244, 92, 67))',
    'linear-gradient(to left top, rgb(29, 151, 108), rgb(147, 249, 185))',
    'linear-gradient(to left top, rgb(255, 128, 8), rgb(255, 200, 55))',
    'linear-gradient(to left top, rgb(22, 34, 42), rgb(58, 96, 115))',
    'linear-gradient(to left top, rgb(31, 28, 44), rgb(146, 141, 171))',
    'linear-gradient(to left top, rgb(97, 67, 133), rgb(81, 99, 149))',
    'linear-gradient(to left top, rgb(71, 118, 230), rgb(142, 84, 233))',
    'linear-gradient(to left top, rgb(8, 80, 120), rgb(133, 216, 206))',
    'linear-gradient(to left top, rgb(43, 192, 228), rgb(234, 236, 198))',
    'linear-gradient(to left top, rgb(19, 78, 94), rgb(113, 178, 128))',
    'linear-gradient(to left top, rgb(92, 37, 141), rgb(67, 137, 162))',
    'linear-gradient(to left top, rgb(117, 127, 154), rgb(215, 221, 232))',
    'linear-gradient(to left top, rgb(35, 37, 38), rgb(65, 67, 69))',
    'linear-gradient(to left top, rgb(28, 216, 210), rgb(147, 237, 199))',
    'linear-gradient(to left top, rgb(61, 126, 170), rgb(255, 228, 122))',
    'linear-gradient(to left top, rgb(40, 48, 72), rgb(133, 147, 152))',
    'linear-gradient(to left top, rgb(36, 198, 220), rgb(81, 74, 157))',
    'linear-gradient(to left top, rgb(220, 36, 36), rgb(74, 86, 157))',
    'linear-gradient(to left top, rgb(237, 66, 100), rgb(255, 237, 188))',
    'linear-gradient(to left top, rgb(218, 226, 248), rgb(214, 164, 164))',
    'linear-gradient(to left top, rgb(236, 233, 230), rgb(255, 255, 255))',
    'linear-gradient(to left top, rgb(116, 116, 191), rgb(52, 138, 199))',
    'linear-gradient(to left top, rgb(236, 111, 102), rgb(243, 161, 131))',
    'linear-gradient(to left top, rgb(95, 44, 130), rgb(73, 160, 157))',
    'linear-gradient(to left top, rgb(192, 72, 72), rgb(72, 0, 72))',
    'linear-gradient(to left top, rgb(228, 58, 21), rgb(230, 82, 69))',
    'linear-gradient(to left top, rgb(65, 77, 11), rgb(114, 122, 23))',
    'linear-gradient(to left top, rgb(252, 53, 76), rgb(10, 191, 188))',
    'linear-gradient(to left top, rgb(75, 108, 183), rgb(24, 40, 72))',
    'linear-gradient(to left top, rgb(248, 87, 166), rgb(255, 88, 88))',
    'linear-gradient(to left top, rgb(167, 55, 55), rgb(122, 40, 40))',
    'linear-gradient(to left top, rgb(213, 51, 105), rgb(203, 173, 109))',
    'linear-gradient(to left top, rgb(233, 211, 98), rgb(51, 51, 51))',
    'linear-gradient(to left top, rgb(222, 98, 98), rgb(255, 184, 140))',
    'linear-gradient(to left top, rgb(102, 102, 0), rgb(153, 153, 102))',
    'linear-gradient(to left top, rgb(255, 238, 238), rgb(221, 239, 187))',
    'linear-gradient(to left top, rgb(239, 239, 187), rgb(212, 211, 221))',
    'linear-gradient(to left top, rgb(194, 21, 0), rgb(255, 197, 0))',
    'linear-gradient(to left top, rgb(33, 95, 0), rgb(228, 228, 217))',
    'linear-gradient(to left top, rgb(80, 201, 195), rgb(150, 222, 218))',
    'linear-gradient(to left top, rgb(221, 214, 243), rgb(250, 172, 168))',
    'linear-gradient(to left top, rgb(93, 65, 87), rgb(168, 202, 186))',
    'linear-gradient(to left top, rgb(230, 218, 218), rgb(39, 64, 70))',
    'linear-gradient(to left top, rgb(242, 112, 156), rgb(255, 148, 114))',
    'linear-gradient(to left top, rgb(218, 210, 153), rgb(176, 218, 185))',
    'linear-gradient(to left top, rgb(211, 149, 155), rgb(191, 230, 186))',
    'linear-gradient(to left top, rgb(0, 210, 255), rgb(58, 123, 213))',
    'linear-gradient(to left top, rgb(135, 0, 0), rgb(25, 10, 5))',
    'linear-gradient(to left top, rgb(185, 147, 214), rgb(140, 166, 219))',
    'linear-gradient(to left top, rgb(100, 145, 115), rgb(219, 213, 164))',
    'linear-gradient(to left top, rgb(201, 255, 191), rgb(255, 175, 189))',
    'linear-gradient(to left top, rgb(96, 108, 136), rgb(63, 76, 107))',
    'linear-gradient(to left top, rgb(251, 211, 233), rgb(187, 55, 125))',
    'linear-gradient(to left top, rgb(173, 209, 0), rgb(123, 146, 10))',
    'linear-gradient(to left top, rgb(255, 78, 80), rgb(249, 212, 35))',
    'linear-gradient(to left top, rgb(240, 194, 123), rgb(75, 18, 72))',
    'linear-gradient(to left top, rgb(0, 0, 0), rgb(231, 76, 60))',
    'linear-gradient(to left top, rgb(170, 255, 169), rgb(17, 255, 189))',
    'linear-gradient(to left top, rgb(179, 255, 171), rgb(18, 255, 247))',
    'linear-gradient(to left top, rgb(120, 2, 6), rgb(6, 17, 97))',
    'linear-gradient(to left top, rgb(157, 80, 187), rgb(110, 72, 170))',
    'linear-gradient(to left top, rgb(85, 98, 112), rgb(255, 107, 107))',
    'linear-gradient(to left top, rgb(112, 225, 245), rgb(255, 209, 148))',
    'linear-gradient(to left top, rgb(0, 198, 255), rgb(0, 114, 255))',
    'linear-gradient(to left top, rgb(254, 140, 0), rgb(248, 54, 0))',
    'linear-gradient(to left top, rgb(82, 194, 52), rgb(6, 23, 0))',
    'linear-gradient(to left top, rgb(72, 85, 99), rgb(41, 50, 60))',
    'linear-gradient(to left top, rgb(131, 164, 212), rgb(182, 251, 255))',
    'linear-gradient(to left top, rgb(253, 252, 71), rgb(36, 254, 65))',
    'linear-gradient(to left top, rgb(171, 186, 171), rgb(255, 255, 255))',
    'linear-gradient(to left top, rgb(115, 200, 169), rgb(55, 59, 68))',
    'linear-gradient(to left top, rgb(211, 131, 18), rgb(168, 50, 121))',
    'linear-gradient(to left top, rgb(30, 19, 12), rgb(154, 132, 120))',
    'linear-gradient(to left top, rgb(148, 142, 153), rgb(46, 20, 55))',
    'linear-gradient(to left top, rgb(54, 0, 51), rgb(11, 135, 147))',
    'linear-gradient(to left top, rgb(255, 161, 127), rgb(0, 34, 62))',
    'linear-gradient(to left top, rgb(67, 206, 162), rgb(24, 90, 157))',
    'linear-gradient(to left top, rgb(255, 179, 71), rgb(255, 204, 51))',
    'linear-gradient(to left top, rgb(100, 65, 165), rgb(42, 8, 69))',
    'linear-gradient(to left top, rgb(254, 172, 94), rgb(199, 121, 208), rgb(75, 192, 200))',
    'linear-gradient(to left top, rgb(131, 58, 180), rgb(253, 29, 29), rgb(252, 176, 69))',
    'linear-gradient(to left top, rgb(255, 0, 132), rgb(51, 0, 27))',
    'linear-gradient(to left top, rgb(0, 191, 143), rgb(0, 21, 16))',
    'linear-gradient(to left top, rgb(19, 106, 138), rgb(38, 120, 113))',
    'linear-gradient(to left top, rgb(142, 158, 171), rgb(238, 242, 243))',
    'linear-gradient(to left top, rgb(123, 67, 151), rgb(220, 36, 48))',
    'linear-gradient(to left top, rgb(209, 145, 60), rgb(255, 209, 148))',
    'linear-gradient(to left top, rgb(241, 242, 181), rgb(19, 80, 88))',
    'linear-gradient(to left top, rgb(106, 145, 19), rgb(20, 21, 23))',
    'linear-gradient(to left top, rgb(0, 79, 249), rgb(255, 249, 76))',
    'linear-gradient(to left top, rgb(82, 82, 82), rgb(61, 114, 180))',
    'linear-gradient(to left top, rgb(186, 139, 2), rgb(24, 24, 24))',
    'linear-gradient(to left top, rgb(48, 67, 82), rgb(215, 210, 204))',
    'linear-gradient(to left top, rgb(204, 204, 178), rgb(117, 117, 25))',
    'linear-gradient(to left top, rgb(44, 62, 80), rgb(52, 152, 219))',
    'linear-gradient(to left top, rgb(252, 0, 255), rgb(0, 219, 222))',
    'linear-gradient(to left top, rgb(229, 57, 53), rgb(227, 93, 91))',
    'linear-gradient(to left top, rgb(0, 92, 151), rgb(54, 55, 149))',
    'linear-gradient(to left top, rgb(244, 107, 69), rgb(238, 168, 73))',
    'linear-gradient(to left top, rgb(0, 201, 255), rgb(146, 254, 157))',
    'linear-gradient(to left top, rgb(103, 58, 183), rgb(81, 45, 168))',
    'linear-gradient(to left top, rgb(118, 184, 82), rgb(141, 194, 111))',
    'linear-gradient(to left top, rgb(142, 14, 0), rgb(31, 28, 24))',
    'linear-gradient(to left top, rgb(255, 183, 94), rgb(237, 143, 3))',
    'linear-gradient(to left top, rgb(194, 229, 156), rgb(100, 179, 244))',
    'linear-gradient(to left top, rgb(64, 58, 62), rgb(190, 88, 105))',
    'linear-gradient(to left top, rgb(192, 36, 37), rgb(240, 203, 53))',
    'linear-gradient(to left top, rgb(178, 69, 146), rgb(241, 95, 121))',
    'linear-gradient(to left top, rgb(69, 127, 202), rgb(86, 145, 200))',
    'linear-gradient(to left top, rgb(106, 48, 147), rgb(160, 68, 255))',
    'linear-gradient(to left top, rgb(234, 205, 163), rgb(214, 174, 123))',
    'linear-gradient(to left top, rgb(253, 116, 108), rgb(255, 144, 104))',
    'linear-gradient(to left top, rgb(17, 67, 87), rgb(242, 148, 146))',
    'linear-gradient(to left top, rgb(30, 60, 114), rgb(42, 82, 152))',
    'linear-gradient(to left top, rgb(47, 115, 54), rgb(170, 58, 56))',
    'linear-gradient(to left top, rgb(86, 20, 176), rgb(219, 214, 92))',
    'linear-gradient(to left top, rgb(77, 160, 176), rgb(211, 157, 56))',
    'linear-gradient(to left top, rgb(90, 63, 55), rgb(44, 119, 68))',
    'linear-gradient(to left top, rgb(41, 128, 185), rgb(44, 62, 80))',
    'linear-gradient(to left top, rgb(0, 153, 247), rgb(241, 23, 18))',
    'linear-gradient(to left top, rgb(131, 77, 155), rgb(208, 78, 214))',
    'linear-gradient(to left top, rgb(75, 121, 161), rgb(40, 62, 81))',
    'linear-gradient(to left top, rgb(0, 0, 0), rgb(67, 67, 67))',
    'linear-gradient(to left top, rgb(76, 161, 175), rgb(196, 224, 229))',
    'linear-gradient(to left top, rgb(224, 234, 252), rgb(207, 222, 243))',
    'linear-gradient(to left top, rgb(186, 83, 112), rgb(244, 226, 216))',
    'linear-gradient(to left top, rgb(255, 75, 31), rgb(31, 221, 255))',
    'linear-gradient(to left top, rgb(247, 255, 0), rgb(219, 54, 164))',
    'linear-gradient(to left top, rgb(168, 0, 119), rgb(102, 255, 0))',
    'linear-gradient(to left top, rgb(29, 67, 80), rgb(164, 57, 49))',
    'linear-gradient(to left top, rgb(238, 205, 163), rgb(239, 98, 159))',
    'linear-gradient(to left top, rgb(22, 191, 253), rgb(203, 48, 102))',
    'linear-gradient(to left top, rgb(255, 75, 31), rgb(255, 144, 104))',
    'linear-gradient(to left top, rgb(255, 95, 109), rgb(255, 195, 113))',
    'linear-gradient(to left top, rgb(33, 150, 243), rgb(244, 67, 54))',
    'linear-gradient(to left top, rgb(0, 210, 255), rgb(146, 141, 171))',
    'linear-gradient(to left top, rgb(58, 123, 213), rgb(58, 96, 115))',
    'linear-gradient(to left top, rgb(11, 72, 107), rgb(245, 98, 23))',
    'linear-gradient(to left top, rgb(233, 100, 67), rgb(144, 78, 149))',
    'linear-gradient(to left top, rgb(44, 62, 80), rgb(76, 161, 175))',
    'linear-gradient(to left top, rgb(44, 62, 80), rgb(253, 116, 108))',
    'linear-gradient(to left top, rgb(240, 0, 0), rgb(220, 40, 30))',
    'linear-gradient(to left top, rgb(20, 30, 48), rgb(36, 59, 85))',
    'linear-gradient(to left top, rgb(66, 39, 90), rgb(115, 75, 109))',
    'linear-gradient(to left top, rgb(0, 4, 40), rgb(0, 78, 146))',
    'linear-gradient(to left top, rgb(86, 171, 47), rgb(168, 224, 99))',
    'linear-gradient(to left top, rgb(203, 45, 62), rgb(239, 71, 58))',
    'linear-gradient(to left top, rgb(247, 157, 0), rgb(100, 243, 140))',
    'linear-gradient(to left top, rgb(248, 80, 50), rgb(231, 56, 39))',
    'linear-gradient(to left top, rgb(252, 234, 187), rgb(248, 181, 0))',
    'linear-gradient(to left top, rgb(128, 128, 128), rgb(63, 173, 168))',
    'linear-gradient(to left top, rgb(255, 216, 155), rgb(25, 84, 123))',
    'linear-gradient(to left top, rgb(189, 195, 199), rgb(44, 62, 80))',
    'linear-gradient(to left top, rgb(190, 147, 197), rgb(123, 198, 204))',
    'linear-gradient(to left top, rgb(161, 255, 206), rgb(250, 255, 209))',
    'linear-gradient(to left top, rgb(78, 205, 196), rgb(85, 98, 112))',
    'linear-gradient(to left top, rgb(58, 97, 134), rgb(137, 37, 62))',
    'linear-gradient(to left top, rgb(239, 50, 217), rgb(137, 255, 253))',
    'linear-gradient(to left top, rgb(222, 97, 97), rgb(38, 87, 235))',
    'linear-gradient(to left top, rgb(255, 0, 204), rgb(51, 51, 153))',
    'linear-gradient(to left top, rgb(255, 252, 0), rgb(255, 255, 255))',
    'linear-gradient(to left top, rgb(255, 126, 95), rgb(254, 180, 123))',
    'linear-gradient(to left top, rgb(0, 195, 255), rgb(255, 255, 28))',
    'linear-gradient(to left top, rgb(244, 196, 243), rgb(252, 103, 250))',
    'linear-gradient(to left top, rgb(65, 41, 90), rgb(47, 7, 67))',
    'linear-gradient(to left top, rgb(167, 112, 239), rgb(207, 139, 243), rgb(253, 185, 155))',
    'linear-gradient(to left top, rgb(238, 9, 121), rgb(255, 106, 0))',
    'linear-gradient(to left top, rgb(243, 144, 79), rgb(59, 67, 113))',
    'linear-gradient(to left top, rgb(103, 178, 111), rgb(76, 162, 205))',
    'linear-gradient(to left top, rgb(52, 148, 230), rgb(236, 110, 173))',
    'linear-gradient(to left top, rgb(219, 230, 246), rgb(197, 121, 109))',
    'linear-gradient(to left top, rgb(156, 236, 251), rgb(101, 199, 247), rgb(0, 82, 212))',
    'linear-gradient(to left top, rgb(192, 192, 170), rgb(28, 239, 255))',
    'linear-gradient(to left top, rgb(220, 227, 91), rgb(69, 182, 73))',
    'linear-gradient(to left top, rgb(232, 203, 192), rgb(99, 111, 164))',
    'linear-gradient(to left top, rgb(240, 242, 240), rgb(0, 12, 64))',
    'linear-gradient(to left top, rgb(255, 175, 189), rgb(255, 195, 160))',
    'linear-gradient(to left top, rgb(67, 198, 172), rgb(248, 255, 174))',
    'linear-gradient(to left top, rgb(9, 48, 40), rgb(35, 122, 87))',
    'linear-gradient(to left top, rgb(67, 198, 172), rgb(25, 22, 84))',
    'linear-gradient(to left top, rgb(69, 104, 220), rgb(176, 106, 179))',
    'linear-gradient(to left top, rgb(5, 117, 230), rgb(2, 27, 121))',
    'linear-gradient(to left top, rgb(32, 1, 34), rgb(111, 0, 0))',
    'linear-gradient(to left top, rgb(68, 160, 141), rgb(9, 54, 55))',
    'linear-gradient(to left top, rgb(97, 144, 232), rgb(167, 191, 232))',
    'linear-gradient(to left top, rgb(52, 232, 158), rgb(15, 52, 67))',
    'linear-gradient(to left top, rgb(247, 151, 30), rgb(255, 210, 0))',
    'linear-gradient(to left top, rgb(195, 55, 100), rgb(29, 38, 113))',
    'linear-gradient(to left top, rgb(32, 0, 44), rgb(203, 180, 212))',
    'linear-gradient(to left top, rgb(214, 109, 117), rgb(226, 149, 135))',
    'linear-gradient(to left top, rgb(48, 232, 191), rgb(255, 130, 53))',
    'linear-gradient(to left top, rgb(178, 254, 250), rgb(14, 210, 247))',
    'linear-gradient(to left top, rgb(74, 194, 154), rgb(189, 255, 243))',
    'linear-gradient(to left top, rgb(228, 77, 38), rgb(241, 101, 41))',
    'linear-gradient(to left top, rgb(235, 87, 87), rgb(0, 0, 0))',
    'linear-gradient(to left top, rgb(242, 153, 74), rgb(242, 201, 76))',
    'linear-gradient(to left top, rgb(86, 204, 242), rgb(47, 128, 237))',
    'linear-gradient(to left top, rgb(0, 121, 145), rgb(120, 255, 214))',
    'linear-gradient(to left top, rgb(0, 0, 70), rgb(28, 181, 224))',
    'linear-gradient(to left top, rgb(21, 153, 87), rgb(21, 87, 153))',
    'linear-gradient(to left top, rgb(192, 57, 43), rgb(142, 68, 173))',
    'linear-gradient(to left top, rgb(239, 59, 54), rgb(255, 255, 255))',
    'linear-gradient(to left top, rgb(40, 60, 134), rgb(69, 162, 71))',
    'linear-gradient(to left top, rgb(58, 28, 113), rgb(215, 109, 119), rgb(255, 175, 123))',
    'linear-gradient(to left top, rgb(203, 53, 107), rgb(189, 63, 50))',
    'linear-gradient(to left top, rgb(54, 209, 220), rgb(91, 134, 229))',
    'linear-gradient(to left top, rgb(0, 0, 0), rgb(15, 155, 15))',
    'linear-gradient(to left top, rgb(28, 146, 210), rgb(242, 252, 254))',
    'linear-gradient(to left top, rgb(100, 43, 115), rgb(198, 66, 110))',
    'linear-gradient(to left top, rgb(6, 190, 182), rgb(72, 177, 191))',
    'linear-gradient(to left top, rgb(12, 235, 235), rgb(32, 227, 178), rgb(41, 255, 198))',
    'linear-gradient(to left top, rgb(217, 167, 199), rgb(255, 252, 220))',
    'linear-gradient(to left top, rgb(57, 106, 252), rgb(41, 72, 255))',
    'linear-gradient(to left top, rgb(201, 214, 255), rgb(226, 226, 226))',
    'linear-gradient(to left top, rgb(127, 0, 255), rgb(225, 0, 255))',
    'linear-gradient(to left top, rgb(255, 153, 102), rgb(255, 94, 98))',
    'linear-gradient(to left top, rgb(34, 193, 195), rgb(253, 187, 45))',
    'linear-gradient(to left top, rgb(26, 42, 108), rgb(178, 31, 31), rgb(253, 187, 45))',
    'linear-gradient(to left top, rgb(225, 238, 195), rgb(240, 80, 83))',
    'linear-gradient(to left top, rgb(173, 169, 150), rgb(242, 242, 242), rgb(219, 219, 219), rgb(234, 234, 234))',
    'linear-gradient(to left top, rgb(102, 125, 182), rgb(0, 130, 200), rgb(0, 130, 200), rgb(102, 125, 182))',
    'linear-gradient(to left top, rgb(3, 0, 30), rgb(115, 3, 192), rgb(236, 56, 188), rgb(253, 239, 249))',
    'linear-gradient(to left top, rgb(109, 96, 39), rgb(211, 203, 184))',
    'linear-gradient(to left top, rgb(116, 235, 213), rgb(172, 182, 229))',
    'linear-gradient(to left top, rgb(252, 74, 26), rgb(247, 183, 51))',
    'linear-gradient(to left top, rgb(0, 242, 96), rgb(5, 117, 230))',
    'linear-gradient(to left top, rgb(128, 0, 128), rgb(255, 192, 203))',
    'linear-gradient(to left top, rgb(202, 197, 49), rgb(243, 249, 167))',
    'linear-gradient(to left top, rgb(60, 59, 63), rgb(96, 92, 60))',
    'linear-gradient(to left top, rgb(211, 204, 227), rgb(233, 228, 240))',
    'linear-gradient(to left top, rgb(0, 176, 155), rgb(150, 201, 61))',
    'linear-gradient(to left top, rgb(15, 12, 41), rgb(48, 43, 99), rgb(36, 36, 62))',
    'linear-gradient(to left top, rgb(255, 251, 213), rgb(178, 10, 44))',
    'linear-gradient(to left top, rgb(35, 7, 77), rgb(204, 83, 51))',
    'linear-gradient(to left top, rgb(201, 75, 75), rgb(75, 19, 79))',
    'linear-gradient(to left top, rgb(252, 70, 107), rgb(63, 94, 251))',
    'linear-gradient(to left top, rgb(252, 92, 125), rgb(106, 130, 251))',
    'linear-gradient(to left top, rgb(16, 141, 199), rgb(239, 142, 56))',
    'linear-gradient(to left top, rgb(17, 153, 142), rgb(56, 239, 125))',
    'linear-gradient(to left top, rgb(62, 81, 81), rgb(222, 203, 164))',
    'linear-gradient(to left top, rgb(64, 224, 208), rgb(255, 140, 0), rgb(255, 0, 128))',
    'linear-gradient(to left top, rgb(188, 78, 156), rgb(248, 7, 89))',
    'linear-gradient(to left top, rgb(53, 92, 125), rgb(108, 91, 123), rgb(192, 108, 132))',
    'linear-gradient(to left top, rgb(78, 84, 200), rgb(143, 148, 251))',
    'linear-gradient(to left top, rgb(51, 51, 51), rgb(221, 24, 24))',
    'linear-gradient(to left top, rgb(168, 192, 255), rgb(63, 43, 150))',
    'linear-gradient(to left top, rgb(173, 83, 137), rgb(60, 16, 83))',
    'linear-gradient(to left top, rgb(99, 99, 99), rgb(162, 171, 88))',
    'linear-gradient(to left top, rgb(218, 68, 83), rgb(137, 33, 107))',
    'linear-gradient(to left top, rgb(0, 90, 167), rgb(255, 253, 228))',
    'linear-gradient(to left top, rgb(89, 193, 115), rgb(161, 127, 224), rgb(93, 38, 193))',
    'linear-gradient(to left top, rgb(255, 239, 186), rgb(255, 255, 255))',
    'linear-gradient(to left top, rgb(0, 180, 219), rgb(0, 131, 176))',
    'linear-gradient(to left top, rgb(253, 200, 48), rgb(243, 115, 53))',
    'linear-gradient(to left top, rgb(237, 33, 58), rgb(147, 41, 30))',
    'linear-gradient(to left top, rgb(30, 150, 0), rgb(255, 242, 0), rgb(255, 0, 0))',
    'linear-gradient(to left top, rgb(168, 255, 120), rgb(120, 255, 214))',
    'linear-gradient(to left top, rgb(138, 35, 135), rgb(233, 64, 87), rgb(242, 113, 33))',
    'linear-gradient(to left top, rgb(255, 65, 108), rgb(255, 75, 43))',
    'linear-gradient(to left top, rgb(101, 78, 163), rgb(234, 175, 200))',
    'linear-gradient(to left top, rgb(0, 159, 255), rgb(236, 47, 75))',
    'linear-gradient(to left top, rgb(84, 74, 125), rgb(255, 212, 82))',
    'linear-gradient(to left top, rgb(131, 96, 195), rgb(46, 191, 145))',
    'linear-gradient(to left top, rgb(221, 62, 84), rgb(107, 229, 133))',
    'linear-gradient(to left top, rgb(101, 153, 153), rgb(244, 121, 31))',
    'linear-gradient(to left top, rgb(241, 39, 17), rgb(245, 175, 25))',
    'linear-gradient(to left top, rgb(195, 20, 50), rgb(36, 11, 54))',
    'linear-gradient(to left top, rgb(127, 127, 213), rgb(134, 168, 231), rgb(145, 234, 228))',
    'linear-gradient(to left top, rgb(249, 83, 198), rgb(185, 29, 115))',
    'linear-gradient(to left top, rgb(31, 64, 55), rgb(153, 242, 200))',
    'linear-gradient(to left top, rgb(142, 45, 226), rgb(74, 0, 224))',
    'linear-gradient(to left top, rgb(170, 75, 107), rgb(107, 107, 131), rgb(59, 141, 153))',
    'linear-gradient(to left top, rgb(255, 0, 153), rgb(73, 50, 64))',
    'linear-gradient(to left top, rgb(41, 128, 185), rgb(109, 213, 250), rgb(255, 255, 255))',
    'linear-gradient(to left top, rgb(55, 59, 68), rgb(66, 134, 244))',
    'linear-gradient(to left top, rgb(185, 43, 39), rgb(21, 101, 192))',
    'linear-gradient(to left top, rgb(18, 194, 233), rgb(196, 113, 237), rgb(246, 79, 89))',
    'linear-gradient(to left top, rgb(15, 32, 39), rgb(32, 58, 67), rgb(44, 83, 100))',
    'linear-gradient(to left top, rgb(198, 255, 221), rgb(251, 215, 134), rgb(247, 121, 125))',
    'linear-gradient(to left top, rgb(33, 147, 176), rgb(109, 213, 237))',
    'linear-gradient(to left top, rgb(238, 156, 167), rgb(255, 221, 225))',
    'linear-gradient(to left top, rgb(243, 98, 34), rgb(92, 182, 68), rgb(0, 127, 195))',
    'linear-gradient(to left top, rgb(42, 45, 62), rgb(254, 203, 110))',
    'linear-gradient(to left top, rgb(138, 43, 226), rgb(0, 0, 205), rgb(34, 139, 34), rgb(204, 255, 0))',
    'linear-gradient(to left top, rgb(5, 25, 55), rgb(0, 77, 122), rgb(0, 135, 147), rgb(0, 191, 114), rgb(168, 235, 18))',
    'linear-gradient(to left top, rgb(96, 37, 245), rgb(255, 85, 85))',
    'linear-gradient(to left top, rgb(138, 43, 226), rgb(255, 165, 0), rgb(248, 248, 255))',
    'linear-gradient(to left top, rgb(39, 116, 174), rgb(0, 46, 93), rgb(0, 46, 93))',
    'linear-gradient(to left top, rgb(0, 70, 128), rgb(68, 132, 186))',
    'linear-gradient(to left top, rgb(126, 198, 188), rgb(235, 231, 23))',
    'linear-gradient(to left top, rgb(255, 30, 86), rgb(249, 201, 66), rgb(30, 144, 255))',
    'linear-gradient(to left top, rgb(222, 138, 65), rgb(42, 218, 83))',
    'linear-gradient(to left top, rgb(247, 240, 172), rgb(172, 247, 240), rgb(240, 172, 247))',
    'linear-gradient(to left top, rgb(255, 0, 0), rgb(253, 207, 88))',
    'linear-gradient(to left top, rgb(54, 177, 199), rgb(150, 11, 51))',
    'linear-gradient(to left top, rgb(29, 161, 242), rgb(0, 159, 252))',
    'linear-gradient(to left top, rgb(109, 166, 190), rgb(75, 133, 158), rgb(109, 166, 190))',
    'linear-gradient(to left top, rgb(181, 185, 255), rgb(43, 44, 73))',
    'linear-gradient(to left top, rgb(159, 160, 168), rgb(92, 120, 82))',
    'linear-gradient(to left top, rgb(220, 255, 189), rgb(204, 134, 209))',
    'linear-gradient(to left top, rgb(230, 174, 140), rgb(168, 206, 207))',
    'linear-gradient(to left top, rgb(0, 255, 240), rgb(0, 131, 254))',
    'linear-gradient(to left top, rgb(51, 51, 51), rgb(162, 171, 88), rgb(164, 57, 49))',
    'linear-gradient(to left top, rgb(12, 12, 109), rgb(222, 81, 43), rgb(152, 208, 193), rgb(91, 178, 38), rgb(2, 60, 13))',
    'linear-gradient(to left top, rgb(5, 56, 107), rgb(92, 219, 149))',
    'linear-gradient(to left top, rgb(66, 132, 219), rgb(41, 234, 196))',
    'linear-gradient(to left top, rgb(85, 64, 35), rgb(201, 152, 70))',
    'linear-gradient(to left top, rgb(81, 107, 139), rgb(5, 107, 59))',
    'linear-gradient(to left top, rgb(215, 6, 82), rgb(255, 2, 94))',
    'linear-gradient(to left top, rgb(21, 35, 49), rgb(0, 0, 0))',
    'linear-gradient(to left top, rgb(247, 247, 247), rgb(185, 160, 160), rgb(121, 71, 71), rgb(78, 32, 32), rgb(17, 17, 17))',
    'linear-gradient(to left top, rgb(89, 205, 233), rgb(10, 42, 136))',
    'linear-gradient(to left top, rgb(235, 0, 0), rgb(149, 0, 138), rgb(51, 0, 252))',
    'linear-gradient(to left top, rgb(255, 117, 195), rgb(255, 166, 71), rgb(255, 232, 63), rgb(159, 255, 91), rgb(112, 226, 255), rgb(205, 147, 255))',
    'linear-gradient(to left top, rgb(129, 255, 138), rgb(100, 150, 94))',
    'linear-gradient(to left top, rgb(212, 252, 121), rgb(150, 230, 161))',
    'linear-gradient(to left top, rgb(0, 61, 77), rgb(0, 201, 150))',
    'linear-gradient(to left top, rgb(255, 154, 158), rgb(250, 208, 196), rgb(250, 208, 196))',
    'linear-gradient(to left top, rgb(161, 140, 209), rgb(251, 194, 235))',
    'linear-gradient(to left top, rgb(250, 208, 196), rgb(250, 208, 196), rgb(255, 209, 255))',
    'linear-gradient(to left top, rgb(255, 236, 210), rgb(252, 182, 159))',
    'linear-gradient(to left top, rgb(255, 129, 119), rgb(255, 134, 122), rgb(255, 140, 127), rgb(249, 145, 133), rgb(207, 85, 108), rgb(177, 42, 91))',
    'linear-gradient(to left top, rgb(255, 154, 158), rgb(254, 207, 239), rgb(254, 207, 239))',
    'linear-gradient(to left top, rgb(246, 211, 101), rgb(253, 160, 133))',
    'linear-gradient(to left top, rgb(251, 194, 235), rgb(166, 193, 238))',
    'linear-gradient(to left top, rgb(253, 203, 241), rgb(253, 203, 241), rgb(230, 222, 233))',
    'linear-gradient(to left top, rgb(161, 196, 253), rgb(194, 233, 251))',
    'linear-gradient(to left top, rgb(132, 250, 176), rgb(143, 211, 244))',
    'linear-gradient(to left top, rgb(207, 217, 223), rgb(226, 235, 240))',
    'linear-gradient(to left top, rgb(166, 192, 254), rgb(246, 128, 132))',
    'linear-gradient(to left top, rgb(252, 203, 144), rgb(213, 126, 235))',
    'linear-gradient(to left top, rgb(224, 195, 252), rgb(142, 197, 252))',
    'linear-gradient(to left top, rgb(240, 147, 251), rgb(245, 87, 108))',
    'linear-gradient(to left top, rgb(253, 251, 251), rgb(235, 237, 238))',
    'linear-gradient(to left top, rgb(79, 172, 254), rgb(0, 242, 254))',
    'linear-gradient(to left top, rgb(67, 233, 123), rgb(56, 249, 215))',
    'linear-gradient(to left top, rgb(250, 112, 154), rgb(254, 225, 64))',
    'linear-gradient(to left top, rgb(48, 207, 208), rgb(51, 8, 103))',
    'linear-gradient(to left top, rgb(168, 237, 234), rgb(254, 214, 227))',
    'linear-gradient(to left top, rgb(94, 231, 223), rgb(180, 144, 202))',
    'linear-gradient(to left top, rgb(210, 153, 194), rgb(254, 249, 215))',
    'linear-gradient(to left top, rgb(245, 247, 250), rgb(195, 207, 226))',
    'linear-gradient(to left top, rgb(22, 217, 227), rgb(48, 199, 236), rgb(70, 174, 247))',
    'linear-gradient(to left top, rgb(102, 126, 234), rgb(118, 75, 162))',
    'linear-gradient(to left top, rgb(253, 252, 251), rgb(226, 209, 195))',
    'linear-gradient(to left top, rgb(137, 247, 254), rgb(102, 166, 255))',
    'linear-gradient(to left top, rgb(253, 219, 146), rgb(209, 253, 255))',
    'linear-gradient(to left top, rgb(152, 144, 227), rgb(177, 244, 207))',
    'linear-gradient(to left top, rgb(235, 192, 253), rgb(217, 222, 216))',
    'linear-gradient(to left top, rgb(150, 251, 196), rgb(249, 245, 134))',
    'linear-gradient(to left top, rgb(42, 245, 152), rgb(0, 158, 253))',
    'linear-gradient(to left top, rgb(205, 156, 242), rgb(246, 243, 255))',
    'linear-gradient(to left top, rgb(228, 175, 203), rgb(184, 203, 184), rgb(184, 203, 184), rgb(226, 197, 139), rgb(194, 206, 156), rgb(126, 219, 220))',
    'linear-gradient(to left top, rgb(184, 203, 184), rgb(184, 203, 184), rgb(180, 101, 218), rgb(207, 108, 201), rgb(238, 96, 156), rgb(238, 96, 156))',
    'linear-gradient(to left top, rgb(106, 17, 203), rgb(37, 117, 252))',
    'linear-gradient(to left top, rgb(55, 236, 186), rgb(114, 175, 211))',
    'linear-gradient(to left top, rgb(235, 187, 167), rgb(207, 199, 248))',
    'linear-gradient(to left top, rgb(255, 241, 235), rgb(172, 224, 249))',
    'linear-gradient(to left top, rgb(238, 162, 162), rgb(187, 193, 191), rgb(87, 198, 225))',
    'linear-gradient(to left top, rgb(196, 113, 245), rgb(250, 113, 205))',
    'linear-gradient(to left top, rgb(72, 198, 239), rgb(111, 134, 214))',
    'linear-gradient(to left top, rgb(247, 140, 160), rgb(249, 116, 143), rgb(253, 134, 140))',
    'linear-gradient(to left top, rgb(254, 173, 166), rgb(245, 239, 239))',
    'linear-gradient(to left top, rgb(230, 233, 240), rgb(238, 241, 245))',
    'linear-gradient(to left top, rgb(172, 203, 238), rgb(231, 240, 253))',
    'linear-gradient(to left top, rgb(233, 222, 250), rgb(251, 252, 219))',
    'linear-gradient(to left top, rgb(193, 223, 196), rgb(222, 236, 221))',
    'linear-gradient(to left top, rgb(11, 163, 96), rgb(60, 186, 146))',
    'linear-gradient(to left top, rgb(0, 198, 251), rgb(0, 91, 234))',
    'linear-gradient(to left top, rgb(116, 235, 213), rgb(159, 172, 230))',
    'linear-gradient(to left top, rgb(106, 133, 182), rgb(186, 200, 224))',
    'linear-gradient(to left top, rgb(163, 189, 237), rgb(105, 145, 199))',
    'linear-gradient(to left top, rgb(151, 149, 240), rgb(251, 200, 212))',
    'linear-gradient(to left top, rgb(167, 166, 203), rgb(137, 137, 186), rgb(137, 137, 186))',
    'linear-gradient(to left top, rgb(63, 81, 177), rgb(90, 85, 174), rgb(123, 95, 172), rgb(143, 106, 174), rgb(168, 106, 164), rgb(204, 107, 142), rgb(241, 130, 113), rgb(243, 164, 105), rgb(247, 201, 120))',
    'linear-gradient(to left top, rgb(252, 197, 228), rgb(253, 163, 75), rgb(255, 120, 130), rgb(200, 105, 158), rgb(112, 70, 170), rgb(12, 29, 184), rgb(2, 15, 117))',
    'linear-gradient(to left top, rgb(219, 220, 215), rgb(221, 220, 215), rgb(226, 201, 204), rgb(231, 98, 125), rgb(184, 35, 90), rgb(128, 19, 87), rgb(61, 22, 53), rgb(28, 26, 39))',
    'linear-gradient(to left top, rgb(244, 59, 71), rgb(69, 58, 148))',
    'linear-gradient(to left top, rgb(79, 181, 118), rgb(68, 196, 137), rgb(40, 169, 174), rgb(40, 162, 183), rgb(76, 119, 136), rgb(108, 79, 99), rgb(67, 44, 57))',
    'linear-gradient(to left top, rgb(2, 80, 197), rgb(212, 63, 141))',
    'linear-gradient(to left top, rgb(136, 211, 206), rgb(110, 69, 226))',
    'linear-gradient(to left top, rgb(217, 175, 217), rgb(151, 217, 225))',
    'linear-gradient(to left top, rgb(112, 40, 228), rgb(229, 178, 202))',
    'linear-gradient(to left top, rgb(19, 84, 122), rgb(128, 208, 199))',
    'linear-gradient(to left top, rgb(189, 187, 190), rgb(157, 158, 163))',
    'linear-gradient(to left top, rgb(80, 82, 133), rgb(88, 94, 146), rgb(101, 104, 159))',
    'linear-gradient(to left top, rgb(255, 8, 68), rgb(255, 177, 153))',
    'linear-gradient(to left top, rgb(67, 67, 67), rgb(0, 0, 0))',
    'linear-gradient(to left top, rgb(12, 52, 131), rgb(162, 182, 223), rgb(107, 140, 206))',
    'linear-gradient(to left top, rgb(147, 165, 207), rgb(228, 239, 233))',
    'linear-gradient(to left top, rgb(146, 254, 157), rgb(0, 201, 255))',
    'linear-gradient(to left top, rgb(255, 117, 140), rgb(255, 126, 179))',
    'linear-gradient(to left top, rgb(134, 143, 150), rgb(89, 97, 100))',
    'linear-gradient(to left top, rgb(199, 144, 129), rgb(223, 165, 121))',
    'linear-gradient(to left top, rgb(139, 170, 170), rgb(174, 139, 156))',
    'linear-gradient(to left top, rgb(248, 54, 0), rgb(249, 212, 35))',
    'linear-gradient(to left top, rgb(183, 33, 255), rgb(33, 212, 253))',
    'linear-gradient(to left top, rgb(110, 69, 226), rgb(136, 211, 206))',
    'linear-gradient(to left top, rgb(213, 88, 200), rgb(36, 210, 146))',
    'linear-gradient(to left top, rgb(171, 236, 214), rgb(251, 237, 150))',
    'linear-gradient(to left top, rgb(213, 212, 208), rgb(213, 212, 208), rgb(238, 238, 236))',
    'linear-gradient(to left top, rgb(95, 114, 189), rgb(155, 35, 234))',
    'linear-gradient(to left top, rgb(9, 32, 63), rgb(83, 120, 149))',
    'linear-gradient(to left top, rgb(221, 214, 243), rgb(250, 172, 168), rgb(250, 172, 168))',
    'linear-gradient(to left top, rgb(220, 176, 237), rgb(153, 201, 156))',
    'linear-gradient(to left top, rgb(243, 231, 233), rgb(227, 238, 255), rgb(227, 238, 255))',
    'linear-gradient(to left top, rgb(199, 29, 111), rgb(208, 150, 147))',
    'linear-gradient(to left top, rgb(150, 222, 218), rgb(80, 201, 195))',
    'linear-gradient(to left top, rgb(247, 112, 98), rgb(254, 81, 150))',
    'linear-gradient(to left top, rgb(196, 197, 199), rgb(220, 221, 223), rgb(235, 235, 235))',
    'linear-gradient(to left top, rgb(168, 202, 186), rgb(93, 65, 87))',
    'linear-gradient(to left top, rgb(41, 50, 60), rgb(72, 85, 99))',
    'linear-gradient(to left top, rgb(22, 160, 133), rgb(244, 208, 63))',
    'linear-gradient(to left top, rgb(255, 88, 88), rgb(240, 152, 25))',
    'linear-gradient(to left top, rgb(0, 205, 172), rgb(141, 218, 213))',
    'linear-gradient(to left top, rgb(188, 197, 206), rgb(146, 158, 173))',
    'linear-gradient(to left top, rgb(68, 129, 235), rgb(4, 190, 254))',
    'linear-gradient(to left top, rgb(218, 212, 236), rgb(218, 212, 236), rgb(243, 231, 233))',
    'linear-gradient(to left top, rgb(135, 77, 162), rgb(196, 58, 48))',
    'linear-gradient(to left top, rgb(232, 25, 139), rgb(199, 234, 253))',
    'linear-gradient(to left top, rgb(234, 223, 223), rgb(236, 226, 223))',
    'linear-gradient(to left top, rgb(247, 148, 164), rgb(253, 214, 189))',
    'linear-gradient(to left top, rgb(100, 179, 244), rgb(194, 229, 156))',
    'linear-gradient(to left top, rgb(59, 65, 197), rgb(169, 129, 187), rgb(255, 200, 169))',
    'linear-gradient(to left top, rgb(15, 216, 80), rgb(249, 240, 71))',
    'linear-gradient(to left top, rgb(211, 211, 211), rgb(211, 211, 211), rgb(224, 224, 224))',
    'linear-gradient(to left top, rgb(58, 181, 176), rgb(61, 153, 190), rgb(86, 49, 122))',
    'linear-gradient(to left top, rgb(32, 156, 255), rgb(104, 224, 207))',
    'linear-gradient(to left top, rgb(189, 194, 232), rgb(189, 194, 232), rgb(230, 222, 233))',
    'linear-gradient(to left top, rgb(230, 185, 128), rgb(234, 205, 163))',
    'linear-gradient(to left top, rgb(30, 60, 114), rgb(30, 60, 114), rgb(42, 82, 152))',
    'linear-gradient(to left top, rgb(213, 222, 231), rgb(255, 175, 189), rgb(201, 255, 191))',
    'linear-gradient(to left top, rgb(155, 225, 93), rgb(0, 227, 174))',
    'linear-gradient(to left top, rgb(237, 110, 160), rgb(236, 140, 105))',
    'linear-gradient(to left top, rgb(255, 195, 160), rgb(255, 175, 189))',
    'linear-gradient(to left top, rgb(204, 32, 142), rgb(103, 19, 210))',
    'linear-gradient(to left top, rgb(179, 255, 171), rgb(18, 255, 247))',
    'linear-gradient(to left top, rgb(213, 222, 231), rgb(232, 235, 242), rgb(226, 231, 237))',
    'linear-gradient(to left top, rgb(101, 189, 96), rgb(90, 193, 168), rgb(62, 198, 237))',
    'linear-gradient(to left top, rgb(36, 57, 73), rgb(81, 127, 164))',
    'linear-gradient(to left top, rgb(252, 96, 118), rgb(255, 154, 68))',
    'linear-gradient(to left top, rgb(223, 233, 243), rgb(255, 255, 255))',
    'linear-gradient(to left top, rgb(0, 219, 222), rgb(252, 0, 255))',
    'linear-gradient(to left top, rgb(249, 212, 35), rgb(255, 78, 80))',
    'linear-gradient(to left top, rgb(80, 204, 127), rgb(245, 209, 0))',
    'linear-gradient(to left top, rgb(10, 207, 254), rgb(73, 90, 255))',
    'linear-gradient(to left top, rgb(97, 97, 97), rgb(155, 197, 195))',
    'linear-gradient(to left top, rgb(61, 51, 147), rgb(43, 118, 185), rgb(44, 172, 209), rgb(53, 235, 147))',
    'linear-gradient(to left top, rgb(223, 137, 181), rgb(191, 217, 254))',
    'linear-gradient(to left top, rgb(215, 210, 204), rgb(48, 67, 82))',
    'linear-gradient(to left top, rgb(225, 79, 173), rgb(249, 212, 35))',
    'linear-gradient(to left top, rgb(178, 36, 239), rgb(117, 121, 255))',
    'linear-gradient(to left top, rgb(193, 193, 97), rgb(193, 193, 97), rgb(212, 212, 177))',
    'linear-gradient(to left top, rgb(236, 119, 171), rgb(120, 115, 245))',
    'linear-gradient(to left top, rgb(0, 122, 223), rgb(0, 236, 188))',
    'linear-gradient(to left top, rgb(32, 226, 215), rgb(249, 254, 165))',
    'linear-gradient(to left top, rgb(44, 216, 213), rgb(197, 193, 255), rgb(255, 186, 195))',
    'linear-gradient(to left top, rgb(44, 216, 213), rgb(107, 141, 214), rgb(142, 55, 215))',
    'linear-gradient(to left top, rgb(223, 255, 205), rgb(144, 249, 196), rgb(57, 243, 187))',
    'linear-gradient(to left top, rgb(93, 159, 255), rgb(184, 220, 255), rgb(107, 187, 255))',
    'linear-gradient(to left top, rgb(168, 191, 255), rgb(136, 77, 128))',
    'linear-gradient(to left top, rgb(82, 113, 196), rgb(177, 159, 255), rgb(236, 161, 254))',
    'linear-gradient(to left top, rgb(255, 226, 159), rgb(255, 169, 159), rgb(255, 113, 154))',
    'linear-gradient(to left top, rgb(34, 225, 255), rgb(29, 143, 225), rgb(98, 94, 177))',
    'linear-gradient(to left top, rgb(182, 206, 232), rgb(245, 120, 220))',
    'linear-gradient(to left top, rgb(255, 254, 255), rgb(215, 255, 254))',
    'linear-gradient(to left top, rgb(227, 253, 245), rgb(255, 230, 250))',
    'linear-gradient(to left top, rgb(125, 226, 252), rgb(185, 182, 229))',
    'linear-gradient(to left top, rgb(203, 186, 204), rgb(37, 128, 179))',
    'linear-gradient(to left top, rgb(183, 248, 219), rgb(80, 167, 194))',
    'linear-gradient(to left top, rgb(112, 133, 182), rgb(135, 167, 217), rgb(222, 243, 248))',
    'linear-gradient(to left top, rgb(119, 255, 210), rgb(98, 151, 219), rgb(30, 236, 255))',
    'linear-gradient(to left top, rgb(172, 50, 228), rgb(121, 24, 242), rgb(72, 1, 255))',
    'linear-gradient(to left top, rgb(212, 255, 236), rgb(87, 242, 204), rgb(69, 150, 251))',
    'linear-gradient(to left top, rgb(158, 251, 211), rgb(87, 233, 242), rgb(69, 212, 251))',
    'linear-gradient(to left top, rgb(71, 59, 123), rgb(53, 132, 167), rgb(48, 210, 190))',
    'linear-gradient(to left top, rgb(101, 55, 155), rgb(136, 106, 234), rgb(100, 87, 198))',
    'linear-gradient(to left top, rgb(164, 69, 178), rgb(212, 24, 114), rgb(255, 0, 102))',
    'linear-gradient(to left top, rgb(119, 66, 178), rgb(241, 128, 255), rgb(253, 139, 217))',
    'linear-gradient(to left top, rgb(255, 60, 172), rgb(86, 43, 124), rgb(43, 134, 197))',
    'linear-gradient(to left top, rgb(255, 5, 124), rgb(141, 11, 147), rgb(50, 21, 117))',
    'linear-gradient(to left top, rgb(255, 5, 124), rgb(124, 100, 213), rgb(76, 195, 255))',
    'linear-gradient(to left top, rgb(105, 234, 203), rgb(234, 204, 248), rgb(102, 84, 241))',
    'linear-gradient(to left top, rgb(35, 21, 87), rgb(68, 16, 122), rgb(255, 19, 97))',
    'linear-gradient(to left top, rgb(61, 78, 129), rgb(87, 83, 201), rgb(110, 127, 243))',
  ]
  const gradientRows = chunkArray(gradientsExt, 12)

  const images = [
    'url(https://images.unsplash.com/photo-1691200099282-16fd34790ade?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2532&q=90)',
    'url(https://images.unsplash.com/photo-1691226099773-b13a89a1d167?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2532&q=90',
    'url(https://images.unsplash.com/photo-1688822863426-8c5f9b257090?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2532&q=90)',
    'url(https://images.unsplash.com/photo-1691225850735-6e4e51834cad?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2532&q=90)',
  ]

  const defaultTab = useMemo(() => {
    if (selection.includes('url')) return 'image'
    if (selection.includes('gradient')) return 'gradient'
    return 'solid'
  }, [selection])

  const isDesktop = useBreakpoint('sm')?.isAboveSm

  return isDesktop ? (
    <Popover open={open} onOpenChange={setIsOpen} modal={false}>
      <PopoverTrigger>
        <If condition={isNullish(children)}>
          <Button
            variant={'outline'}
            className={cn(
              'w-[220px] justify-start text-left font-normal',
              !selection && 'text-muted-foreground',
              className
            )}
          >
            <div className="flex w-full items-center gap-2">
              {selection ? (
                <div
                  className="h-4 w-4 rounded !bg-cover !bg-center transition-all"
                  style={{ background: selection }}
                ></div>
              ) : (
                <Paintbrush className="h-4 w-4" />
              )}
              <div className="flex-1 truncate">
                {selection ? selection : 'Pick a color'}
              </div>
            </div>
          </Button>
        </If>
        <If condition={children}>{children}</If>
      </PopoverTrigger>

      <PopoverContent className="w-64">
        <Tabs defaultValue={defaultTab} className="w-full">
          <TabsList className="mb-4 w-full">
            <TabsTrigger className="flex-1" value="solid">
              Solid
            </TabsTrigger>
            <TabsTrigger className="flex-1" value="gradient">
              Gradient
            </TabsTrigger>
          </TabsList>

          <TabsContent value="solid" className="mt-0 flex flex-wrap gap-1">
            <ScrollArea className="h-max-[80%] mb-2 flex h-72 w-full flex-grow flex-row flex-wrap">
              {chunkedSolids.map((rows, index) => (
                <div
                  className="flex flex-row space-x-1 space-y-1 pl-0"
                  key={`${index}`}
                >
                  {rows?.map((s, idx) => (
                    <div
                      key={`${idx}`}
                      style={{ background: s }}
                      className={`h-6 w-6 cursor-pointer rounded-md active:scale-105`}
                      onClick={() => {
                        handleAnnotation(s)
                      }}
                    />
                  ))}
                </div>
              ))}
            </ScrollArea>
          </TabsContent>

          <TabsContent value="gradient" className="mt-0">
            {/* <div className="flex flex-wrap gap-1"> */}
            <ScrollArea className="h-max-[80%] mb-2 flex h-72 w-full flex-grow flex-row flex-wrap">
              {gradientRows.map((rows, index) => (
                <div
                  className="flex flex-row space-x-1 space-y-1 pl-0"
                  key={`${index}`}
                >
                  {rows?.map((s, idx) => (
                    <div
                      key={`${idx}`}
                      style={{ background: s }}
                      className={`h-6 w-6 cursor-pointer rounded-md active:scale-105 ${
                        idx === 0 ? 'mt-1' : ''
                      }`}
                      onClick={() => handleAnnotation(s)}
                    />
                  ))}
                </div>
              ))}
            </ScrollArea>
            {/* </div> */}

            {/* <GradientButton background={background!}>
              💡 Get more at{' '}
              <a
                href="https://madarchitect.dev"
                className="font-bold hover:underline"
                target="_blank"
              >
                Gradient Page
              </a>
            </GradientButton> */}
          </TabsContent>
        </Tabs>

        <Input
          id="custom"
          value={selection}
          className="col-span-2 mt-4 h-8"
          onChange={(e) => {
            const selection = e.currentTarget.value
            handleAnnotation(selection)
          }}
        />
      </PopoverContent>
    </Popover>
  ) : (
    <>
      <If condition={children}>{children}</If>
      <Modal showModal={open} setShowModal={setIsOpen}>
        <Tabs defaultValue={defaultTab} className="bg-background w-full">
          <TabsList className="bg-background mb-4 w-full">
            <TabsTrigger className="flex-1" value="solid">
              Solid
            </TabsTrigger>
            <TabsTrigger className="flex-1" value="gradient">
              Gradient
            </TabsTrigger>
          </TabsList>

          <TabsContent
            value="solid"
            className="bg-background mt-0 flex flex-wrap gap-1"
          >
            <ScrollArea className="h-max-[80%] mb-2 flex h-72 w-full flex-grow flex-row flex-wrap">
              {chunkedSolids.map((rows, index) => (
                <div
                  className="flex flex-row space-x-1 space-y-1 pl-0"
                  key={`${index}`}
                >
                  {rows?.map((s, idx) => (
                    <div
                      key={`${idx}`}
                      style={{ background: s }}
                      className={`h-9 w-9 cursor-pointer rounded-md active:scale-105`}
                      onClick={() => {
                        handleAnnotation(s)
                      }}
                    />
                  ))}
                </div>
              ))}
            </ScrollArea>
          </TabsContent>

          <TabsContent value="gradient" className="mt-0">
            <ScrollArea className="h-max-[80%] mb-2  flex h-72 flex-wrap">
              {gradientRows.map((rows, index) => (
                <div
                  className="flex flex-row space-x-1 space-y-1 pl-0"
                  key={`${index}`}
                >
                  {takeLastItems(rows, 2)?.map((s, idx) => (
                    <div
                      key={`${idx}`}
                      style={{ background: s }}
                      className={`flex h-9 w-9 flex-grow cursor-pointer rounded-md active:scale-105 ${
                        idx === 0 ? 'mt-1' : ''
                      }`}
                      onClick={() => handleAnnotation(s)}
                    />
                  ))}
                </div>
              ))}
            </ScrollArea>
            {/* </div> */}
          </TabsContent>

          <TabsContent value="image" className="mt-0">
            <div className="mb-2 grid grid-cols-2 gap-1">
              {images.map((s, idx) => (
                <div
                  key={`${idx}`}
                  style={{ backgroundImage: s }}
                  className="h-12 w-full cursor-pointer rounded-md bg-cover bg-center active:scale-105"
                  onClick={() => handleAnnotation(s)}
                />
              ))}
            </div>
          </TabsContent>
        </Tabs>

        <Input
          id="custom"
          value={selection}
          className="col-span-2 mt-4 h-8"
          onChange={(e) => {
            const selection = e.currentTarget.value
            handleAnnotation(selection)
          }}
        />
      </Modal>
    </>
  )
}

const GradientButton = ({
  background,
  children,
}: {
  background: string
  children: React.ReactNode
}) => {
  return (
    <div
      className="relative rounded-md !bg-cover !bg-center p-0.5 transition-all"
      style={{ background }}
    >
      <div className="bg-popover/80 rounded-md p-1 text-center text-xs">
        {children}
      </div>
    </div>
  )
}
